import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxTooltipDirective } from '../../../directives';
import { OverflowHelper } from '../../../helpers';

@Component({
  selector: 'onyx-information-row',
  imports: [OnyxTooltipDirective],
  templateUrl: './onyx-information-row.component.html',
  styleUrl: './onyx-information-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxInformationRowComponent {
  protected readonly OverflowHelper = OverflowHelper;

  public color = input<'gray' | 'blue' | 'green' | 'red' | 'yellow'>('gray');
  public label = input.required<string>();
  public size = input<'s' | 'm'>('m');
}
