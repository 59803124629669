import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { OnyxIconComponent } from '../../icons';

type SpinnerColor = 'blue' | 'white' | 'black';

@Component({
  selector: 'onyx-spinner',
  imports: [OnyxIconComponent],
  templateUrl: './onyx-spinner.component.html',
  styleUrl: './onyx-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxSpinnerComponent {
  public color = input<SpinnerColor>('blue');
  public size = input(24);

  protected name = computed(
    (): `spinner-${SpinnerColor}` => `spinner-${this.color()}`,
  );
}
