import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { OnyxTimepickerTime } from '../components';

export const onyxMaxTimeValidator =
  (
    maxTime: DateTime | OnyxTimepickerTime = DateTime.now(),
    currentDate?: string,
  ): ValidatorFn =>
  (control: AbstractControl<OnyxTimepickerTime>): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    const maximumTime = isString(maxTime)
      ? DateTime.fromFormat(maxTime, 'HH:mm:ss')
      : maxTime;
    const time = DateTime.fromFormat(value, 'HH:mm:ss');

    if (currentDate) {
      const date = DateTime.fromISO(currentDate);
      if (date.startOf('day') < maximumTime.startOf('day')) return null;
    }

    return time > maximumTime
      ? { maxTime: maximumTime.toFormat('HH:mm') }
      : null;
  };
