import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostListener,
  Injector,
  forwardRef,
  input,
  model,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxInputLabelComponent } from '../../labels/onyx-input-label/onyx-input-label.component';

@Component({
  selector: 'onyx-toggle',
  imports: [NgClass, OnyxInputLabelComponent, OnyxFormControlErrorComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxToggleComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-toggle.component.html',
  styleUrls: ['./onyx-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxToggleComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public value = model(false);
  public label = input<string>('');
  public size = input<'s' | 'm' | 'l'>('l');
  public showOptional = input(true);
  public showErrors = input(true);
  public disabled = model(false);

  protected onChange?: (value: boolean) => void;
  protected onTouched?: () => void;

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
  ) {
    super(injector, destroyRef);
  }

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: boolean): void {
    this.value.set(value);
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.space', ['$event'])
  protected changeValue(event: Event): void {
    if (this.disabled()) return;
    event.preventDefault();

    this.value.update((value) => !value);
    this.onChange?.(this.value());
  }
}
