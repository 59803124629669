import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  OnChanges,
  computed,
  effect,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { isArray, isEqual } from 'lodash';
import { Observable, Subject, takeUntil } from 'rxjs';
import { OnyxSuggestion } from '../../../interfaces';

@Component({
  selector: 'onyx-suggestions',
  imports: [TranslatePipe, NgClass],
  templateUrl: './onyx-suggestions.component.html',
  styleUrl: './onyx-suggestions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxSuggestionsComponent<T> implements OnChanges {
  public control = input.required<AbstractControl>();
  public suggestions = input.required<OnyxSuggestion<T>[] | null>();

  protected suggestionsData = computed(() => {
    const value = this.value();
    if (!this.suggestions()) return;

    return this.suggestions()!.map((suggestion) => ({
      ...suggestion,
      disabled:
        this.disabled_() ||
        (isArray(value)
          ? value.includes(suggestion.value)
          : isEqual(value, suggestion.value)),
    }));
  });

  private value_ = signal<T | null>(null);
  protected get value() {
    return this.value_.asReadonly();
  }

  private disabled_ = signal(false);

  private next$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnChanges(): void {
    effect(
      () => {
        this.next$.next();
        this.update();

        const statusChanges$ = (
          this.control().statusChanges as Observable<any> | undefined
        )?.pipe(takeUntil(this.next$), takeUntilDestroyed(this.destroyRef));
        const valueChanges$ = (
          this.control().valueChanges as Observable<any> | undefined
        )?.pipe(takeUntil(this.next$), takeUntilDestroyed(this.destroyRef));

        statusChanges$?.subscribe(() => this.update());
        valueChanges$?.subscribe(() => this.update());
      },
      { injector: this.injector },
    );
  }

  protected setValue(value: T): void {
    this.control().setValue(value);
  }

  private update(): void {
    this.value_.set(this.control().value);
    this.disabled_.set(this.control().disabled);
  }
}
