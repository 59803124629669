@let employee = employees[0];
@let employeeFullName = employee.firstName + ' ' + employee.lastName;
@let singleHeadingTemplate =
  employeeFullName + ' - ' + (I18N + '.singleHeading' | translate);
<onyx-modal
  type="center"
  size="m"
  [heading]="
    isSingle() ? singleHeadingTemplate : (I18N + '.bulkHeading' | translate)
  "
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <onyx-avatar headingIcon [user]="employee" type="user" size="s"></onyx-avatar>

  <div class="container">
    @if (!isSingle()) {
      <div class="employees">
        <p>
          {{
            I18N + '.changingAssignedVehicles'
              | translate: { value: employees.length }
          }}
        </p>

        <div class="badges">
          @for (employee of employees; track employee) {
            <onyx-badge color="outlined-black" size="m">
              {{ employee.firstName + ' ' + employee.lastName }}
            </onyx-badge>
          }
        </div>
      </div>
    }

    <form [formGroup]="form">
      <onyx-dropdown
        formControlName="vehicles"
        [label]="'labels.vehicles' | translate"
        [options]="vehiclesOptions()"
        [multiple]="true"
      ></onyx-dropdown>
    </form>
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.assign' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
