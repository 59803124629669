import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StatisticsByType,
  StatisticsDataByType,
  StatisticsType,
} from '../enums/statistics-type';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getStatistics<T extends StatisticsType>(
    type: T,
    params?: StatisticsDataByType[T],
  ): Observable<StatisticsByType[T]> {
    return this.get(
      `/statistics/${type}`,
      params ? { params: params as any } : undefined,
    );
  }
}
