<onyx-modal
  type="center"
  size="m"
  [heading]="
    (vehicle.trailer ? I18N + '.editSet' : I18N + '.newSet') | translate
  "
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <div class="container">
    @if (vehicle.trailer) {
      <onyx-tabs
        [label]="I18N + '.whatToDo' | translate"
        size="m"
        [tabs]="TABS"
        [fill]="true"
        [forceOptional]="true"
        [(value)]="mode"
      ></onyx-tabs>
    }

    @let isChangeMode = mode() === FleetSetsModalOption.CHANGE;
    @if (isChangeMode) {
      <form class="switch" [formGroup]="changeForm">
        <onyx-dropdown
          formControlName="vehicle"
          [label]="'labels.vehicle' | translate"
          [options]="vehicleOptions()"
          compareKey="uuid"
        ></onyx-dropdown>

        <onyx-dropdown
          formControlName="trailer"
          [label]="'labels.trailer' | translate"
          [options]="trailerOptions()"
          [optional]="true"
          compareKey="uuid"
        ></onyx-dropdown>
      </form>

      @if (
        warningMessage() &&
        (isVehicle
          ? changeForm.controls.trailer.dirty
          : changeForm.controls.vehicle.dirty) &&
        changeForm.valid
      ) {
        <onyx-message size="m" type="warning" [borderRadius]="true">
          <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

          @if (warningMessage()?.currentVehicle; as vehicle) {
            {{ I18N + '.unassignTrailer' | translate: { vehicle } }}
          } @else {
            {{
              I18N + '.reassign'
                | translate
                  : {
                      vehicle: warningMessage()?.assignedVehicle,
                      trailer: warningMessage()?.currentTrailer,
                    }
            }}
          }
        </onyx-message>
      }
    } @else {
      <form class="swap" [formGroup]="swapForm">
        @let secondSet = swapForm.controls.secondSet.getRawValue();
        @let firstSet = swapForm.controls.firstSet.getRawValue();
        <div class="item">
          <onyx-dropdown
            formControlName="firstSet"
            [label]="I18N + '.firstSet' | translate"
            [options]="setsOptions()"
            compareKey="uuid"
          ></onyx-dropdown>

          @if (secondSet) {
            <onyx-icon name="arrow-right" [size]="16"></onyx-icon>

            @let value =
              firstSet.generalInformation.registrationNumber +
              ' · ' +
              secondSet?.trailer?.generalInformation?.registrationNumber;
            <onyx-text-field
              [label]="I18N + '.firstSet' | translate"
              [disabled]="true"
              [value]="value"
            ></onyx-text-field>
          }
        </div>

        <div class="item">
          <onyx-dropdown
            formControlName="secondSet"
            [label]="I18N + '.secondSet' | translate"
            [options]="setsOptions()"
            [optional]="true"
            compareKey="uuid"
          ></onyx-dropdown>

          @if (secondSet) {
            <onyx-icon name="arrow-right" [size]="16"></onyx-icon>

            @let value =
              secondSet.generalInformation.registrationNumber +
              ' · ' +
              firstSet?.trailer?.generalInformation?.registrationNumber;
            <onyx-text-field
              [label]="I18N + '.secondSet' | translate"
              [disabled]="true"
              [value]="value"
            ></onyx-text-field>
          }
        </div>
      </form>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      (click)="
        !loading()
          ? isChangeMode
            ? submitChangeForm()
            : submitSwapForm()
          : null
      "
      [disabled]="
        isChangeMode ? false : !swapForm.controls.secondSet.getRawValue()
      "
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
