import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxCallbackOption,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationRowComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxPhone,
  OnyxPhonePipe,
  OnyxToastService,
  PhoneHelper,
} from '@onyx/angular';
import { Subject, switchMap } from 'rxjs';
import { FleetIdentifierPipe } from '../../../common/components/pipes/fleet-identifier.pipe';
import { UnavailabilitiesComponent } from '../../../common/components/unavailabilities/unavailabilities.component';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { DelegationsRoute } from '../../delegations/delegations.routes';
import { DriverAssignVehicleModalComponent } from '../common/components/driver-assign-vehicle-modal/driver-assign-vehicle-modal.component';
import { Driver } from '../common/interfaces/driver';
import { DriversService } from '../common/services/drivers.service';
import { DriversRoute } from '../drivers.routes';
import { DriverModalDataComponent } from './driver-modal-data/driver-modal-data.component';
import { DriverModalDocumentsComponent } from './driver-modal-documents/driver-modal-documents.component';

export enum DriverModalOption {
  WORK_TIME = 'work-time',
  DELEGATION = 'delegation',
  STATISTICS = 'statistics',
  UNAVAILABILITIES = 'unavailabilities',
  DRIVER_DATA = 'driver-data',
  DOCUMENTS = 'documents',
  NOTE = 'note',
}

@Component({
  selector: 'app-driver-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxChipsComponent,
    OnyxPhonePipe,
    DriverModalDataComponent,
    DriverModalDocumentsComponent,
    UnavailabilitiesComponent,
    OnyxDropdownDirective,
    FleetIdentifierPipe,
  ],
  templateUrl: './driver-modal.component.html',
  styleUrl: './driver-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalComponent {
  protected readonly I18N = 'drivers.driverModal';
  protected readonly GROUP_CHIPS: OnyxChip<string>[] = [
    { name: 'labels.workTime', value: DriverModalOption.WORK_TIME },
    { name: 'labels.delegation', value: DriverModalOption.DELEGATION },
    { name: 'labels.statistics', value: DriverModalOption.STATISTICS },
    {
      name: 'labels.unavailabilities',
      value: DriverModalOption.UNAVAILABILITIES,
    },
    { name: 'labels.driverData', value: DriverModalOption.DRIVER_DATA },
    { name: 'labels.documents', value: DriverModalOption.DOCUMENTS },
    { name: 'labels.note', value: DriverModalOption.NOTE },
  ];
  protected readonly DROPDOWN_OPTIONS: OnyxCallbackOption[] = [
    {
      name: `${this.I18N}.rewireVehicle`,
      value: () =>
        this.modalService.open<Driver>(
          DriverAssignVehicleModalComponent,
          this.driver,
        ),
    },
    {
      name: 'drivers.driverForm.editDriver',
      value: () => {
        this.close$.next();
        this.router.navigateByUrl(
          `${DriversRoute.EDIT_DRIVER.replace(':uuid', this.driver.uuid)}`,
        );
      },
    },
    {
      name: `${this.I18N}.editDelegation`,
      value: () => alert('HOLD: BE'),
    },
    {
      name: `${this.I18N}.createDelegation`,
      value: () => {
        this.close$.next();
        this.router.navigateByUrl(DelegationsRoute.ADD_DELEGATION);
      },
    },
  ];

  protected readonly ActionHelper = ActionHelper;
  protected readonly DriverModalOption = DriverModalOption;

  protected group = signal([DriverModalOption.WORK_TIME]);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected driver: Driver,
    protected dialogRef: DialogRef<void>,
    private driversService: DriversService,
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private modalService: OnyxModalService,
    private router: Router,
  ) {
    this.driversService.reload$
      .pipe(
        switchMap(() => this.driversService.getDriver(this.driver.uuid)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (driver) => (this.driver = driver),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }

  protected copyPhone(phone: OnyxPhone) {
    ActionHelper.copy(PhoneHelper.composeLabel(phone), this.toastService);
  }

  protected copy(value: string) {
    ActionHelper.copy(value, this.toastService);
  }
}
