import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxOption,
  OnyxToastService,
} from '@onyx/angular';
import { filter } from 'lodash';
import { map, Subject, take } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { Driver } from '../../../../drivers/common/interfaces/driver';
import { DriverCategory } from '../../../../drivers/common/interfaces/driver-category';
import { DriversService } from '../../../../drivers/common/services/drivers.service';
import { Fleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

type FleetAssignDriverModalFormGroup = ReturnType<
  FleetAssignDriverModalComponent['buildForm']
>;

export type FleetAssignDriverModalForm = ReturnType<
  FleetAssignDriverModalFormGroup['getRawValue']
>;

@Component({
  selector: 'app-fleet-assign-driver-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxIconComponent,
    FleetIdentifierPipe,
  ],
  templateUrl: './fleet-assign-driver-modal.component.html',
  styleUrl: './fleet-assign-driver-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetAssignDriverModalComponent implements OnInit {
  protected readonly I18N = 'fleet.assignDriversModal';

  protected form = this.buildForm();
  protected loading = signal(false);
  protected driverOptions = signal<OnyxOption<string>[] | null>(null);
  protected formChanges = toSignal(
    this.form.valueChanges.pipe(map(() => this.form.getRawValue())),
  );
  protected close$ = new Subject<void>();

  protected driversOptions = computed(() => ({
    primary: this.getFilteredDriverOptions(
      this.formChanges()?.secondaryDriverUuid,
    ),
    secondary: this.getFilteredDriverOptions(
      this.formChanges()?.primaryDriverUuid,
    ),
  }));

  constructor(
    @Inject(DIALOG_DATA) protected vehicle: Fleet,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private driversService: DriversService,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.driversService
      .listDrivers({
        category: DriverCategory.ACTIVE,
        status: [],
        freeOver24h: false,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
      .pipe(
        take(1),
        map((drivers) =>
          drivers.items.map((driver: Driver) => ({
            name: `${driver.driverData.firstName} ${driver.driverData.lastName}`,
            value: driver.uuid,
          })),
        ),
      )
      .subscribe((options) => this.driverOptions.set(options));

    this.form.setValue({
      primaryDriverUuid: this.vehicle.drivers?.primaryDriver?.uuid ?? null,
      secondaryDriverUuid: this.vehicle.drivers?.secondaryDriver?.uuid ?? null,
    });

    this.form.controls.primaryDriverUuid.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) =>
        ValidationHelper.toggleControls(
          this.form.controls.secondaryDriverUuid,
          !!value,
        ),
      );
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    this.loading.set(true);
    this.fleetService
      .assignDrivers(form, this.vehicle.uuid)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.success`);
          this.close$.next();
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private getFilteredDriverOptions = (
    excludeUuid: string | null | undefined,
  ) => {
    return filter(
      this.driverOptions(),
      (option) => option.value !== excludeUuid,
    );
  };

  private buildForm() {
    return this.fb.group({
      primaryDriverUuid: this.fb.control<string | null | undefined>(null, [
        Validators.required,
      ]),
      secondaryDriverUuid: this.fb.control<string | null | undefined>({
        value: null,
        disabled: true,
      }),
    });
  }
}
