@let displayValue = decimalValue();
@if (displayValue != null && unit()) {
  <p>
    <span>{{ displayValue | number: pipeFormat() }}</span>
    <span class="unit" [ngClass]="{ percentage: unit() === '%' }">{{
      unit()! | translate
    }}</span>
  </p>
} @else {
  -
}
