@if (make() && model()) {
  <div class="container">
    <p>
      {{
        makeDictionaryCode()
          ? (makeDictionaryCode() + '.' + make() | translate)
          : make()
      }}
    </p>

    <p class="model f-regular-3">
      {{
        modelDictionaryCode()
          ? (modelDictionaryCode() + '.' + model() | translate)
          : model()
      }}
    </p>
  </div>
} @else {
  -
}
