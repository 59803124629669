import { LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxFormMode } from '@onyx/angular';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { ContractorDocument } from '../../interfaces/contractor-documents';

@Component({
  selector: 'app-contractor-documents-form-information-cell',
  imports: [OnyxDatePipe, TranslatePipe, LowerCasePipe],
  templateUrl: './contractor-documents-form-information-cell.component.html',
  styleUrl: './contractor-documents-form-information-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorDocumentsFormInformationCellComponent {
  protected readonly I18N = 'contractors.documentsForm';

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OnyxFormMode = OnyxFormMode;

  public document = input.required<ContractorDocument | null>();
  public mode = input.required<OnyxFormMode>();
}
