<button
  class="banner"
  (click)="openModal(driver() ? 'driver' : 'employee'); $event.preventDefault()"
>
  <div class="content">
    @if (driver()) {
      <div class="tacho-icon">
        <onyx-icon name="tacho-other-work" [size]="16"></onyx-icon>
      </div>
    } @else {
      <onyx-avatar
        type="user"
        size="s"
        [user]="{
          firstName: employee()?.firstName,
          lastName: employee()?.lastName,
        }"
      ></onyx-avatar>
    }

    <p>
      @if (driver(); as driver) {
        @let driverData = driver.driverData;
        {{ driverData.firstName }} {{ driverData.lastName }}
      } @else {
        {{ employee()?.firstName }} {{ employee()?.lastName }}
      }
    </p>

    <div class="roles">
      @if (driver()) {
        <onyx-badge color="blue">
          {{ 'labels.driver' | translate }}
        </onyx-badge>
      } @else {
        @for (role of employee()?.roles; track role) {
          <onyx-badge [color]="role.color">
            {{ DictionaryCode.EMPLOYEE_ROLE + '.' + role.value | translate }}
          </onyx-badge>
        }
      }
    </div>
  </div>

  <div class="buttons">
    <onyx-icon-button
      type="transparent"
      color="black"
      size="s"
      (click)="
        ActionHelper.makeCall(
          driver() ? driver()!.driverData.privatePhone : employee()!.phone
        )
      "
    >
      <onyx-icon class="phone" name="telephone" [size]="16"></onyx-icon>
    </onyx-icon-button>

    @let email = driver()?.driverData?.privateEmail || employee()?.email;
    @if (email) {
      <onyx-icon-button
        type="transparent"
        color="black"
        size="s"
        (click)="ActionHelper.sendEmail(email)"
      >
        <onyx-icon name="e-mail" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </div>
</button>
