@if (generalInformation(); as generalInformation) {
  <div class="vehicle-cell">
    <onyx-icon
      [name]="$any(generalInformation.category)"
      [size]="24"
    ></onyx-icon>

    {{ generalInformation | fleetIdentifier }}
  </div>
} @else {
  -
}
