import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  signal,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxCallbackOption,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxModalService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { DelegationStatus } from '../common/enums/delegation-status';
import { Delegation } from '../common/interfaces/delegation';
import {
  DelegationActionModalComponent,
  DelegationActionModalData,
  DelegationActionModalMode,
} from '../delegation-action-modal/delegation-action-modal.component';
import { DelegationsRoute } from '../delegations.routes';
import { DelegationModalHeadingComponent } from './delegation-modal-heading/delegation-modal-heading.component';
import { DelegationModalMainSectionComponent } from './delegation-modal-main-section/delegation-modal-main-section.component';

enum DelegationModalOption {
  FINANCES = 'finances',
  ORDERS = 'orders',
  EXPENSES = 'expenses',
  TACHOGRAPH = 'tachograph',
  DOCUMENTS = 'documents',
}

@Component({
  selector: 'app-delegation-modal',
  imports: [
    OnyxModalComponent,
    DelegationModalHeadingComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxButtonComponent,
    DelegationModalMainSectionComponent,
    OnyxChipsComponent,
    OnyxDropdownDirective,
  ],
  templateUrl: './delegation-modal.component.html',
  styleUrl: './delegation-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalComponent {
  protected readonly I18N = 'delegations.delegationModal';

  protected DelegationModalOption = DelegationModalOption;

  protected headerActionButton = signal<OnyxCallbackOption | null>(null);
  protected group = signal([DelegationModalOption.FINANCES]);
  protected close$ = new Subject<void>();

  protected chips = computed<OnyxChip<DelegationModalOption>[]>(() => [
    {
      name: `${this.I18N}.chips.finances`,
      value: DelegationModalOption.FINANCES,
    },
    { name: 'labels.orders', value: DelegationModalOption.ORDERS },
    {
      name: `${this.I18N}.chips.expenses`,
      value: DelegationModalOption.EXPENSES,
    },
    ...(this.delegation.status.value !== DelegationStatus.PLANNED
      ? [
          {
            name: 'labels.tachograph',
            value: DelegationModalOption.TACHOGRAPH,
          },
        ]
      : []),
    {
      name: 'labels.documents',
      value: DelegationModalOption.DOCUMENTS,
    },
  ]);
  protected dropdownOptions = computed<OnyxCallbackOption[]>(() => [
    {
      name: 'buttons.edit',
      value: () => this.edit(),
    },
    ...(this.delegation.status.value === DelegationStatus.SETTLED
      ? [
          {
            name: 'buttons.archive',
            value: () => alert('HOLD'),
          },
        ]
      : []),
    ...(this.delegation.status.value === DelegationStatus.ARCHIVED
      ? [
          {
            name: 'buttons.unarchive',
            value: () => alert('HOLD'),
          },
        ]
      : []),
    ...(this.delegation.status.value === DelegationStatus.PLANNED
      ? [
          {
            name: 'buttons.cancel',
            value: () => alert('HOLD'),
          },
        ]
      : []),
  ]);

  constructor(
    @Inject(DIALOG_DATA) protected delegation: Delegation,
    protected dialogRef: DialogRef<void>,
    private modalService: OnyxModalService,
    private router: Router,
  ) {
    const headerActionButtons: Partial<
      Record<DelegationStatus, OnyxCallbackOption>
    > = {
      [DelegationStatus.PLANNED]: {
        name: 'buttons.active',
        value: () =>
          this.openDelegationActionModal(
            DelegationActionModalMode.ACTIVATE,
            this.delegation,
          ),
      },
      [DelegationStatus.ACTIVE]: {
        name: 'buttons.finish',
        value: () =>
          this.openDelegationActionModal(
            DelegationActionModalMode.FINISH,
            this.delegation,
          ),
      },
      [DelegationStatus.UNSETTLED]: {
        name: 'buttons.settle',
        value: () => alert('HOLD'),
      },
    };

    this.headerActionButton.set(
      headerActionButtons[this.delegation.status.value] ?? null,
    );
  }

  protected edit(): void {
    this.router.navigateByUrl(
      `${DelegationsRoute.EDIT_DELEGATION.replace(':uuid', this.delegation.uuid)}`,
    );
    this.close$.next();
  }

  protected openDelegationActionModal(
    mode: DelegationActionModalMode,
    delegation: Delegation,
  ): void {
    this.modalService.open<DelegationActionModalData>(
      DelegationActionModalComponent,
      { mode, delegation },
    );
  }
}
