import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxUploadComponent } from '@onyx/angular';

@Component({
  selector: 'app-contractor-documents-form-scan-cell',
  imports: [OnyxUploadComponent, TranslatePipe],
  templateUrl: './contractor-documents-form-scan-cell.component.html',
  styleUrl: './contractor-documents-form-scan-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorDocumentsFormScanCellComponent {
  public scan = input.required<File | string | null>();
}
