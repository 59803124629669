<onyx-modal
  type="center"
  [heading]="I18N + '.editEmployee' | translate"
  size="m"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    <div class="group">
      <onyx-text-field
        formControlName="firstName"
        [label]="'labels.firstName' | translate"
      ></onyx-text-field>

      <onyx-text-field
        formControlName="lastName"
        [label]="'labels.lastName' | translate"
      ></onyx-text-field>
    </div>

    <div class="group">
      <onyx-phone-number-input
        formControlName="phone"
        [label]="'labels.phone' | translate"
      ></onyx-phone-number-input>

      <onyx-text-field
        formControlName="email"
        [label]="'labels.email' | translate"
        autocomplete="email"
      ></onyx-text-field>
    </div>

    <onyx-dropdown
      formControlName="roles"
      [label]="'labels.roles' | translate"
      [options]="employeeRoles$ | async"
      [multiple]="true"
      [search]="true"
    ></onyx-dropdown>
  </form>

  <onyx-button
    bottomLeftOptions
    color="red"
    type="outlined"
    (click)="archiveEmployee()"
  >
    {{ 'buttons.archive' | translate }}
  </onyx-button>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
