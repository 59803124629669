import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  effect,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxChipsComponent,
  OnyxDatePipe,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxPaginated,
  OnyxPhonePipe,
  OnyxTableComponent,
  OnyxTableNotFound,
  OnyxToastService,
} from '@onyx/angular';
import { cloneDeep } from 'lodash';
import { Subject, forkJoin, startWith, switchMap, take, tap } from 'rxjs';
import { UnavailabilitiesComponent } from '../../../../../common/components/unavailabilities/unavailabilities.component';
import { SHORT_PAGINATION } from '../../../../../common/constants/short-pagination';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { StatisticsType } from '../../../../../common/enums/statistics-type';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { EmployeeStatistics } from '../../../../../common/interfaces/statistics/employee-statistics';
import { StatisticsService } from '../../../../../common/services/statistics.service';
import { CallHelper } from '../../../../common/helpers/call-helper';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { EditEmployeeModalComponent } from '../../common/components/edit-employee-modal/edit-employee-modal.component';
import { EmployeeStatusComponent } from '../../common/components/employee-status/employee-status.component';
import { EmployeesAssignVehiclesModalComponent } from '../../common/components/employees-assign-vehicles-modal/employees-assign-vehicles-modal.component';
import { EMPLOYEE_MODAL_CHIPS } from '../../common/constants/employee-modal-chips';
import { EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS } from '../../common/constants/employees-assigned-vehicles-list-columns';
import { EmployeeStatus } from '../../common/enums/employee-status';
import { Employee } from '../../common/interfaces/employee';
import { EmployeesService } from '../../common/services/employees.service';

@Component({
  selector: 'app-employee-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxInformationRowComponent,
    EmployeeStatusComponent,
    OnyxInformationHeadingComponent,
    OnyxDatePipe,
    OnyxTableComponent,
    OnyxChipsComponent,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxPhonePipe,
    UnavailabilitiesComponent,
  ],
  templateUrl: './employee-modal.component.html',
  styleUrl: './employee-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeModalComponent {
  protected readonly I18N = 'employees.employeeModal';
  protected readonly EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS =
    EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS;
  protected readonly ASSIGNED_VEHICLES_NOT_FOUND: OnyxTableNotFound = {
    icon: { name: 'menu-fleet', size: 24 },
    text: `${this.I18N}.assignedVehiclesNotFound`,
  };

  protected readonly CallHelper = CallHelper;
  protected readonly EmployeeStatus = EmployeeStatus;
  protected readonly DictionaryCode = DictionaryCode;

  protected chips = signal(cloneDeep(EMPLOYEE_MODAL_CHIPS));
  protected group = signal([this.chips()[0].value]);
  protected assignedVehicles = signal<OnyxPaginated<SimplifiedFleet> | null>(
    null,
  );
  protected loading = signal(false);
  protected pagination = signal(SHORT_PAGINATION);
  protected close$ = new Subject<void>();

  private reload$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected employee: Employee,
    protected dialogRef: DialogRef<void>,
    private modalService: OnyxModalService,
    private employeesService: EmployeesService,
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private statisticsService: StatisticsService,
  ) {
    this.reload$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.employeesService.getAssignedVehicles(
            employee.uuid,
            this.pagination(),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((assignedVehicles) => {
        this.assignedVehicles.set(assignedVehicles);
        this.loading.set(false);
      });

    effect(() => {
      this.pagination();
      this.reload$.next();
    });

    const getStatistics$ = () =>
      this.statisticsService.getStatistics(StatisticsType.EMPLOYEE, {
        employeeUuid: this.employee.uuid,
      });

    this.employeesService.reload$
      .pipe(
        startWith(undefined),
        take(1),
        switchMap(() => getStatistics$()),
      )
      .subscribe((statistics) => this.updateStatistics(statistics));

    this.employeesService.reload$
      .pipe(
        tap(() => this.reload$.next()),
        switchMap(() =>
          forkJoin([
            this.employeesService.getEmployee(this.employee.uuid),
            getStatistics$(),
          ]),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: ([employee, statistics]) => {
          this.employee = employee;
          this.updateStatistics(statistics);
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }

  protected editEmployee(): void {
    this.modalService.open<Employee>(EditEmployeeModalComponent, this.employee);
  }

  protected assignVehicles(): void {
    this.modalService.open<Employee[]>(EmployeesAssignVehiclesModalComponent, [
      this.employee,
    ]);
  }

  private updateStatistics(statistics: EmployeeStatistics): void {
    this.chips.update((chips) =>
      chips.map((chip) => ({
        ...chip,
        count: statistics[chip.value],
      })),
    );
  }
}
