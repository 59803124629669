import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxOption,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { concatMap, forkJoin, map, Subject, take } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { FleetCategory } from '../../enums/fleet-category';
import { FleetState } from '../../enums/fleet-state';
import { FleetHelper } from '../../helpers/fleet.helper';
import { Fleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

interface WarningMessage {
  currentVehicle?: string;
  currentTrailer?: string;
  assignedVehicle?: string;
}

enum FleetSetsModalOption {
  CHANGE,
  SWAP,
}

@Component({
  selector: 'app-fleet-sets-modal',
  imports: [
    OnyxModalComponent,
    OnyxTabsComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    TranslatePipe,
    OnyxButtonComponent,
    OnyxMessageComponent,
    OnyxIconComponent,
    OnyxTextFieldComponent,
  ],
  templateUrl: './fleet-sets-modal.component.html',
  styleUrl: './fleet-sets-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetSetsModalComponent implements OnInit {
  protected readonly I18N = 'fleet.fleetSetsModal';
  protected readonly TABS: OnyxTab<FleetSetsModalOption>[] = [
    { name: `${this.I18N}.changeTrailer`, value: FleetSetsModalOption.CHANGE },
    { name: `${this.I18N}.changeSets`, value: FleetSetsModalOption.SWAP },
  ];
  protected readonly isVehicle = FleetHelper.isVehicle(this.vehicle);

  protected readonly FleetSetsModalOption = FleetSetsModalOption;

  protected changeForm = this.buildChangeForm();
  protected changeFormChanges = toSignal(
    this.changeForm.valueChanges.pipe(map(() => this.changeForm.getRawValue())),
  );
  protected swapForm = this.buildSwapForm();
  protected vehicleOptions = signal<OnyxOption<Fleet>[] | null>(null);
  protected trailerOptions = signal<OnyxOption<Fleet>[] | null>(null);
  protected setsOptions = signal<OnyxOption<Fleet>[] | null>(null);
  protected mode = signal(FleetSetsModalOption.CHANGE);
  protected close$ = new Subject<void>();
  protected loading = signal(false);
  protected warningMessage = signal<WarningMessage | null>(null);

  constructor(
    @Inject(DIALOG_DATA) protected vehicle: Fleet,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
  ) {
    effect(() => {
      const form = this.changeFormChanges();
      const trailer = form?.trailer;

      if (!trailer) {
        this.warningMessage.set({
          currentVehicle: form?.vehicle?.generalInformation.registrationNumber,
        });
      } else if (trailer?.vehicle) {
        this.warningMessage.set({
          currentTrailer: trailer.generalInformation.registrationNumber,
          assignedVehicle:
            trailer.vehicle?.generalInformation.registrationNumber,
        });
      } else {
        this.warningMessage.set(null);
      }
    });
  }

  public ngOnInit(): void {
    forkJoin([
      this.loadFleetOptions([]),
      this.loadFleetOptions([
        FleetCategory.SEMI_TRAILER,
        FleetCategory.TRAILER,
      ]),
      this.loadFleetOptions([], true),
    ])
      .pipe(
        take(1),
        map(([vehicles, trailers, sets]) => ({
          vehicleOptions: this.mapOptions(vehicles.items),
          trailerOptions: this.mapOptions(trailers.items),
          setsOptions: this.mapSetsOptions(sets.items),
        })),
      )
      .subscribe(({ vehicleOptions, trailerOptions, setsOptions }) => {
        this.vehicleOptions.set(vehicleOptions);
        this.trailerOptions.set(trailerOptions);
        this.setsOptions.set(setsOptions);
      });
  }

  protected submitChangeForm(): void {
    if (!ValidationHelper.checkValidity(this.changeForm, this.toastService)) {
      return;
    }

    const form = this.changeForm.getRawValue();
    this.loading.set(true);

    this.fleetService
      .assignTrailer(form.vehicle!.uuid, form.trailer?.uuid ?? null)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.changeSucced`);
          this.close$.next();
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected submitSwapForm(): void {
    if (!ValidationHelper.checkValidity(this.swapForm, this.toastService)) {
      return;
    }

    const form = this.swapForm.getRawValue();
    this.loading.set(true);

    this.fleetService
      .assignTrailer(form.firstSet.uuid, form.secondSet!.trailer!.uuid, false)
      .pipe(
        concatMap(() =>
          this.fleetService.assignTrailer(
            form.secondSet!.uuid,
            form.firstSet.trailer!.uuid,
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.swapSucceed`);
          this.close$.next();
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private mapOptions(fleet: Fleet[]): OnyxOption<Fleet>[] {
    return fleet.map((fleet) => ({
      name: this.fleetIdentifierPipe.transform(fleet.generalInformation),
      value: fleet,
    }));
  }

  private mapSetsOptions(fleet: Fleet[]): OnyxOption<Fleet>[] {
    return fleet.map((fleet) => ({
      name: `${this.fleetIdentifierPipe.transform(fleet.generalInformation, 'bracket')} · ${this.fleetIdentifierPipe.transform(fleet.trailer!.generalInformation, 'bracket')}`,
      value: fleet,
    }));
  }

  private loadFleetOptions(category: FleetCategory[], showSetsOnly = false) {
    return this.fleetService.listFleet({
      state: FleetState.ACTIVE,
      category,
      showSetsOnly,
      showAssignedOnly: false,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    });
  }

  private buildChangeForm() {
    return this.fb.group({
      vehicle: this.fb.control<Fleet | null>(
        {
          value: this.isVehicle ? this.vehicle : null,
          disabled: this.isVehicle,
        },
        [Validators.required],
      ),
      trailer: this.fb.control<Fleet | null>({
        value: !this.isVehicle ? this.vehicle : null,
        disabled: !this.isVehicle,
      }),
    });
  }

  private buildSwapForm() {
    return this.fb.group({
      firstSet: this.fb.control<Fleet>(
        { value: this.vehicle, disabled: true },
        [Validators.required],
      ),
      secondSet: this.fb.control<Fleet | null>(null, [Validators.required]),
    });
  }
}
