<div class="warning-item {{ color() }}">
  <div class="icon-box">
    <onyx-icon name="document" [size]="16"></onyx-icon>
  </div>

  <div class="message f-paragraph-medium-3">
    <ng-content></ng-content>

    @if (label(); as label) {
      <span> {{ 'labels.' + label | translate }}</span>

      <span>
        {{ I18N + (color() === 'red' ? '.expired' : '.expires') | translate }}
      </span>
    }

    @if (expiryDate(); as expiryDate) {
      <span class="highlighted">
        {{ expiryDate | onyxDate: 'date' }}
      </span>
    }
  </div>
</div>
