import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxIconComponent } from '@onyx/angular';
import { CellColor } from '../../../../../common/helpers/color.helper';

@Component({
  selector: 'app-fleet-modal-warning',
  imports: [OnyxIconComponent, OnyxDatePipe, TranslatePipe],
  templateUrl: './fleet-modal-warning.component.html',
  styleUrl: './fleet-modal-warning.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalWarningComponent {
  protected readonly I18N = 'fleet.fleetModal';

  public color = input.required<CellColor>();
  public expiryDate = input<string>();
  public label = input<string>();
}
