@let driverData = driver.driverData;
<onyx-modal
  type="center"
  size="m"
  heading="{{ driverData.firstName }} {{ driverData.lastName }}"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <div class="container">
    @if (
      existingPrimaryDriverData() &&
      existingPrimaryDriverData()?.uuid !== driver.uuid
    ) {
      @let vehicle = form.controls.vehicle.getRawValue()!;
      @let primaryDriverData = existingPrimaryDriverData()?.driverData;
      <onyx-message size="s" type="warning" [borderRadius]="true">
        <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

        <p>
          {{ I18N + '.toVehicle' | translate }}

          <span class="f-medium-2">
            {{ vehicle | fleetIdentifier }}
          </span>

          {{ I18N + '.currentAssign' | translate }}

          <span class="f-medium-2">
            {{ primaryDriverData?.firstName }} {{ primaryDriverData?.lastName }}
          </span>

          <br />

          <span class="f-medium-2">
            {{ driverData.firstName }} {{ driverData.lastName }}
          </span>

          {{ I18N + '.secondDriver' | translate }}
        </p>
      </onyx-message>
    }

    <form [formGroup]="form">
      <onyx-dropdown
        formControlName="vehicle"
        [label]="'labels.vehicle' | translate"
        [options]="vehicleOptions()"
        compareKey="uuid"
      ></onyx-dropdown>

      <onyx-dropdown
        formControlName="trailer"
        [label]="'labels.trailer' | translate"
        [options]="trailerOption()"
        compareKey="uuid"
      ></onyx-dropdown>
    </form>
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.assign' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
