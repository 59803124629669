import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { Contractor } from '../interfaces/contractor';

export class ContractorHelper {
  public static fromDto(dto: Contractor): ContractorForm {
    return {
      ...dto,
      companyProfile: {
        ...dto.companyProfile,
        types: dto.companyProfile.types.map((type) => type.value),
      },
      payments: {
        ...dto.payments,
        tradeCreditLimit: dto.payments
          .tradeCreditLimit as ContractorForm['payments']['tradeCreditLimit'],
      },
      branches: dto.branches.map((branch) => ({
        ...branch,
        vatIdentification: branch.vatIdentification ?? {
          country: null,
          vatId: null,
        },
      })),
    };
  }
}
