import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
} from '@angular/core';
import { ButtonActionType } from '../../../internal/interfaces';
import { ICON_BUTTONS } from '../constants';
import { IconButtonColor, IconButtonType } from '../interfaces';

@Component({
  selector: 'onyx-icon-button',
  imports: [NgClass],
  templateUrl: './onyx-icon-button.component.html',
  styleUrl: './onyx-icon-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxIconButtonComponent<
  Type extends IconButtonType = IconButtonType,
  Color extends IconButtonColor<Type> = IconButtonColor<Type>,
> {
  protected readonly ICON_BUTTONS = ICON_BUTTONS;

  @Input({ required: true }) public type!: Type;
  @Input({ required: true }) public color!: Color;
  public size = input.required<'xs' | 's' | 'm' | 'l' | 'xl'>();
  public circle = input(false);
  public active = input(false);
  public selected = input(false);
  public actionType = input<ButtonActionType>('button');
  public tabindex = input(0);
  public disabled = input(false);
}
