@let generalInformation = vehicle.generalInformation;
<onyx-modal
  type="right"
  size="m"
  heading="{{ generalInformation | fleetIdentifier }}"
  [extend]="true"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    <onyx-icon-button
      color="black"
      type="transparent"
      size="m"
      [onyxDropdownOptions]="DROPDOWN_OPTIONS"
      (onyxDropdownValueChange)="$event!()"
      (click)="$event.stopPropagation()"
    >
      <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
    </onyx-icon-button>

    <onyx-icon-button
      color="black"
      type="outlined"
      size="m"
      [circle]="true"
      (click)="editFleet()"
    >
      <onyx-icon name="edit" [size]="16"></onyx-icon>
    </onyx-icon-button>
  </ng-container>

  <app-fleet-modal-vehicle-heading
    bottomOptions
    [vehicle]="vehicle"
    [isVehicle]="isVehicle"
  ></app-fleet-modal-vehicle-heading>

  <div class="container">
    @if (warnings() !== false) {
      <app-fleet-modal-warnings
        [vehicle]="vehicle"
        (redirect)="redirectToDocument()"
        (warnings)="warnings.set($event)"
      ></app-fleet-modal-warnings>
    }

    <app-fleet-modal-main-section
      [vehicle]="vehicle"
      [isVehicle]="isVehicle"
    ></app-fleet-modal-main-section>

    <div class="separator"></div>

    @if (!isArchived) {
      @if (!loading()) {
        <app-fleet-modal-assigned-vehicle
          [vehicle]="assignedVehicle()"
          [primaryVehicle]="vehicle"
          [isVehicle]="isVehicle"
        ></app-fleet-modal-assigned-vehicle>

        <div class="separator"></div>
      } @else {
        <onyx-spinner color="blue" [size]="24"></onyx-spinner>
      }
    }

    <div class="chips-container" [ngClass]="{ hidden: loading() }">
      <onyx-chips
        [chips]="chips()"
        type="secondary"
        [single]="true"
        [wrap]="true"
        [(activeValues)]="group"
      ></onyx-chips>

      @switch (group()[0]) {
        @case (FleetModalOption.ORDER) {
          N/A
        }
        @case (FleetModalOption.DELEGATION) {
          N/A
        }
        @case (FleetModalOption.PROFITABILITY) {
          N/A
        }
        @case (FleetModalOption.CREW) {
          @if (isVehicle) {
            <app-fleet-modal-crew
              [vehicle]="vehicle"
              [isVehicle]="isVehicle"
            ></app-fleet-modal-crew>
          }
        }
        @case (FleetModalOption.DOCUMENTS) {
          <app-fleet-modal-documents
            [vehicle]="vehicle"
          ></app-fleet-modal-documents>
        }
        @case (FleetModalOption.DETAILS) {
          <app-fleet-modal-details
            [vehicle]="vehicle"
            [isVehicle]="isVehicle"
          ></app-fleet-modal-details>
        }
        @case (FleetModalOption.NOTE) {
          <app-modal-note
            [note]="vehicle.note"
            [route]="FleetRoute.EDIT_FLEET.replace(':uuid', vehicle.uuid)"
            [close$]="close$"
            [state]="getRedirectState()"
          ></app-modal-note>
        }
      }
    </div>
  </div>
</onyx-modal>
