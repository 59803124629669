import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  OnInit,
  effect,
  input,
  model,
  viewChild,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxClearButtonComponent } from '../../buttons';
import { OnyxIconComponent } from '../../icons';

@Component({
  selector: 'onyx-search',
  imports: [
    OnyxIconComponent,
    NgClass,
    TranslatePipe,
    OnyxClearButtonComponent,
  ],
  templateUrl: './onyx-search.component.html',
  styleUrl: './onyx-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxSearchComponent implements OnInit {
  protected readonly I18N = `${I18N_NAMESPACE}.search`;

  public query = model<string>();
  public placeholder = input<string>();
  public color = input<'dark-gray' | 'light-gray' | 'white'>('dark-gray');
  public showFocus = input(true);
  public size = input<'s' | 'm'>('m');

  private searchElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('searchElement');

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    effect(
      () => {
        this.searchElementRef().nativeElement.value = this.query() ?? '';
      },
      { injector: this.injector },
    );
  }

  public focus(): void {
    this.searchElementRef().nativeElement.focus();
  }

  protected changeValue(event: Event): void {
    this.query.set((event.target as HTMLInputElement).value);
  }

  protected clearValue(): void {
    this.query.set('');
    this.focus();
  }
}
