import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxButtonComponent, OnyxIconComponent } from '@onyx/angular';
import { Subject } from 'rxjs';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { DelegationsRoute } from '../../../delegations.routes';
import { DelegationModalVehicleItemComponent } from './delegation-modal-vehicle-item/delegation-modal-vehicle-item.component';

@Component({
  selector: 'app-delegation-modal-vehicles',
  standalone: true,
  imports: [
    DelegationModalVehicleItemComponent,
    NgClass,
    OnyxIconComponent,
    OnyxButtonComponent,
    TranslatePipe,
  ],
  templateUrl: './delegation-modal-vehicles.component.html',
  styleUrl: './delegation-modal-vehicles.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalVehiclesComponent {
  protected readonly I18N = 'delegations.delegationModal';

  public vehicle = input<SimplifiedFleet>();
  public trailer = input<SimplifiedFleet | null>();
  public uuid = input.required<string>();
  public close$ = input.required<Subject<void>>();

  constructor(private router: Router) {}

  protected edit(): void {
    this.router.navigateByUrl(
      `${DelegationsRoute.EDIT_DELEGATION.replace(':uuid', this.uuid())}`,
    );
    this.close$().next();
  }
}
