import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxIconComponent } from '@onyx/angular';
import { FleetIdentifierPipe } from '../../../../../../common/components/pipes/fleet-identifier.pipe';
import { Fleet } from '../../../../../fleet/common/interfaces/fleet';

@Component({
  selector: 'app-base-vehicle-cell',
  imports: [OnyxIconComponent, FleetIdentifierPipe],
  templateUrl: './base-vehicle-cell.component.html',
  styleUrl: './base-vehicle-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseVehicleCellComponent {
  public generalInformation = input.required<
    Fleet['generalInformation'] | null
  >();
}
