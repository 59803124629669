import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxColor } from '../interfaces';

@Component({
  selector: 'onyx-dot',
  imports: [NgClass],
  templateUrl: './onyx-dot.component.html',
  styleUrl: './onyx-dot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDotComponent {
  public color = input.required<OnyxColor>();
  public pulse = input(false);
}
