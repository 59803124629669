@if (label(); as label) {
  <onyx-input-label [label]="label"></onyx-input-label>
}

<div class="chips" [ngClass]="{ wrap: wrap() }" [ngStyle]="{ 'gap.px': gap() }">
  @for (group of chips(); track group.value; let count = $count) {
    @let groupActive = activeGroup() === group.value;
    <ng-container>
      @if (showGroups() ?? count > 1) {
        <onyx-chip
          [chip]="group"
          [active]="groupActive"
          [color]="groupActive ? 'black' : 'white'"
          (click)="changeGroup(group.value)"
          #groupChipElement
        ></onyx-chip>
      }

      @if (groupActive || count === 1) {
        @if (selectAll() && group.chips.length !== 0) {
          <onyx-chip
            [chip]="{
              name:
                (selectAll() === true ? I18N + '.all' : $any(selectAll()))
                | translate,
              value: '',
              count: group.count,
            }"
            [active]="isSelectedAll()"
            [color]="count === 1 && type() !== 'secondary' ? 'white' : 'blue'"
            (click)="selectAllChips()"
            #selectAllChipElement
          ></onyx-chip>
        }

        @for (chip of group.chips; track chip.value) {
          @let active =
            (!selectAll() || !isSelectedAll()) &&
            activeValues().includes(chip.value);
          @let disabled = !!chip.disabled;
          <onyx-chip
            [chip]="chip"
            [active]="active"
            [color]="count === 1 && type() !== 'secondary' ? 'white' : 'blue'"
            [disabled]="disabled"
            (click)="!disabled ? toggleChip(chip, !active) : null"
            #chipElement
          ></onyx-chip>
        }
      }
    </ng-container>
  }
</div>

<ng-template let-option="option" #optionTemplate>
  <div class="option">
    <span class="f-medium-1">{{ option.name }}</span>
    @if (option.count != null) {
      <span class="count">{{ option.count }}</span>
    }
  </div>
</ng-template>
