import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  OnInit,
  computed,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective, NgControl, Validators } from '@angular/forms';

@Component({
  selector: 'onyx-base-form-control',
  imports: [],
  template: ``,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxBaseFormControlComponent implements OnInit, AfterViewInit {
  protected isValid = computed(() => !this.isInvalid_());
  protected isOptional = computed(() => !this.isRequired_());

  private isRequired_ = signal(false);
  protected get isRequired() {
    return this.isRequired_.asReadonly();
  }

  private isInvalid_ = signal(false);
  protected get isInvalid() {
    return this.isInvalid_.asReadonly();
  }

  private formGroupDirective_ = signal<FormGroupDirective | null>(null);
  protected get formGroupDirective() {
    return this.formGroupDirective_.asReadonly();
  }

  private formControl_ = signal<NgControl | null>(null);
  protected get formControl() {
    return this.formControl_.asReadonly();
  }

  constructor(
    protected injector: Injector,
    protected destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.formGroupDirective_.set(
      this.injector.get(FormGroupDirective, null, {
        optional: true,
      }),
    );

    this.formControl_.set(
      this.injector.get(NgControl, null, {
        optional: true,
      }),
    );
  }

  public ngAfterViewInit(): void {
    if (!this.formControl_()) return;

    const submit$ = this.formGroupDirective_()
      ?.ngSubmit.asObservable()
      ?.pipe(takeUntilDestroyed(this.destroyRef));
    const statusChanges$ = this.formControl_()?.statusChanges?.pipe(
      takeUntilDestroyed(this.destroyRef),
    );
    const valueChanges$ = this.formControl_()?.valueChanges?.pipe(
      takeUntilDestroyed(this.destroyRef),
    );

    this.update();
    submit$?.subscribe(() => this.update());
    statusChanges$?.subscribe(() => this.update());
    valueChanges$?.subscribe(() => this.update());
  }

  private update(): void {
    this.isRequired_.set(this.getIsRequired());
    this.isInvalid_.set(this.getIsInvalid());
  }

  private getIsRequired(): boolean {
    const control = this.formControl()?.control;
    if (!control) return false;

    return (
      control.hasValidator(Validators.required) ||
      control.hasValidator(Validators.requiredTrue)
    );
  }

  private getIsInvalid(): boolean {
    const control = this.formControl()?.control;
    if (!control) return false;

    const isInvalid = control.invalid;
    const isDirty = control.dirty && control.touched;
    const isSubmitted = this.formGroupDirective()?.submitted ?? false;

    return isInvalid && (isDirty || isSubmitted);
  }
}
