import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { addOrderResolver } from './common/resolvers/add-order.resolver';
import { editOrderResolver } from './common/resolvers/edit-order.resolver';

export enum OrdersRoute {
  ORDERS_LIST = '/orders',
  ADD_ORDER = '/orders/add',
  ORDER_CARD = '/orders/:uuid',
  EDIT_ORDER = '/orders/:uuid/edit',
}

export const ORDERS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./orders-list/orders-list.component').then(
        (m) => m.OrdersListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./order-form/order-form.component').then(
        (m) => m.OrderFormComponent,
      ),
    data: { mode: OnyxFormMode.ADD },
    resolve: { order: addOrderResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./order-form/order-form.component').then(
        (m) => m.OrderFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { order: editOrderResolver },
  },
] as const;
