import { FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { OnyxTimepickerTime } from '../components';
import { onyxMaxDateValidator } from './onyx-max-date.validator';
import { onyxMaxTimeValidator } from './onyx-max-time.validator';

export const onyxMaxDateTimeValidator =
  (
    maxDate = DateTime.now(),
    maxTime: DateTime | OnyxTimepickerTime = DateTime.now(),
    dateControlName = 'date',
    timeControlName = 'time',
  ) =>
  (form: FormGroup): void => {
    const dateControl = form.get(dateControlName)!;
    const timeControl = form.get(timeControlName)!;
    if (!dateControl.valid || !timeControl.valid) return;

    const dateError = onyxMaxDateValidator(maxDate)(dateControl);
    dateControl.setErrors(dateError ?? null);

    if (dateControl.invalid) return;

    const timeError = onyxMaxTimeValidator(
      maxTime,
      dateControl.getRawValue(),
    )(timeControl);
    timeControl.setErrors(timeError ?? null);
  };
