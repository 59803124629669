import { OnyxAddress } from '../components';

export class AddressHelper {
  public static composeLabel(
    address: Omit<OnyxAddress, 'type' | 'label' | 'coordinates'>,
  ): string {
    return `${address.street} ${address.houseNumber}${address.apartmentNumber ? `/${address.apartmentNumber}` : ''}, ${address.zipCode} ${address.city}, ${address.countryCode?.toUpperCase()}`;
  }

  public static composeCoordinatesLabel(
    address: Pick<OnyxAddress, 'coordinates'>,
  ): string {
    const coordinates = address.coordinates;
    return `${+coordinates.latitude.toFixed(5)}, ${+coordinates.longitude.toFixed(5)}`;
  }

  public static getHaversineDistance(
    from: Pick<OnyxAddress, 'coordinates'>,
    to: Pick<OnyxAddress, 'coordinates'>,
  ): number {
    const EARTH_RADIUS = 6.371e6;

    const toRadians = (degrees: number) => degrees * (Math.PI / 180);

    const deltaLatitude = toRadians(
      to.coordinates.latitude - from.coordinates.latitude,
    );
    const deltaLongitude = toRadians(
      to.coordinates.longitude - from.coordinates.longitude,
    );

    const a =
      Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
      Math.cos(toRadians(from.coordinates.latitude)) *
        Math.cos(toRadians(to.coordinates.latitude)) *
        Math.sin(deltaLongitude / 2) *
        Math.sin(deltaLongitude / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return EARTH_RADIUS * c;
  }
}
