import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  ViewContainerRef,
  effect,
  input,
  signal,
} from '@angular/core';
import { isString } from 'lodash';
import { OnyxTooltipService } from '../internal/services/onyx-tooltip.service';

@Directive({
  selector: '[onyxTextOverflow]',
  standalone: true,
})
export class OnyxTextOverflowDirective implements OnInit {
  public onyxTextOverflow = input<string | string[]>();
  public onyxTextOverflowPlaceholder = input<string>();
  public onyxTextOverflowElementRef = input<ElementRef>();

  private hasOverflow = signal(false);

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    private tooltipService: OnyxTooltipService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  public ngOnInit(): void {
    effect(
      () => {
        const text = this.onyxTextOverflow();
        if (!text?.length) {
          this.elementRef.nativeElement.innerText =
            this.onyxTextOverflowPlaceholder();
          this.hasOverflow.set(false);
          return;
        }

        if (isString(text)) {
          this.compressString(text);
        } else {
          this.compressArray(text);
        }
      },
      { injector: this.injector },
    );
  }

  @HostListener('mouseenter')
  protected showTooltip(): void {
    const text = this.onyxTextOverflow();
    if (!text || !this.hasOverflow()) return;

    this.tooltipService.attachTooltip(
      this.onyxTextOverflowElementRef() ?? this.elementRef,
      this.viewContainerRef,
      isString(text) ? text : text.join(', '),
    );
  }

  private compressString(text: string): void {
    const element = this.elementRef.nativeElement;
    element.innerText = text;

    if (element.scrollWidth <= element.clientWidth) return;
    this.hasOverflow.set(true);

    let counter = 0;
    while (element.scrollWidth > element.clientWidth) {
      element.innerText = '...'.concat(text.slice(++counter, text.length));
    }
  }

  private compressArray(text: string[]): void {
    const element = this.elementRef.nativeElement;
    element.innerText = text.join(', ');

    if (element.scrollWidth <= element.clientWidth) return;
    this.hasOverflow.set(true);

    let counter = text.length;
    while (element.scrollWidth > element.clientWidth && counter > 1) {
      element.innerText = text
        .slice(0, --counter)
        .join(', ')
        .concat(`, +${text.length - counter}`);
    }
  }
}
