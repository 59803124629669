import { OrderForm } from '../../order-form/order-form.component';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderTimeWindowType } from '../enums/order-time-window-type';
import { Order } from '../interfaces/order';
import { OrderLoadingPoint, OrderPoint } from '../interfaces/order-point';

export class OrderHelper {
  public static getFirstLoadingPoint(points: OrderPoint[]): OrderLoadingPoint {
    return points.find(
      (point) => point.category === OrderPointCategory.LOADING,
    )!;
  }

  public static getLoadingPointDeadline(
    loadingPoint: OrderLoadingPoint,
  ): string {
    const { type, windows } = loadingPoint.timeWindows;
    if (type === OrderTimeWindowType.FIX) {
      return `${windows[0].date}T${windows[0].time}`;
    }

    const lastTimeWindow = windows[windows.length - 1];
    return `${lastTimeWindow.date}T${lastTimeWindow.timeRange.to}`;
  }

  public static fromDto(dto: Order): OrderForm {
    return {
      basicInformation: {
        ...dto.basicInformation,
        sender: dto.basicInformation.sender.uuid,
        loadedSemiTrailer:
          dto.basicInformation.loadedSemiTrailer?.uuid ??
          dto.basicInformation.loadedSemiTrailerRegistrationNumber ??
          null,
      },
      parameters: {
        ...dto.parameters,
        temperatureRange: {
          from: dto.parameters.temperatureRange?.from ?? null,
          to: dto.parameters.temperatureRange?.to ?? null,
        },
      },
      documents: dto.documents,
      note: { content: dto.note?.content ?? null },
      points: dto.points,
    };
  }
}
