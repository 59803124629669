import { PickDeep } from 'type-fest/source/pick-deep';
import { FleetForm } from '../../fleet-form/fleet-form.component';
import { FleetCategory } from '../enums/fleet-category';
import { FleetState } from '../enums/fleet-state';
import { Fleet, SimplifiedFleet } from '../interfaces/fleet';

export class FleetHelper {
  public static getType(
    initial:
      | PickDeep<Fleet | SimplifiedFleet, 'generalInformation.category'>
      | Pick<
          Fleet['generalInformation'] | SimplifiedFleet['generalInformation'],
          'category'
        >,
  ): 'vehicle' | 'trailer' {
    const { category } =
      'generalInformation' in initial ? initial.generalInformation : initial;

    return {
      [FleetCategory.SEMI_TRAILER]: 'trailer',
      [FleetCategory.SEMI_TRUCK]: 'vehicle',
      [FleetCategory.STRAIGHT_TRUCK]: 'vehicle',
      [FleetCategory.TRAILER]: 'trailer',
      [FleetCategory.VAN]: 'vehicle',
    }[category] as 'vehicle' | 'trailer';
  }

  public static isVehicle(fleet: Fleet | SimplifiedFleet): boolean {
    return this.getType(fleet) === 'vehicle';
  }

  public static isTrailer(fleet: Fleet | SimplifiedFleet): boolean {
    return this.getType(fleet) === 'trailer';
  }

  public static isArchived(fleet: Fleet | SimplifiedFleet): boolean {
    return fleet.generalInformation.state === FleetState.ARCHIVED;
  }

  public static fromDto(dto: Fleet): FleetForm {
    return {
      ...dto,
      generalInformation: {
        ...dto.generalInformation,
        assignedBase: dto.generalInformation.assignedBase?.uuid ?? null,
      },
      fuelTankCapacity: dto.fuelTankCapacity as FleetForm['fuelTankCapacity'],
      ownership: {
        ...dto.ownership,
        vehicleValue: dto.ownership
          .vehicleValue as FleetForm['ownership']['vehicleValue'],
        leasingProfile: null,
        loanProfile: null,
        rentalProfile: null,
        selfDeposit: dto.ownership
          .selfDeposit as FleetForm['ownership']['selfDeposit'],
        buyout: dto.ownership.buyout as FleetForm['ownership']['buyout'],
        installment: dto.ownership
          .installment as FleetForm['ownership']['installment'],
      },
      licensesAndPermits:
        dto.licensesAndPermits as FleetForm['licensesAndPermits'],
      additionalParameters: {
        ...dto.additionalParameters,
        temperatureRange: dto.additionalParameters
          .temperatureRange as FleetForm['additionalParameters']['temperatureRange'],
      },
      note: {
        content: dto.note?.content ?? null,
      },
      tachograph: dto.tachograph as FleetForm['tachograph'],
      co2Emissions: dto.co2Emissions as FleetForm['co2Emissions'],
      comprehensiveInsurance:
        dto.comprehensiveInsurance as FleetForm['comprehensiveInsurance'],
    };
  }
}
