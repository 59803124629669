@for (toast of config.toasts(); track toast) {
  <onyx-toast
    [color]="toast.color"
    [message]="toast.message"
    [icon]="toast?.icon"
    [templateContext]="toast?.templateContext"
    [forceClose]="$index > 2"
    (closeToast)="config.hideToast(toast)"
  ></onyx-toast>
}
