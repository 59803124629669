import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxDropdownOptionsGroup,
  OnyxIconBoxColor,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxSpinnerComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ModalNoteComponent } from '../../../common/components/modals/modal-note/modal-note.component';
import { FleetIdentifierPipe } from '../../../common/components/pipes/fleet-identifier.pipe';
import { DictionaryCode } from '../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { FleetAssignDriverModalComponent } from '../common/components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import { FleetAssignEmployeesModalComponent } from '../common/components/fleet-assign-employees-modal/fleet-assign-employees-modal.component';
import { FleetSetsModalComponent } from '../common/components/fleet-sets-modal/fleet-sets-modal.component';
import { FleetRouterStateKey } from '../common/enums/fleet-router-state-key';
import { FleetHelper } from '../common/helpers/fleet.helper';
import { Fleet } from '../common/interfaces/fleet';
import { FleetService } from '../common/services/fleet.service';
import { FleetRoute } from '../fleet.routes';
import { FleetModalAssignedVehicleComponent } from './fleet-modal-assigned-vehicle/fleet-modal-assigned-vehicle.component';
import { FleetModalCrewComponent } from './fleet-modal-crew/fleet-modal-crew.component';
import { FleetModalDetailsComponent } from './fleet-modal-details/fleet-modal-details.component';
import { FleetModalDocumentsComponent } from './fleet-modal-documents/fleet-modal-documents.component';
import { FleetModalMainSectionComponent } from './fleet-modal-main-section/fleet-modal-main-section.component';
import { FleetModalVehicleHeadingComponent } from './fleet-modal-vehicle-heading/fleet-modal-vehicle-heading.component';
import { FleetModalWarningsComponent } from './fleet-modal-warnings/fleet-modal-warnings.component';

enum FleetModalOption {
  ORDER = 'order',
  DELEGATION = 'delegation',
  PROFITABILITY = 'profitability',
  CREW = 'crew',
  DOCUMENTS = 'documents',
  DETAILS = 'details',
  NOTE = 'note',
}

@Component({
  selector: 'app-fleet-modal',
  imports: [
    OnyxModalComponent,
    FleetIdentifierPipe,
    NgClass,
    OnyxIconComponent,
    OnyxChipsComponent,
    FleetModalMainSectionComponent,
    FleetModalAssignedVehicleComponent,
    FleetModalVehicleHeadingComponent,
    FleetModalDocumentsComponent,
    FleetModalWarningsComponent,
    FleetModalCrewComponent,
    FleetModalDetailsComponent,
    OnyxSpinnerComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
    ModalNoteComponent,
  ],
  templateUrl: './fleet-modal.component.html',
  styleUrl: './fleet-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalComponent implements OnInit {
  protected readonly I18N = 'fleet.fleetModal';

  protected readonly OnyxIconBoxColor = OnyxIconBoxColor;
  protected readonly FleetModalOption = FleetModalOption;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly FleetRoute = FleetRoute;
  protected readonly FleetRouterStateKey = FleetRouterStateKey;

  private readonly documentsElement = viewChild.required(
    FleetModalDocumentsComponent,
    { read: ElementRef<any> },
  );

  protected isArchived = FleetHelper.isArchived(this.vehicle);
  protected isVehicle = FleetHelper.isVehicle(this.vehicle);
  protected DROPDOWN_OPTIONS: OnyxDropdownOptionsGroup<() => void>[] = [
    ...(!this.isArchived
      ? [
          {
            options: [
              {
                name: 'fleet.services.scheduleService',
                value: () => this.scheduleService(),
              },
              {
                name: `${this.I18N}.switchVehicle`,
                value: () => this.assignVehicle(),
              },
            ],
          },
        ]
      : []),

    ...(!this.isArchived && this.isVehicle
      ? [
          {
            options: [
              {
                name: 'fleet.fleetList.assignEmployees',
                value: () => this.assignEmployees(),
              },
              {
                name: `fleet.fleetList.assignDrivers`,
                value: () => this.assignDrivers(),
              },
            ],
          },
        ]
      : []),
    {
      options: [
        {
          name: `${this.I18N}.finishDelegation`,
          value: () => alert('HOLD: BE'),
        },
      ],
    },
  ];
  protected assignedVehicle = signal<Fleet | null>(null);
  protected group = signal([
    this.isArchived ? FleetModalOption.DOCUMENTS : FleetModalOption.CREW,
  ]);
  protected warnings = signal<boolean | null>(null);
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  protected chips = computed<OnyxChip<FleetModalOption>[]>(() => [
    { name: 'labels.order', value: FleetModalOption.ORDER },
    { name: 'labels.delegation', value: FleetModalOption.DELEGATION },
    {
      name: 'labels.profitability',
      value: FleetModalOption.PROFITABILITY,
    },
    ...(this.isVehicle && !this.isArchived
      ? [
          {
            name: `${this.I18N}.crew`,
            value: FleetModalOption.CREW,
          },
        ]
      : []),
    { name: 'labels.documents', value: FleetModalOption.DOCUMENTS },
    { name: `${this.I18N}.details`, value: FleetModalOption.DETAILS },
    {
      name: 'labels.note',
      value: FleetModalOption.NOTE,
    },
  ]);

  constructor(
    @Inject(DIALOG_DATA) protected vehicle: Fleet,
    protected dialogRef: DialogRef<void>,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private router: Router,
    private modalService: OnyxModalService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    const simplifiedVehicle = this.isVehicle
      ? this.vehicle.trailer
      : this.vehicle.vehicle;
    if (!simplifiedVehicle) return;

    this.loading.set(true);
    this.fleetService
      .getFleet(
        simplifiedVehicle.generalInformation.category,
        simplifiedVehicle.uuid,
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (vehicle) => this.assignedVehicle.set(vehicle),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected scheduleService(): void {
    this.router.navigateByUrl(
      FleetRoute.FLEET_SERVICE.replace(':uuid', this.vehicle.uuid),
      { state: this.getRedirectState() },
    );
    this.close$.next();
  }

  protected editFleet(): void {
    this.router.navigateByUrl(
      FleetRoute.EDIT_FLEET.replace(':uuid', this.vehicle.uuid),
      { state: this.getRedirectState() },
    );
    this.close$.next();
  }

  protected assignEmployees(): void {
    this.modalService.open<Fleet[]>(FleetAssignEmployeesModalComponent, [
      this.vehicle,
    ]);
  }

  protected assignDrivers(): void {
    this.modalService.open<Fleet>(
      FleetAssignDriverModalComponent,
      this.vehicle,
    );
  }
  protected assignVehicle(): void {
    this.modalService.open<Fleet>(FleetSetsModalComponent, this.vehicle);
  }

  protected redirectToDocument(): void {
    this.group.set([FleetModalOption.DOCUMENTS]);

    setTimeout(() =>
      this.documentsElement().nativeElement.scrollIntoView({
        behavior: 'smooth',
      }),
    );
  }

  protected getRedirectState() {
    return {
      [FleetRouterStateKey.CATEGORY]: this.vehicle.generalInformation.category,
    };
  }
}
