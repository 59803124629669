import { inject } from '@angular/core';
import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { FleetRoute } from '../../fleet.routes';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';

export const fleetCategoryGuard: CanActivateFn = () => {
  const router = inject(Router);

  const state = router.getCurrentNavigation()?.extras?.state;
  const category = state?.[FleetRouterStateKey.CATEGORY];

  if (category) return true;

  const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};
