import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { OnyxDate } from '../components';

export const onyxMaxDateValidator =
  (maxDate = DateTime.now()): ValidatorFn =>
  (control: AbstractControl<string | OnyxDate>): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    let date: DateTime;
    if (isString(value)) {
      date = DateTime.fromISO(value)!;
    } else if (value.date) {
      date = DateTime.fromISO(value.date);
    } else if (value.to) {
      date = DateTime.fromISO(value.to);
    } else {
      return { maxDate: maxDate.toISODate() };
    }

    date = date.startOf('day');
    maxDate = maxDate.startOf('day');

    return date > maxDate ? { maxDate: maxDate.toISODate() } : null;
  };
