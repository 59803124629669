@if (parameters(); as parameters) {
  @for (item of parameters; track item[0]) {
    @let value = $any(item[1]);
    @let key = item[0];

    <onyx-icon-box
      [color]="OnyxIconBoxColor.GRAY"
      [label]="'labels.' + key | translate"
    >
      <onyx-icon name="more-alt" [size]="16"></onyx-icon>

      @if (value !== true) {
        @switch (key) {
          @case ('adrClasses') {
            @for (class of value; track class) {
              <span>
                {{ DictionaryCode.ADR_CLASS + '.' + class | translate }}
              </span>
            }
          }
          @case ('temperatureRange') {
            <span>
              {{ value | onyxTemperature }}
            </span>
          }
          @case ('coilWellLength') {
            <app-unit-cell [value]="value" unit="m"></app-unit-cell>
          }
          <!-- Replace with multiple @case ('hooksNumber' || 'matsNumber' || 'beltsNumber') after Angular fix -->
          @case ('hooksNumber') {
            <app-unit-cell
              [value]="value"
              [unit]="'units.pieces' | translate"
            ></app-unit-cell>
          }
          @case ('matsNumber') {
            <app-unit-cell
              [value]="value"
              [unit]="'units.pieces' | translate"
            ></app-unit-cell>
          }
          @case ('beltsNumber') {
            <app-unit-cell
              [value]="value"
              [unit]="'units.pieces' | translate"
            ></app-unit-cell>
          }
          <!---->
          @case ('trailerSize') {
            <span>
              {{ DictionaryCode.TRAILER_SIZE + '.' + value | translate }}
            </span>
          }
          @case ('semiTrailerSize') {
            <span>
              {{ DictionaryCode.SEMI_TRAILER_SIZE + '.' + value | translate }}
            </span>
          }
          @case ('other') {
            @for (parameter of value; track parameter) {
              <span>{{ parameter | onyxLanguage }}</span>
            }
          }
          @default {
            <span>{{ value }}</span>
          }
        }
      }
    </onyx-icon-box>
  }
}
