import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'onyx-information-heading',
  imports: [],
  templateUrl: './onyx-information-heading.component.html',
  styleUrl: './onyx-information-heading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxInformationHeadingComponent {
  public count = input<number>();
}
