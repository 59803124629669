export enum OnyxMapMarkerType {
  AIRPORT = 'airport',
  BASE = 'base',
  BRIDGE = 'bridge',
  CAR_WASH = 'car-wash',
  CORRESPONDENCE_ADDRESS = 'correspondence-address',
  CUSTOMS = 'customs',
  FERRY = 'ferry',
  GARAGE = 'garage',
  GAS_STATION = 'gas-station',
  PARKING = 'parking',
  POINT = 'point',
  POINT_OF_INTEREST = 'point-of-interest',
  PORT = 'port',
  TRAIN = 'train',
  TUNNEL = 'tunnel',
  WAREHOUSE = 'warehouse',
}
