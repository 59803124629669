@if (countryCode(); as countryCode) {
  <div class="country-cell">
    @let name = DictionaryCode.COUNTRY + '.' + countryCode | translate;
    <onyx-flag
      [countryCode]="$any(countryCode)"
      [onyxTooltip]="name"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
    ></onyx-flag>

    @if (!onlyFlag()) {
      <p>
        @if (short()) {
          {{ countryCode | uppercase }}
        } @else {
          {{ name }}
        }
      </p>
    }
  </div>
} @else {
  -
}
