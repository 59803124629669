<div class="suggestion-box f-label-2">
  @for (suggestion of suggestionsData(); track suggestion.value) {
    <button
      [ngClass]="{ disabled: suggestion.disabled }"
      (click)="
        suggestion.disabled ? null : setValue(suggestion.value);
        $event.stopPropagation()
      "
      type="button"
    >
      {{ suggestion.name | translate }}
    </button>
  }
</div>
