import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAvatarComponent,
  OnyxBadgeComponent,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxModalComponent,
  OnyxOption,
  OnyxToastService,
} from '@onyx/angular';
import { intersectionBy, union } from 'lodash';
import { map, Subject, take } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { FleetState } from '../../../../../fleet/common/enums/fleet-state';
import { Fleet } from '../../../../../fleet/common/interfaces/fleet';
import { FleetService } from '../../../../../fleet/common/services/fleet.service';
import { Employee } from '../../interfaces/employee';
import { EmployeesService } from '../../services/employees.service';

@Component({
  selector: 'app-employees-assign-vehicles-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxBadgeComponent,
    OnyxAvatarComponent,
  ],
  templateUrl: './employees-assign-vehicles-modal.component.html',
  styleUrl: './employees-assign-vehicles-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeesAssignVehiclesModalComponent implements OnInit {
  protected readonly I18N = 'employees.assignVehiclesModal';

  protected form = this.buildForm();
  protected loading = signal(false);
  protected vehiclesOptions = signal<OnyxOption<string>[] | null>(null);
  protected close$ = new Subject<void>();

  protected isSingle = computed(() => this.employees.length === 1);

  constructor(
    @Inject(DIALOG_DATA) protected employees: Employee[],
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private fleetService: FleetService,
    private employeesService: EmployeesService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
  ) {}

  public ngOnInit(): void {
    this.fleetService
      .listFleet({
        state: FleetState.ACTIVE,
        category: [],
        showSetsOnly: false,
        showAssignedOnly: false,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
      .pipe(
        take(1),
        map((vehicles) =>
          vehicles.items.map((vehicle: Fleet) => ({
            name: this.fleetIdentifierPipe.transform(vehicle),
            value: vehicle.uuid,
          })),
        ),
      )
      .subscribe((options) => this.vehiclesOptions.set(options));

    if (this.employees.length > 1) {
      const vehicles = this.employees
        .map((employee) => employee.assignedVehicles)
        .flat();
      const commonVehicles = intersectionBy(vehicles, 'uuid');
      const selectedVehicles =
        commonVehicles.length > 0 ? commonVehicles : union(...vehicles);

      this.form.controls.vehicles.setValue(selectedVehicles);

      if (commonVehicles.length > 0) {
        this.toastService.showInformation(`${this.I18N}.commonVehicles`);
      } else {
        this.toastService.showInformation(`${this.I18N}.allVehicles`);
      }
    } else {
      this.form.controls.vehicles.setValue(this.employees[0].assignedVehicles);
    }
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    // HOLD: wait for mass actions
    if (!this.isSingle()) return;

    this.loading.set(true);
    this.employeesService
      .assignVehicles(form, this.employees[0].uuid)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.assignedVehicles`);
          this.close$.next();
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      vehicles: this.fb.control<string[]>([], [Validators.required]),
    });
  }
}
