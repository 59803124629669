import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxInformationRowComponent } from '@onyx/angular';
import { AmountCellComponent } from '../../../../common/components/cells/amount-cell/amount-cell.component';
import { ModalDateRowComponent } from '../../../../common/components/modal-documents/modal-date-row/modal-date-row.component';
import { ModalDocumentsHeadingComponent } from '../../../../common/components/modal-documents/modal-documents-heading/modal-documents-heading.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ColorHelper } from '../../../../common/helpers/color.helper';
import { Fleet } from '../../common/interfaces/fleet';

@Component({
  selector: 'app-fleet-modal-documents',
  imports: [
    OnyxInformationRowComponent,
    ModalDocumentsHeadingComponent,
    TranslatePipe,
    ModalDateRowComponent,
    AmountCellComponent,
  ],
  templateUrl: './fleet-modal-documents.component.html',
  styleUrl: './fleet-modal-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalDocumentsComponent {
  protected readonly ColorHelper = ColorHelper;
  protected readonly DictionaryCode = DictionaryCode;

  public vehicle = input.required<Fleet>();
}
