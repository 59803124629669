import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDatePipe,
  OnyxIconComponent,
  OnyxLinkComponent,
  OnyxTimePipe,
} from '@onyx/angular';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { AddressComponent } from '../../../../../common/components/address/address.component';
import { Delegation } from '../../../common/interfaces/delegation';
import { DelegationsRoute } from '../../../delegations.routes';

@Component({
  selector: 'app-delegation-modal-period',
  standalone: true,
  imports: [
    OnyxIconComponent,
    OnyxDatePipe,
    OnyxTimePipe,
    AddressComponent,
    OnyxButtonComponent,
    OnyxLinkComponent,
    NgClass,
    TranslateModule,
  ],
  templateUrl: './delegation-modal-period.component.html',
  styleUrl: './delegation-modal-period.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalPeriodComponent {
  protected readonly I18N = 'delegations';

  public start = input.required<Delegation['start']>();
  public end = input.required<Delegation['end']>();
  public uuid = input.required<string>();
  public close$ = input.required<Subject<void>>();

  protected isLate = computed(() => {
    const { date, time } = this.end();
    if (!date || !time) return null;

    const endDate = DateTime.fromISO(`${date}T${time}`);
    return endDate < DateTime.now();
  });

  constructor(private router: Router) {}

  protected edit(): void {
    this.router.navigateByUrl(
      `${DelegationsRoute.EDIT_DELEGATION.replace(':uuid', this.uuid())}`,
    );
    this.close$().next();
  }
}
