import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CoordinatesHelper } from '../helpers';

export const onyxCoordinatesValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value;
  if (!value) return null;

  const coordinates = CoordinatesHelper.fromString(value);
  return coordinates ? null : { coordinates: true };
};
