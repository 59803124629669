import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxBadgeComponent,
  OnyxIconBoxColor,
  OnyxIconBoxComponent,
  OnyxIconComponent,
} from '@onyx/angular';
import { StringCellComponent } from '../../../../common/components/cells/string-cell/string-cell.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { FleetCategory } from '../../common/enums/fleet-category';
import { FleetState } from '../../common/enums/fleet-state';
import { Fleet } from '../../common/interfaces/fleet';
import { FleetModalParametersComponent } from '../fleet-modal-parameters/fleet-modal-parameters.component';

@Component({
  selector: 'app-fleet-modal-main-section',
  imports: [
    NgClass,
    TranslatePipe,
    OnyxIconComponent,
    OnyxBadgeComponent,
    OnyxIconBoxComponent,
    FleetModalParametersComponent,
    StringCellComponent,
  ],
  templateUrl: './fleet-modal-main-section.component.html',
  styleUrl: './fleet-modal-main-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalMainSectionComponent {
  protected readonly I18N = 'fleet.fleetModal';

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly FleetState = FleetState;
  protected readonly OnyxIconBoxColor = OnyxIconBoxColor;
  protected readonly FleetCategory = FleetCategory;

  public vehicle = input.required<Fleet>();
  public isVehicle = input.required<boolean>();

  protected drivers = computed(() => {
    if (!this.isVehicle()) return null;

    return Object.values(this.vehicle().drivers!)
      .filter((driver) => driver != null)
      .map((driver) => {
        const driverData = driver.driverData;
        return `${driverData.firstName} ${driverData.lastName}`;
      });
  });
}
