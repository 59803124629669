import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxDate, OnyxDatePipe, OnyxIconComponent } from '@onyx/angular';

@Component({
  selector: 'app-unavailability-range-cell',
  imports: [OnyxIconComponent, OnyxDatePipe],
  templateUrl: './unavailability-range-cell.component.html',
  styleUrl: './unavailability-range-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailabilityRangeCellComponent {
  public range = input.required<OnyxDate | null>();
}
