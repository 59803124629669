import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { forkJoin, map, Observable } from 'rxjs';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import {
  FleetValidation,
  FleetValidationByType,
} from '../interfaces/validation/fleet-validation';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getFleetValidation(
    category: FleetCategory,
  ): Observable<FleetValidation> {
    const type = category.includes('trailer') ? 'trailer' : 'vehicle';
    return this.get('/validation/fleet', { params: { type } });
  }

  public getFleetValidationByType(): Observable<FleetValidationByType> {
    const validations$ = [
      FleetCategory.SEMI_TRUCK,
      FleetCategory.SEMI_TRAILER,
    ].map((category) => this.getFleetValidation(category));

    return forkJoin(validations$).pipe(
      map((validations): FleetValidationByType => {
        const mergedValidations = merge({}, ...validations) as FleetValidation;
        return Object.values(mergedValidations).reduce(
          (validationsByType, validationTypes) => {
            for (const [type, validation] of Object.entries(validationTypes)) {
              validationsByType = merge(validationsByType, {
                [type]: validation,
              });
            }
            return validationsByType;
          },
          {} as FleetValidationByType,
        );
      }),
    );
  }
}
