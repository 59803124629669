import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { Observable, of, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../common/services/api.service';
import { OrderFormDto } from '../../order-form/order-form.component';
import { OrderCategory } from '../enums/order-category';
import { OrderStatus } from '../enums/order-status';
import { Order } from '../interfaces/order';
import { ORDER_MOCKS } from '../mocks/order-mocks';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listOrders(
    _params: {
      category: OrderCategory;
      statuses: OrderStatus[] | null;
      recommendedAction: boolean;
      showAssignedOnly: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Order>> {
    // return this.get('/orders', {
    //   params: {
    //     category: params.category,
    //     'status[]': params.statuses ?? [],
    //     recommendedAction: params.recommendedAction,
    //     showAssignedOnly: params.showAssignedOnly,
    //     page: params.page,
    //     limit: params.limit,
    //   },
    // });
    return of(ORDER_MOCKS);
  }

  public getOrder(uuid: string): Observable<Order> {
    return this.get<Order>(`/orders/${uuid}`);
  }

  public addOrder(dto: OrderFormDto): Observable<Order> {
    return this.post<Order>('/orders', dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editOrder(uuid: string, dto: OrderFormDto): Observable<Order> {
    return this.put<Order>(`/orders/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
