import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxModalService, OnyxToastService } from '@onyx/angular';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { DelegationModalComponent } from '../../delegation-modal/delegation-modal.component';
import { DelegationsRoute } from '../../delegations.routes';
import { Delegation } from '../interfaces/delegation';
import { DelegationsService } from '../services/delegations.service';

export const delegationCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const delegationsService = inject(DelegationsService);
  const modalService = inject(OnyxModalService);
  const router = inject(Router);
  const toastService = inject(OnyxToastService);

  const uuid = route.paramMap.get('uuid')!;

  delegationsService.getDelegation(uuid).subscribe({
    next: (delegation) =>
      modalService.open<Delegation>(DelegationModalComponent, delegation),
    error: (error) =>
      ValidationHelper.handleUnexpectedError(error, toastService),
  });

  const urlTree = router.parseUrl(DelegationsRoute.DELEGATIONS_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};
