@let stringValue = valueAsString();
@if (valueAsArray(); as values) {
  <div
    class="values {{ color() }}"
    [ngClass]="{ wrap: wrap() }"
    [onyxTooltip]="wrap() ? '' : valueElement.textContent!"
    [onyxTooltipDelay]="TOOLTIP_DELAY"
    [onyxTooltipEnabled]="
      OverflowHelper.enableOverflowTooltip.bind(this, valueElement)
    "
    #valueElement
  >
    @for (value of values; track value) {
      <span>
        <ng-container
          *ngTemplateOutlet="
            translateTemplate;
            context: { value: value, comma: !$last }
          "
        ></ng-container>
      </span>
    }
  </div>
} @else if (stringValue) {
  <p
    class="{{ color() }}"
    [ngClass]="{ wrap: wrap() }"
    [onyxTooltip]="valueElement.textContent!"
    [onyxTooltipDelay]="TOOLTIP_DELAY"
    [onyxTooltipEnabled]="
      OverflowHelper.enableOverflowTooltip.bind(this, valueElement)
    "
    #valueElement
  >
    <ng-container
      *ngTemplateOutlet="translateTemplate; context: { value: stringValue }"
    ></ng-container>
  </p>
} @else {
  -
}

<ng-template let-value="value" let-comma="comma" #translateTemplate>
  {{ (dictionaryCode() ? dictionaryCode() + '.' + value : value) | translate
  }}{{ comma ? ',' : '' }}
</ng-template>
