import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { FleetCategory } from '../../common/enums/fleet-category';
import { Fleet, SimplifiedFleet } from '../../common/interfaces/fleet';

@Component({
  selector: 'app-fleet-modal-vehicle-heading',
  imports: [TranslatePipe],
  templateUrl: './fleet-modal-vehicle-heading.component.html',
  styleUrl: './fleet-modal-vehicle-heading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalVehicleHeadingComponent {
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly FleetCategory = FleetCategory;

  public vehicle = input.required<Fleet | SimplifiedFleet>();
  public isVehicle = input.required<boolean>();
}
