import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxInformationHeadingComponent } from '@onyx/angular';
import { DriverModalDocumentsButtonsComponent } from '../modal-documents-buttons/modal-documents-buttons.component';

@Component({
  selector: 'app-modal-documents-heading',
  imports: [
    DriverModalDocumentsButtonsComponent,
    OnyxInformationHeadingComponent,
    TranslatePipe,
  ],
  templateUrl: './modal-documents-heading.component.html',
  styleUrl: './modal-documents-heading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDocumentsHeadingComponent {
  public heading = input.required<string>();
  public subheading = input<string>();
  public scans = input.required<string[]>();
}
