import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { Observable, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../common/services/api.service';
import { DelegationActionModalForm } from '../../delegation-action-modal/delegation-action-modal.component';
import { DelegationDto } from '../../delegation-form/delegation-form.component';
import { DelegationStatus } from '../enums/delegation-status';
import { Delegation } from '../interfaces/delegation';

@Injectable({
  providedIn: 'root',
})
export class DelegationsService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listDelegations(
    params: {
      category?: DelegationStatus;
      status?: DelegationStatus[];
      hasDoubleCrew?: boolean;
      showAssignedOnly?: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Delegation>> {
    return this.get('/delegations', {
      params: {
        ...(params.category && { category: params.category }),
        ...(params.status &&
          params.status.length > 0 && { 'status[]': params.status }),
        ...(params.hasDoubleCrew != null && {
          hasDoubleCrew: params.hasDoubleCrew,
        }),
        ...(params.showAssignedOnly != null && {
          showAssignedOnly: params.showAssignedOnly,
        }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public getDelegation(uuid: string): Observable<Delegation> {
    return this.get(`/delegations/${uuid}`);
  }

  public addDelegation(delegation: DelegationDto): Observable<void> {
    return this.post<void>('/delegations', delegation).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editDelegation(
    uuid: string,
    delegation: DelegationDto,
  ): Observable<void> {
    return this.put<void>(`/delegations/${uuid}`, delegation).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public activateDelegation(uuid: string, form: DelegationActionModalForm) {
    return this.post<void>(`/delegations/${uuid}/activate`, form).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public finishDelegation(uuid: string, form: DelegationActionModalForm) {
    return this.post<void>(`/delegations/${uuid}/finish`, form).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
