<onyx-modal
  type="center"
  size="m"
  heading="{{ vehicle | fleetIdentifier }} - {{
    I18N + '.heading' | translate
  }}"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <onyx-icon
    headingIcon
    class="vehicle-icon"
    [name]="$any(vehicle.generalInformation.category)"
    [size]="28"
  ></onyx-icon>

  <form [formGroup]="form">
    <onyx-dropdown
      formControlName="primaryDriverUuid"
      [label]="I18N + '.firstDriver' | translate"
      [options]="driversOptions().primary"
    ></onyx-dropdown>

    <onyx-dropdown
      formControlName="secondaryDriverUuid"
      [label]="I18N + '.secondDriver' | translate"
      [options]="driversOptions().secondary"
      [optional]="true"
    ></onyx-dropdown>
  </form>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [disabled]="form.invalid"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
