@if (countLabel(); as label) {
  <onyx-input-label
    [optional]="isOptional()"
    [label]="label"
    [hint]="isFile() ? (hint() ?? hintTemplate) : ''"
    [disabled]="disabled()"
    (click)="focus()"
  ></onyx-input-label>
}

<div class="container" [ngStyle]="{ 'gap.px': limit() === 1 ? '0' : gap() }">
  @if (files() && isFile() && !loading()) {
    @for (file of files(); track file) {
      <ng-container
        *ngTemplateOutlet="
          filePreviewTemplate;
          context: { index: $index, file: file }
        "
      ></ng-container>
    }
  }

  @let isLoading = isFile() && loading();
  @if (!files() && isLoading) {
    <div
      class="preview"
      [ngClass]="{ 'hide-border': hideBorder(), loading: loading() }"
    >
      <button class="preview-wrapper">
        <div class="preview-icon">
          <div class="file-icon">
            <onyx-icon name="picture" [size]="16"></onyx-icon>
          </div>
        </div>

        <div class="preview-details">
          <span class="preview-name f-regular-3">
            {{ I18N + '.loading' | translate }}
          </span>
        </div>
      </button>
    </div>
  }

  <div
    class="input-wrapper"
    [ngClass]="{ 'reached-limit': isReachedLimit() && isFile() }"
  >
    <div
      class="input-file"
      [ngClass]="{
        image: !isFile(),
        dragover: isDragOver(),
        invalid: isInvalid(),
        inactive: inactive(),
        hidden:
          ((disabled() || inactive()) && files() && isFile()) || isLoading,
        disabled: disabled() && !files(),
      }"
      [tabindex]="inactive() ? -1 : 0"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      (keydown.space)="onSpaceKeyPress()"
      (focusout)="onTouched?.()"
    >
      @if (isFile()) {
        <div class="text">
          <onyx-icon name="plus" [size]="16"></onyx-icon>
          <span>
            {{ I18N + '.uploadOrDrop' | translate }}
          </span>
        </div>
      } @else {
        @if (!files() && !isFile()) {
          <div class="picture-icon" [ngClass]="{ invalid: isInvalid() }">
            <onyx-icon name="picture" [size]="24"></onyx-icon>
          </div>
        }
      }

      @if (files() && !isFile()) {
        <div
          class="image-preview"
          [ngStyle]="{ 'background-image': 'url(' + fileBlob() + ')' }"
        ></div>
      }

      <input
        class="input"
        type="file"
        [disabled]="inactive()"
        tabindex="-1"
        [accept]="(isFile() ? FILE_TYPES : IMAGE_TYPES).join(', ')"
        (change)="onFileInputChange($event)"
        #inputElement
      />
    </div>

    @if (!isFile()) {
      <div class="image-container">
        @if (files()) {
          <div class="image-links">
            <onyx-button
              color="blue"
              type="outlined"
              size="s"
              [disabled]="disabled()"
              (click)="onChangeFile(true)"
            >
              {{ I18N + '.changeImage' | translate }}
            </onyx-button>

            <onyx-button
              color="red"
              type="outlined"
              size="s"
              [disabled]="disabled()"
              (click)="deleteFile()"
              (keydown.space)="deleteFile(); $event.preventDefault()"
            >
              {{ I18N + '.delete' | translate }}
            </onyx-button>
          </div>
        } @else {
          <span class="choose">
            <onyx-button
              color="blue"
              type="outlined"
              size="s"
              [disabled]="disabled()"
              (click)="onChangeFile(!disabled()); $event.preventDefault()"
              (keydown.space)="
                onChangeFile(!disabled()); $event.preventDefault()
              "
            >
              {{ I18N + '.chooseFile' | translate }}
            </onyx-button>
          </span>
        }
        @if (customErrors().length !== 0) {
          <div class="image-errors f-regular-3">
            @for (error of customErrors(); track error) {
              <p>{{ error }}</p>
            }
          </div>
        }
        <div class="image-details f-regular-3">
          <p>JPG, PNG, SVG</p>
          <p>
            {{ I18N + '.maxSize' | translate }}:
            {{ MAX_IMAGE_SIZE | onyxFileSize }}
          </p>
        </div>
      </div>
    }
  </div>
</div>

<ng-template #hintTemplate>
  <p class="hint">
    PDF, DOCX, XLSX, JPG, JPEG, PNG, SVG, WEBP <br />
    {{ I18N + '.maxSize' | translate }}: {{ MAX_FILE_SIZE | onyxFileSize }}
  </p>
</ng-template>

<ng-template let-index="index" let-file="file" #filePreviewTemplate>
  <div class="preview" [ngClass]="{ 'hide-border': hideBorder() }">
    <button
      class="preview-wrapper"
      (click)="previewFile(file); $event.preventDefault()"
    >
      <div
        class="preview-icon"
        [ngClass]="{
          pdf: file.type === 'application/pdf',
        }"
      >
        <div class="show-icon">
          <onyx-icon name="new-tab" [size]="16"></onyx-icon>
        </div>

        <div class="file-icon">
          @if (file.type === 'application/pdf') {
            <onyx-icon name="pdf" [size]="16"></onyx-icon>
          } @else if (IMAGE_TYPES.includes(file.type)) {
            <onyx-icon name="picture" [size]="16"></onyx-icon>
          } @else {
            <onyx-icon name="document" [size]="16"></onyx-icon>
          }
        </div>
      </div>

      <div class="preview-details">
        <span class="preview-name f-regular-3">
          {{ getDisplayFileName(file) }}
        </span>

        <span class="preview-weight f-regular-4">
          {{ file.size | onyxFileSize }}
        </span>
      </div>
    </button>

    @if (!disabled()) {
      <onyx-icon-button
        type="transparent"
        color="black"
        size="m"
        (click)="deleteFile(index)"
        (keydown.space)="deleteFile(index); $event.preventDefault()"
      >
        <onyx-icon name="delete" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </div>
</ng-template>
