@if (vehicle(); as vehicle) {
  <button class="item" (click)="openVehicleModal()">
    @let generalInformation = vehicle.generalInformation;
    <div class="heading">
      {{
        (isVehicle()
          ? DictionaryCode.VEHICLE_CATEGORY
          : DictionaryCode.TRAILER_CATEGORY) +
          '.' +
          generalInformation.category | translate
      }}
    </div>

    <div class="details">
      <h2>{{ generalInformation | fleetIdentifier }}</h2>

      <p class="params f-medium-1">
        @if (vehicle.fuelTankCapacity?.fuelType; as fuelType) {
          <p>{{ DictionaryCode.FUEL_TYPE + '.' + fuelType | translate }}</p>
        }

        @let lowDeck = vehicle?.additionalParameters?.isLowDeck;
        @if (lowDeck != null) {
          <p>
            {{
              (lowDeck ? 'labels.lowDeck' : 'labels.standardDeck') | translate
            }}
          </p>
        }

        @if (generalInformation.type; as type) {
          <p>
            {{
              DictionaryCode.VEHICLE_TYPE + '.' + generalInformation.type
                | translate
            }}
          </p>
        }

        @let trailerSize = vehicle?.additionalParameters?.semiTrailerSize;
        @if (trailerSize) {
          <p>
            {{
              DictionaryCode.SEMI_TRAILER_SIZE + '.' + trailerSize | translate
            }}
          </p>
        }
      </p>
    </div>
  </button>
}
