import { Injectable, computed } from '@angular/core';
import { OnyxPreferencesService } from '@onyx/angular';
import { AuthService } from '../../auth/common/services/auth.service';
import { OrderRateType } from '../../dashboard/orders/common/enums/order-rate-type';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService implements OnyxPreferencesService {
  public defaultAreaCode = computed(() => '+48');
  public defaultCurrency = computed(
    () => this.authService.business()?.defaultCurrency ?? 'eur',
  );
  public defaultCountry = computed(
    () => this.authService.business()?.mainAddress?.countryCode ?? 'pl',
  );
  public defaultFuelType = computed(() => 'diesel');
  public defaultRateType = computed(() => OrderRateType.FREIGHT);
  public defaultVatRate = computed(() => '23');

  constructor(private authService: AuthService) {}
}
