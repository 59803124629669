import { Contractor } from '../../../contractors/common/interfaces/contractor';
import { PointsOfInterestForm } from '../../points-of-interest-form/points-of-interest-form.component';
import { PointOfInterest } from '../interfaces/point-of-interest';

export class PointOfInterestHelper {
  public static fromDto(dto: PointOfInterest): PointsOfInterestForm {
    return {
      ...dto,
      pointInformation: {
        address: dto.address,
        name: dto.name,
      },
      times: {
        averageServiceTime: dto.time.averageServiceTime,
      },
      contact: { phone: dto.phone },
      contractor: {
        selectedContractors: dto.contractors.map(
          (contractor) => contractor.contractor as Contractor, // TEMP
        ),
        contractors: dto.contractors.map((contractor) => ({
          contractor: contractor.contractor as Contractor, // TEMP
          gate: contractor.gate,
          uuid: contractor.contractor.uuid,
        })),
      },
    };
  }
}
