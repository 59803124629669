import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxFormMode,
  OnyxIconComponent,
  OnyxModalService,
  OnyxPaginated,
  OnyxTableComponent,
  OnyxTableNotFound,
  OnyxToggleComponent,
} from '@onyx/angular';
import { Subject, switchMap, tap } from 'rxjs';
import { Driver } from '../../../dashboard/drivers/common/interfaces/driver';
import { DriversService } from '../../../dashboard/drivers/common/services/drivers.service';
import { Employee } from '../../../dashboard/management-panel/employees/common/interfaces/employee';
import { EmployeesService } from '../../../dashboard/management-panel/employees/common/services/employees.service';
import { SHORT_PAGINATION } from '../../constants/short-pagination';
import { getUnavailabilitiesColumns } from '../../constants/unavailabilities-columns';
import { Unavailability } from '../../interfaces/common/unavailability';
import { StorageService } from '../../services/storage.service';
import {
  UnavailabilityModalComponent,
  UnavailabilityModalData,
} from './unavailability-modal/unavailability-modal.component';

export enum UnavailabilityType {
  EMPLOYEE = 'employee',
  DRIVER = 'driver',
}

@Component({
  selector: 'app-unavailabilities',
  imports: [
    OnyxButtonComponent,
    OnyxIconComponent,
    OnyxTableComponent,
    OnyxToggleComponent,
    TranslatePipe,
  ],
  templateUrl: './unavailabilities.component.html',
  styleUrl: './unavailabilities.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailabilitiesComponent implements OnInit {
  protected readonly I18N = 'unavailabilities';
  protected readonly COLUMNS = getUnavailabilitiesColumns(
    this.showFile.bind(this),
  );
  protected readonly UNAVAILABILITIES_NOT_FOUND: OnyxTableNotFound = {
    icon: { name: 'vacation', size: 24 },
    text: `${this.I18N}.unavailabilitiesNotFound`,
  };

  protected readonly UnavailabilityType = UnavailabilityType;

  public employee = input<Employee | null>(null);
  public driver = input<Driver | null>(null);

  protected unavailabilities = signal<OnyxPaginated<Unavailability> | null>(
    null,
  );
  protected loading = signal(false);
  protected showPastUnavailabilities = signal(false);
  protected pagination = signal(SHORT_PAGINATION);

  protected type = computed(() =>
    this.employee() != null
      ? UnavailabilityType.EMPLOYEE
      : UnavailabilityType.DRIVER,
  );
  protected params = computed(() => ({
    past: this.showPastUnavailabilities(),
    ...this.pagination(),
  }));

  private reload$ = new Subject<void>();

  constructor(
    protected dialogRef: DialogRef<void>,
    private employeesService: EmployeesService,
    private driversService: DriversService,
    private destroyRef: DestroyRef,
    private storageService: StorageService,
    private modalService: OnyxModalService,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.reload$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.type() === UnavailabilityType.EMPLOYEE
            ? this.employeesService.getEmployeeUnavailabilities(
                this.employee()!.uuid,
                this.params(),
              )
            : this.driversService.getDriverUnavailabilities(
                this.driver()!.uuid,
                this.params(),
              ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((unavailabilities) => {
        this.unavailabilities.set(unavailabilities);
        this.loading.set(false);
      });

    effect(
      () => {
        this.params();
        untracked(() => this.reload$.next());
      },
      { injector: this.injector },
    );

    (this.type() === UnavailabilityType.EMPLOYEE
      ? this.employeesService.reload$
      : this.driversService.reload$
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload$.next());
  }

  protected addUnavailability(): void {
    if (this.type() === UnavailabilityType.EMPLOYEE) {
      this.modalService.open<UnavailabilityModalData>(
        UnavailabilityModalComponent,
        {
          mode: OnyxFormMode.ADD,
          type: UnavailabilityType.EMPLOYEE,
          item: this.employee()!,
        },
      );
    } else {
      this.modalService.open<UnavailabilityModalData>(
        UnavailabilityModalComponent,
        {
          mode: OnyxFormMode.ADD,
          type: UnavailabilityType.DRIVER,
          item: this.driver()!,
        },
      );
    }
  }

  protected editUnavailability(unavailability: Unavailability): void {
    if (this.type() === UnavailabilityType.EMPLOYEE) {
      this.modalService.open<UnavailabilityModalData>(
        UnavailabilityModalComponent,
        {
          mode: OnyxFormMode.EDIT,
          type: UnavailabilityType.EMPLOYEE,
          item: this.employee()!,
          unavailability,
        },
      );
    } else {
      this.modalService.open<UnavailabilityModalData>(
        UnavailabilityModalComponent,
        {
          mode: OnyxFormMode.EDIT,
          type: UnavailabilityType.DRIVER,
          item: this.driver()!,
          unavailability,
        },
      );
    }
  }

  protected showFile(file: File | string): void {
    this.storageService.previewFile(file as string).subscribe();
  }
}
