import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';

import { catchError, of } from 'rxjs';
import { FleetRoute } from '../../fleet.routes';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';
import { Fleet } from '../interfaces/fleet';
import { FleetService } from '../services/fleet.service';

export const fleetResolver: ResolveFn<Fleet> = (
  route: ActivatedRouteSnapshot,
) => {
  const fleetService = inject(FleetService);
  const router = inject(Router);
  const toastService = inject(OnyxToastService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const uuid = route.paramMap.get('uuid')!;
  const category = state?.[FleetRouterStateKey.CATEGORY];

  return fleetService.getFleet(category, uuid).pipe(
    catchError((error) => {
      ValidationHelper.handleUnexpectedError(error, toastService);

      const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
