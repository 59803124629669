import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxOption,
  OnyxToastService,
} from '@onyx/angular';
import {
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { FleetCategory } from '../../../../fleet/common/enums/fleet-category';
import { FleetState } from '../../../../fleet/common/enums/fleet-state';
import { Fleet } from '../../../../fleet/common/interfaces/fleet';
import { FleetService } from '../../../../fleet/common/services/fleet.service';
import { Driver } from '../../interfaces/driver';
import { DriversService } from '../../services/drivers.service';

@Component({
  selector: 'app-driver-assign-vehicle-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxMessageComponent,
    OnyxIconComponent,
    FleetIdentifierPipe,
  ],
  templateUrl: './driver-assign-vehicle-modal.component.html',
  styleUrl: './driver-assign-vehicle-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverAssignVehicleModalComponent implements OnInit {
  protected readonly I18N = 'drivers.assignVehicleModal';

  protected form = this.buildForm();
  protected loading = signal(false);
  protected vehicleOptions = signal<OnyxOption<Fleet>[] | null>(null);
  protected trailerOption = signal<OnyxOption<string>[] | null>(null);
  protected close$ = new Subject<void>();
  protected existingPrimaryDriverData = signal<Driver | null>(null);

  constructor(
    @Inject(DIALOG_DATA) protected driver: Driver,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private driversService: DriversService,
    private destroyRef: DestroyRef,
    private fleetIdentifierPipe: FleetIdentifierPipe,
  ) {}

  public ngOnInit(): void {
    this.fleetService
      .listFleet({
        state: FleetState.ACTIVE,
        category: [],
        showSetsOnly: false,
        showAssignedOnly: false,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
      .pipe(
        take(1),
        map((vehicles) =>
          vehicles.items.map((vehicle: Fleet) => ({
            name: this.fleetIdentifierPipe.transform(vehicle),
            value: vehicle,
          })),
        ),
      )
      .subscribe((options) => this.vehicleOptions.set(options));

    this.form.controls.vehicle.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((value) => !!value),
        switchMap((value) => this.changeVehicle(value)),
      )
      .subscribe();
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const body = this.existingPrimaryDriverData()
      ? {
          primaryDriverUuid: this.existingPrimaryDriverData()?.uuid,
          secondaryDriverUuid: this.driver.uuid,
        }
      : { primaryDriverUuid: this.driver.uuid, secondaryDriverUuid: null };

    this.loading.set(true);
    this.fleetService
      .assignDrivers(body, form.vehicle!.uuid)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.success`);
          this.close$.next();
          this.driversService.reload();
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private changeVehicle(vehicle: Fleet): Observable<void> {
    return of(vehicle).pipe(
      switchMap(() => {
        if (!vehicle.trailer) {
          this.trailerOption.set(null);
          this.form.controls.trailer.setValue(null);
          return of(null);
        }

        return this.fleetService
          .getFleet(FleetCategory.TRAILER, vehicle.trailer.uuid)
          .pipe(
            tap((trailer) => {
              this.trailerOption.set([
                {
                  name: this.fleetIdentifierPipe.transform(trailer),
                  value: trailer.uuid,
                },
              ]);
              this.form.controls.trailer.setValue(trailer.uuid);
            }),
          );
      }),
      switchMap(() => {
        const drivers = vehicle.drivers;
        if (drivers?.primaryDriver && !drivers?.secondaryDriver) {
          return this.driversService.getDriver(drivers.primaryDriver.uuid);
        }

        return of(null);
      }),
      tap((driver) => this.existingPrimaryDriverData.set(driver)),
      map(() => undefined),
    );
  }

  private buildForm() {
    return this.fb.group({
      vehicle: this.fb.control<Fleet | null>(null, [Validators.required]),
      trailer: this.fb.control<string | null>({ value: null, disabled: true }),
    });
  }
}
