import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { I18N_NAMESPACE } from '../../../../../internal/constants';
import { CalendarRole } from '../../../enums';
import { OnyxDate } from '../../../interfaces';

@Component({
  selector: 'onyx-datepicker-calendar',
  imports: [TranslatePipe, NgClass],
  templateUrl: './onyx-datepicker-calendar.component.html',
  styleUrl: './onyx-datepicker-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDatepickerCalendarComponent {
  protected readonly I18N = `${I18N_NAMESPACE}.datepicker`;
  protected readonly DISPLAY_DAYS = 6 * 7;

  protected readonly CalendarRole = CalendarRole;
  protected readonly DateTime = DateTime;

  public today = input.required<string>();
  public calendar = input.required<string[]>();
  public dateRange = input.required<OnyxDate>();
  public disableDate =
    input.required<(dateRange: OnyxDate, date: string) => boolean>();

  public changeDate = output<string>();

  protected getCalendarDays(): string[] {
    const calendar = this.calendar();
    const startOfMonth = DateTime.fromISO(calendar[0]).startOf('month');
    const endOfMonth = DateTime.fromISO(calendar[calendar.length - 1]).endOf(
      'month',
    );
    const firstDayOfWeek = startOfMonth.weekday;
    const lastDayOfWeek = endOfMonth.weekday;
    const prevMonthDays: string[] = [];
    const nextMonthDays: string[] = [];

    if (firstDayOfWeek > 1) {
      const missingPrevDays = firstDayOfWeek - 1;
      const prevMonthEnd = startOfMonth.minus({ days: missingPrevDays });

      for (let i = 0; i < missingPrevDays; ++i) {
        const day = prevMonthEnd.plus({ days: i }).toISODate();
        prevMonthDays.push(day!);
      }
    }

    if (lastDayOfWeek < 7) {
      const missingNextDays = 7 - lastDayOfWeek;
      const nextMonthStart = endOfMonth.plus({ days: 1 });

      for (let i = 0; i < missingNextDays; ++i) {
        const day = nextMonthStart.plus({ days: i }).toISODate();
        nextMonthDays.push(day!);
      }
    }

    const allDays = [...prevMonthDays, ...calendar, ...nextMonthDays];
    const missingDays = this.DISPLAY_DAYS - allDays.length;

    if (missingDays > 0) {
      const lastDay = DateTime.fromISO(allDays.at(-1)!);

      for (let i = 1; i <= missingDays; ++i) {
        const day = lastDay.plus({ days: i }).toISODate();
        allDays.push(day!);
      }
    }

    return allDays;
  }
}
