import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { BaseForm } from '../../base-form/base-form.component';
import { BasesRoute } from '../../bases.routes';
import { BaseHelper } from '../helpers/base.helper';
import { BasesService } from '../services/bases.service';

export const editBaseResolver: ResolveFn<BaseForm> = (
  route: ActivatedRouteSnapshot,
) => {
  const baseService = inject(BasesService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return baseService.getBase(uuid).pipe(
    map((dto) => BaseHelper.fromDto(dto)),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('bases.toasts.baseNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(error, toastService, {
          keepOnNavigation: true,
        });
      }

      const urlTree = router.parseUrl(BasesRoute.BASES_LIST);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
