import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxIconBoxColor } from '../enums';

@Component({
  selector: 'onyx-icon-box',
  imports: [NgClass, NgStyle],
  templateUrl: './onyx-icon-box.component.html',
  styleUrl: './onyx-icon-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxIconBoxComponent {
  public color = input<`${OnyxIconBoxColor}`>(OnyxIconBoxColor.BLACK);
  public label = input('');
  public hasColoredBackground = input(false);
  public hasShadow = input(false);
  public gap = input(12);
}
