import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNumber } from 'lodash';
import { OnyxNumberRange } from '../interfaces';
import { I18N_NAMESPACE } from '../internal/constants';

@Pipe({
  name: 'onyxTemperature',
  standalone: true,
  pure: false,
})
export class OnyxTemperaturePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private decimalPipe: DecimalPipe,
  ) {}

  public transform(value: number | OnyxNumberRange): string {
    if (isNumber(value)) return this.transformSingle(value);
    return this.transformRange(value);
  }

  private transformSingle(value: number, showUnit = true): string {
    const formatted = this.decimalPipe.transform(value, '1.0-0');
    return `${formatted}${showUnit ? '°C' : ''}`;
  }

  private transformRange(range: OnyxNumberRange): string {
    if (range.from === range.to) return this.transformSingle(range.from);

    const I18N = `${I18N_NAMESPACE}.temperature.separator`;
    const separator = this.translateService.instant(I18N);

    return `${this.transformSingle(range.from, false)} ${separator} ${this.transformSingle(range.to)}`;
  }
}
