import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Subject } from 'rxjs';
import { DelegationStatus } from '../../common/enums/delegation-status';
import { Delegation } from '../../common/interfaces/delegation';
import { DelegationModalNoteComponent } from './delegation-modal-note/delegation-modal-note.component';
import { DelegationModalPeriodComponent } from './delegation-modal-period/delegation-modal-period.component';
import { DelegationModalRealizationStatusComponent } from './delegation-modal-realization-status/delegation-modal-realization-status.component';
import { DelegationModalStatusComponent } from './delegation-modal-status/delegation-modal-status.component';
import { DelegationModalVehiclesComponent } from './delegation-modal-vehicles/delegation-modal-vehicles.component';

@Component({
  selector: 'app-delegation-modal-main-section',
  standalone: true,
  imports: [
    DelegationModalStatusComponent,
    DelegationModalRealizationStatusComponent,
    DelegationModalPeriodComponent,
    DelegationModalVehiclesComponent,
    NgClass,
    DelegationModalNoteComponent,
  ],
  templateUrl: './delegation-modal-main-section.component.html',
  styleUrl: './delegation-modal-main-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalMainSectionComponent {
  protected readonly DelegationStatus = DelegationStatus;

  public delegation = input.required<Delegation>();
  public close$ = input.required<Subject<void>>();
}
