import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { Contractor } from '../../interfaces/contractor';
import { ContractorsService } from '../../services/contractors.service';

export type BlockContractorModalForm = ReturnType<
  ReturnType<BlockContractorModalComponent['buildForm']>['getRawValue']
>;

@Component({
  selector: 'app-block-contractor-modal',
  imports: [
    OnyxModalComponent,
    OnyxMessageComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxButtonComponent,
    OnyxTextFieldComponent,
    OnyxTabsComponent,
    AsyncPipe,
  ],
  templateUrl: './block-contractor-modal.component.html',
  styleUrl: './block-contractor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockContractorModalComponent {
  protected readonly I18N = 'contractors.blockContractorModal';

  protected readonly contractorBlockReason$ =
    this.dictionariesService.getDictionary(
      DictionaryCode.CONTRACTOR_BLOCK_REASON,
    );

  protected form = this.buildForm();
  protected loading = signal(false);
  protected close$ = new Subject<boolean>();

  constructor(
    @Inject(DIALOG_DATA) protected contractor: Contractor,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private contractorsService: ContractorsService,
    private toastService: OnyxToastService,
  ) {}

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    this.loading.set(true);
    this.contractorsService
      .blockContractor(this.contractor.uuid, form)
      .subscribe({
        next: () => {
          this.toastService.showSuccess('contractors.toasts.contractorBlocked');
          this.close$.next(true);
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      reason: this.fb.control('late-payments', [Validators.required]),
      reasonDescription: this.fb.control('', [Validators.required]),
      end: this.fb.group({
        date: this.fb.control(null),
        indefinite: this.fb.control(true),
      }),
    });
  }
}
