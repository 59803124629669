<onyx-modal
  type="center"
  [heading]="data.heading ?? I18N + '.heading' | translate"
  size="l"
  [close$]="close$"
  (closeModal)="close($event)"
>
  <div class="container">
    <form [formGroup]="form">
      <div class="street-container">
        <onyx-text-field
          class="street"
          formControlName="street"
          [label]="I18N + '.street' | translate"
        ></onyx-text-field>

        <onyx-text-field
          class="house"
          formControlName="houseNumber"
          [label]="I18N + '.houseNumber' | translate"
        ></onyx-text-field>

        <onyx-text-field
          class="house"
          formControlName="apartmentNumber"
          [label]="I18N + '.apartmentNumber' | translate"
        ></onyx-text-field>
      </div>

      <div class="city">
        <onyx-text-field
          formControlName="zipCode"
          [label]="I18N + '.zipCode' | translate"
        ></onyx-text-field>

        <onyx-text-field
          formControlName="city"
          [label]="I18N + '.city' | translate"
        ></onyx-text-field>
      </div>

      <onyx-dropdown
        formControlName="countryCode"
        [label]="I18N + '.countryCode' | translate"
        [options]="countries$ | async"
        [search]="true"
      ></onyx-dropdown>

      <onyx-text-field
        formControlName="coordinates"
        [label]="I18N + '.coordinates' | translate"
      >
        @if (coordinatesString()) {
          <onyx-icon-button
            action
            type="transparent"
            color="black"
            size="xs"
            [tabindex]="-1"
            (click)="copyCoordinates()"
          >
            <onyx-icon name="copy" [size]="16"></onyx-icon>
          </onyx-icon-button>
        }
      </onyx-text-field>
    </form>

    <onyx-map></onyx-map>
  </div>

  <onyx-button
    bottomLeftOptions
    type="outlined"
    color="black"
    (click)="clear()"
  >
    {{ I18N + '.clear' | translate }}
  </onyx-button>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="cancel()">
      {{ 'onyxAngular.common.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [disabled]="form.invalid || form.disabled"
      (click)="save()"
    >
      {{ I18N + '.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
