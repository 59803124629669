import { DriverForm } from '../../driver-form/driver-form.component';
import { Driver } from '../interfaces/driver';

export class DriverHelper {
  public static fromDto(dto: Driver): DriverForm {
    return {
      ...dto,
      driverData: {
        ...dto.driverData,
        assignedBase: dto.driverData.assignedBase?.uuid ?? null,
      },
      employmentConditions: {
        ...dto.employmentConditions,
        mileageRate: dto.employmentConditions
          .netMileagePatePerKm as DriverForm['employmentConditions']['mileageRate'],
        dailyRate: dto.employmentConditions
          .dailyRate as DriverForm['employmentConditions']['dailyRate'],
        baseRate: dto.employmentConditions
          .baseRate as DriverForm['employmentConditions']['baseRate'],
        roundTripRate: dto.employmentConditions
          .roundTripRate as DriverForm['employmentConditions']['roundTripRate'],
      },
      driversLicenseAndProfessionalQualifications: {
        ...dto.driversLicenseAndProfessionalQualifications,
        selectedCategories:
          dto.driversLicenseAndProfessionalQualifications.categories.map(
            (category) => category.category,
          ),
      },
      driverCard: dto.driverCard as DriverForm['driverCard'],
      identityDocuments: {
        hasDocuments: true,
        passport: dto.identityDocuments
          .passport as DriverForm['identityDocuments']['passport'],
        identityDocument: dto.identityDocuments
          .identityDocument as DriverForm['identityDocuments']['identityDocument'],
        residenceCard: dto.identityDocuments
          .residenceCard as DriverForm['identityDocuments']['residenceCard'],
        polishCard: dto.identityDocuments
          .polishCard as DriverForm['identityDocuments']['polishCard'],
      },
    };
  }
}
