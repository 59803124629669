<onyx-modal
  type="right"
  size="m"
  heading="N/A"
  [extend]="true"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <h3 headingLeftOptions class="delegation">
    {{ 'labels.delegation' | translate }}
  </h3>

  <ng-container rightOptions>
    <onyx-icon-button
      color="black"
      type="transparent"
      size="m"
      [onyxDropdownOptions]="dropdownOptions()"
      (onyxDropdownValueChange)="$event!()"
      (click)="$event.stopPropagation()"
    >
      <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
    </onyx-icon-button>

    @if (headerActionButton(); as headerActionButton) {
      <onyx-button size="s" (click)="headerActionButton.value()">
        {{ headerActionButton.name | translate }}
      </onyx-button>
    }
  </ng-container>

  <app-delegation-modal-heading
    bottomOptions
    [primaryDriver]="delegation.primaryDriver"
    [secondaryDriver]="delegation.secondaryDriver"
  >
  </app-delegation-modal-heading>

  <div class="container">
    <app-delegation-modal-main-section
      [delegation]="delegation"
      [close$]="close$"
    >
    </app-delegation-modal-main-section>

    <div class="chips-container">
      <onyx-chips
        class="chips"
        [chips]="chips()"
        type="secondary"
        [single]="true"
        [wrap]="true"
        [(activeValues)]="group"
      ></onyx-chips>

      @switch (group()[0]) {
        @case (DelegationModalOption.DOCUMENTS) {
          N/A
        }

        @case (DelegationModalOption.EXPENSES) {
          N/A
        }

        @case (DelegationModalOption.FINANCES) {
          N/A
        }

        @case (DelegationModalOption.ORDERS) {
          N/A
        }

        @case (DelegationModalOption.TACHOGRAPH) {
          N/A
        }
      }
    </div>
  </div>
</onyx-modal>
