import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActionHelper } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class OnyxStorageService {
  constructor(protected http: HttpClient) {}

  public getFile(uuid: string): Observable<File> {
    return this.http
      .get(uuid, { observe: 'response', responseType: 'blob' })
      .pipe(
        map((response) => {
          if (!response.body) throw new Error('No file found');

          const blob = response.body;
          const fileName = response.headers
            .get('content-disposition')
            ?.split('filename=')[1]
            .split(';')[0];

          return new File([blob], fileName || '', { type: blob.type });
        }),
      );
  }

  public showPreview(target: Blob | MediaSource): void {
    ActionHelper.openLink(URL.createObjectURL(target));
  }
}
