import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxDatePipe,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../common/components/cells/amount-cell/amount-cell.component';
import { CountryCellComponent } from '../../../../common/components/cells/country-cell/country-cell.component';
import { DateRangeCellComponent } from '../../../../common/components/cells/date-range-cell/date-range-cell.component';
import { StringCellComponent } from '../../../../common/components/cells/string-cell/string-cell.component';
import { UnitCellComponent } from '../../../../common/components/cells/unit-cell/unit-cell.component';
import { ModalDateRowComponent } from '../../../../common/components/modal-documents/modal-date-row/modal-date-row.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { DictionariesService } from '../../../../common/services/dictionaries.service';
import { OwnershipType } from '../../common/enums/ownership-type';
import { Fleet } from '../../common/interfaces/fleet';

@Component({
  selector: 'app-fleet-modal-details',
  imports: [
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxDatePipe,
    CountryCellComponent,
    UnitCellComponent,
    StringCellComponent,
    AmountCellComponent,
    OnyxDatePipe,
    DateRangeCellComponent,
    ModalDateRowComponent,
  ],
  templateUrl: './fleet-modal-details.component.html',
  styleUrl: './fleet-modal-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalDetailsComponent {
  protected readonly I18N = 'fleet.fleetModal';

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OwnershipType = OwnershipType;

  protected readonly language =
    this.translateService.currentLang ?? this.translateService.defaultLang;

  public vehicle = input.required<Fleet>();
  public isVehicle = input.required<boolean>();

  protected fuelType = computed(() => {
    const fuelTypes = this.fuelTypes();
    if (!fuelTypes) return null;

    return (
      fuelTypes.find(
        (fuelType) =>
          fuelType.value === this.vehicle().fuelTankCapacity?.fuelType,
      ) ?? null
    );
  });

  private fuelTypes = toSignal(
    this.dictionariesService.getDictionary(DictionaryCode.FUEL_TYPE),
    { initialValue: null },
  );

  constructor(
    private translateService: TranslateService,
    private dictionariesService: DictionariesService,
  ) {}
}
