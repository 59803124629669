export const GLOBAL_API_NAMESPACE = 'auth';

export const I18N_NAMESPACES = [
  'auth',
  'bases',
  'common',
  'company-data',
  'contractors',
  'dashboard',
  'delegations',
  'dictionaries',
  'documents',
  'drivers',
  'employees',
  'engine-config',
  'fleet',
  'integrations',
  'map',
  'nav-submenu',
  'orders',
  'points-of-interest',
  'settings',
] as const;

export const SENTRY_DSN =
  'https://229f3aec4883901402c1b1cd5e597142@o4504039106871296.ingest.sentry.io/4506774896377856';

export const HOTJAR_SITE_ID = 5127840;
export const HOTJAR_VERSION = 6;
