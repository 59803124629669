import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, forkJoin, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { FleetValidation } from '../../../../common/interfaces/validation/fleet-validation';
import { ValidationService } from '../../../../common/services/validation.service';
import { FleetForm } from '../../fleet-form/fleet-form.component';
import { FleetRoute } from '../../fleet.routes';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';
import { FleetHelper } from '../helpers/fleet.helper';
import { FleetService } from '../services/fleet.service';

export const editFleetResolver: ResolveFn<{
  validation: FleetValidation;
  form: FleetForm;
}> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const fleetService = inject(FleetService);
  const validationService = inject(ValidationService);
  const toastService = inject(OnyxToastService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const category = state?.[FleetRouterStateKey.CATEGORY];
  const uuid = route.paramMap.get('uuid');

  if (!category || !uuid) {
    const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
    return new RedirectCommand(urlTree, { replaceUrl: true });
  }

  return forkJoin([
    fleetService.getFleet(category, uuid),
    validationService.getFleetValidation(category),
  ]).pipe(
    map(([dto, validation]) => ({
      validation,
      form: FleetHelper.fromDto(dto),
    })),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('fleet.toasts.notFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(error, toastService, {
          keepOnNavigation: true,
        });
      }

      const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
