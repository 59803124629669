import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxModalService,
  OnyxSpinnerComponent,
  OnyxToastService,
} from '@onyx/angular';
import { combineLatest, forkJoin, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { Driver } from '../../../drivers/common/interfaces/driver';
import { DriversService } from '../../../drivers/common/services/drivers.service';
import { Employee } from '../../../management-panel/employees/common/interfaces/employee';
import { EmployeesService } from '../../../management-panel/employees/common/services/employees.service';
import { FleetAssignDriverModalComponent } from '../../common/components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import { FleetAssignEmployeesModalComponent } from '../../common/components/fleet-assign-employees-modal/fleet-assign-employees-modal.component';
import { Fleet } from '../../common/interfaces/fleet';
import { FleetModalCrewItemComponent } from './fleet-modal-crew-item/fleet-modal-crew-item.component';

@Component({
  selector: 'app-fleet-modal-crew',
  imports: [
    OnyxButtonComponent,
    OnyxSpinnerComponent,
    FleetModalCrewItemComponent,
    TranslatePipe,
  ],
  templateUrl: './fleet-modal-crew.component.html',
  styleUrl: './fleet-modal-crew.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalCrewComponent implements OnInit {
  public vehicle = input.required<Fleet>();
  public isVehicle = input.required<boolean>();

  protected drivers = signal<Driver[] | null>(null);
  protected employees = signal<Employee[] | null>(null);
  protected loading = signal(false);

  constructor(
    private driversService: DriversService,
    private employeesService: EmployeesService,
    private toastService: OnyxToastService,
    private modalService: OnyxModalService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    if (!this.isVehicle()) return;

    const drivers$ = Object.values(this.vehicle().drivers!)
      .filter((driver) => driver != null)
      .map((driver) => this.driversService.getDriver(driver.uuid));

    const employees$ = this.vehicle().assignedEmployees!.map((employee) =>
      this.employeesService.getEmployee(employee.uuid),
    );

    this.loading.set(true);
    combineLatest({
      drivers: drivers$.length ? forkJoin(drivers$) : of([]),
      employees: employees$.length ? forkJoin(employees$) : of([]),
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ drivers, employees }) => {
          this.drivers.set(drivers);
          this.employees.set(employees);
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected assignEmployees(): void {
    this.modalService.open<Fleet[]>(FleetAssignEmployeesModalComponent, [
      this.vehicle(),
    ]);
  }

  protected assignDrivers(): void {
    this.modalService.open<Fleet>(
      FleetAssignDriverModalComponent,
      this.vehicle(),
    );
  }
}
