import { CdkListboxModule } from '@angular/cdk/listbox';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  Injector,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { get, has, isArray, isEqual, orderBy, uniqBy } from 'lodash';
import { distinctUntilChanged, map, Observable, of, skip } from 'rxjs';
import {
  OnyxCheckboxComponent,
  OnyxIconComponent,
  OnyxSearchComponent,
  OnyxSpinnerComponent,
} from '../../../components';
import { OnyxTooltipDirective } from '../../../directives';
import {
  OnyxDropdownConfig,
  OnyxDropdownOption,
  OnyxDropdownOptionsGroup,
} from '../../../directives/interfaces';
import { OnyxOverlayPosition } from '../../../enums';
import { OnyxHighlightPipe } from '../../../pipes';
import { I18N_NAMESPACE } from '../../constants';

export const ONYX_DROPDOWN_CONFIG = new InjectionToken<OnyxDropdownConfig<any>>(
  'Dropdown configuration',
);

@Component({
  selector: 'onyx-dropdown-overlay',
  imports: [
    CdkListboxModule,
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    OnyxIconComponent,
    OnyxSpinnerComponent,
    OnyxHighlightPipe,
    ReactiveFormsModule,
    TranslatePipe,
    forwardRef(() => OnyxCheckboxComponent),
    OnyxSearchComponent,
    OnyxTooltipDirective,
  ],
  templateUrl: './onyx-dropdown-overlay.component.html',
  styleUrl: './onyx-dropdown-overlay.component.scss',
})
export class OnyxDropdownOverlayComponent<T> implements AfterViewInit {
  protected readonly I18N = `${I18N_NAMESPACE}.dropdown`;

  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  protected groups = signal<OnyxDropdownOptionsGroup<T>[] | null | undefined>(
    null,
  );
  protected isEmpty = computed(
    () => !!this.groups()?.every((group) => group.options.length === 0),
  );
  protected value = computed<T[]>(() =>
    this.selectedOptions().map((option) => option.value),
  );
  protected selectedAny = computed(
    () =>
      this.groups()
        ?.flatMap((group) => group.options)
        .some((option) => !!option.selected) ?? false,
  );
  protected selectedAll = computed(
    () =>
      this.groups()
        ?.flatMap((group) => group.options)
        .every((option) => !!option.selected || !!option.disabled) ?? false,
  );

  private searchRef = viewChild(OnyxSearchComponent);

  private selectedOptions = signal<OnyxDropdownOption<T>[]>([]);

  constructor(
    @Inject(ONYX_DROPDOWN_CONFIG) protected config: OnyxDropdownConfig<T>,
    private elementRef: ElementRef<HTMLElement>,
    private injector: Injector,
    private destroyRef: DestroyRef,
  ) {
    this.selectedOptions.set(
      config.selectedOptions ??
        config.values
          ?.filter((value) => value != null)
          .map((value) => ({ name: '', value })) ??
        [],
    );
    this.setOptions();

    toObservable(this.value)
      .pipe(
        distinctUntilChanged(isEqual),
        skip(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.config.changeValue({
          values: this.value(),
          options: this.selectedOptions(),
        });
        if (!this.config.multiple) this.config.close();
      });
  }

  public ngAfterViewInit(): void {
    effect(
      () =>
        (this.elementRef.nativeElement.style.width = this.config.width
          ? `${this.config.width}px`
          : 'auto'),
      { injector: this.injector },
    );
  }

  public focus(): void {
    if (this.config.showSearch) return this.focusSearch();
    this.focusFirstOption();
  }

  @HostListener('keydown.escape')
  @HostListener('keydown.shift.tab')
  @HostListener('keydown.tab')
  protected close(): void {
    this.config.close();
  }

  protected focusElement(): void {
    this.config.focusElement();
  }

  protected focusSearch(): void {
    if (!this.config.showSearch) return this.focusElement();
    this.searchRef()?.focus();
  }

  protected focusFirstOption(): void {
    // this.optionRefs()[0]?.element.focus();
  }

  protected toggleAll(selectAll?: boolean): void {
    selectAll ??= !this.selectedAny();
    this.groups.update((groups) =>
      groups?.map((group) => ({
        ...group,
        options: group.options.map((option) => ({
          ...option,
          selected: option.disabled ? option.selected : selectAll,
        })),
      })),
    );

    const toggledOptions =
      this.groups()
        ?.flatMap((group) => group.options)
        ?.filter((option) => option.selected === selectAll) ?? [];
    this.selectedOptions.update((selectedOptions) => {
      if (selectAll) {
        return uniqBy([...toggledOptions, ...selectedOptions], 'value');
      }
      return selectedOptions.filter(
        (option) =>
          !toggledOptions.some((toggled) =>
            isEqual(
              this.getCompareValue(toggled.value),
              this.getCompareValue(option.value),
            ),
          ),
      );
    });
  }

  protected toggleOption(value: T, force?: boolean): void {
    if (!this.config.optional && !this.config.multiple) force = true;

    this.groups.update((groups) =>
      groups?.map((group) => ({
        ...group,
        options: group.options.map((option) => {
          const toggle = isEqual(option.value, value)
            ? (force ?? !option.selected)
            : this.config.multiple
              ? null
              : false;
          if (toggle === null) return option;

          if (toggle) {
            this.selectedOptions.update((selectedOptions) => {
              if (this.config.multiple) {
                return uniqBy([...selectedOptions, option], 'value');
              }
              return [option];
            });
          } else {
            this.selectedOptions.update((selectedOptions) =>
              selectedOptions.filter(
                (selectedOption) =>
                  !isEqual(
                    this.getCompareValue(selectedOption.value),
                    this.getCompareValue(option.value),
                  ),
              ),
            );
          }

          return {
            ...option,
            selected: toggle,
          };
        }),
      })),
    );

    if (!this.config.multiple) setTimeout(() => this.config.close());
  }

  protected clear(): void {
    this.toggleAll(false);
    setTimeout(() => this.config.close());
  }

  private getCompareValue(value: T): T | unknown {
    return this.config.compareKey ? get(value, this.config.compareKey) : value;
  }

  private setOptions(): void {
    const source$ =
      this.config.options instanceof Observable
        ? this.config.options
        : of(this.config.options);

    let first = true;
    source$
      .pipe(
        map((data): OnyxDropdownOptionsGroup<T>[] | null => {
          if (data == null || !isArray(data)) {
            return null;
          } else if (has(data[0], 'options')) {
            return data as OnyxDropdownOptionsGroup<T>[];
          }
          return [{ options: data as OnyxDropdownOption<T>[] }];
        }),
        map((groups) => {
          return groups?.map((group) => ({
            ...group,
            options: group.options
              .filter((option) => !option.hidden)
              .map((option) => ({
                ...option,
                selected: this.value().some((value) =>
                  isEqual(
                    this.getCompareValue(value),
                    this.getCompareValue(option.value),
                  ),
                ),
              })),
          }));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((groups) => {
        this.groups.set(
          first && this.config.multiple
            ? groups?.map((group) => ({
                ...group,
                options: orderBy(
                  group.options,
                  [
                    (option) => option.disabled && option.selected,
                    (option) => option.selected,
                    (option) => !option.selected,
                  ],
                  ['desc', 'desc', 'desc'],
                ),
              }))
            : groups,
        );

        if (!first) return;
        first = false;

        if (this.isEmpty()) return this.focusSearch();
        if (this.config.showSearch || this.config.query() != null) return;

        this.focusFirstOption();
      });
  }
}
