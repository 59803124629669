@if (error(); as error) {
  <p
    class="error {{ type() }} {{ align() }}"
    [ngClass]="{
      'f-label-2': type() === 'default',
      'f=regular-2': type() !== 'default',
    }"
  >
    {{ I18N + '.' + error.code | translate: { value: error.value } }}
  </p>
}
