import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { ContractorsRoute } from '../../contractors.routes';
import { ContractorHelper } from '../helpers/contractor.helper';
import { ContractorsService } from '../services/contractors.service';

export const editContractorResolver: ResolveFn<ContractorForm> = (
  route: ActivatedRouteSnapshot,
) => {
  const contractorsService = inject(ContractorsService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return contractorsService.getContractor(uuid).pipe(
    map((contractor) => ContractorHelper.fromDto(contractor)),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('contractors.toasts.contractorNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(error, toastService, {
          keepOnNavigation: true,
        });
      }

      const urlTree = router.parseUrl(ContractorsRoute.CONTRACTORS_LIST);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
