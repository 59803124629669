import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  Input,
  computed,
  effect,
  forwardRef,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNaN, round } from 'lodash';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { INTEGER_REGEXP } from '../../../internal/constants';
import {
  OnyxClearButtonComponent,
  OnyxIconButtonComponent,
} from '../../buttons';
import { OnyxIconComponent } from '../../icons';
import {
  LabelLink,
  OnyxInputLabelComponent,
} from '../../labels/onyx-input-label/onyx-input-label.component';
import { OnyxTooltipContext } from '../../tooltip';

@Component({
  selector: 'onyx-text-field',
  imports: [
    OnyxFormControlErrorComponent,
    OnyxClearButtonComponent,
    OnyxInputLabelComponent,
    OnyxFormControlErrorComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    NgClass,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxTextFieldComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-text-field.component.html',
  styleUrls: ['./onyx-text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTextFieldComponent<T>
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public size = input<'s' | 'm'>('m');
  public type = input<'text' | 'email' | 'password' | 'url'>('text');
  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public width = input('100%');
  public autocomplete = input<string>();
  public placeholder = input<string>();
  public unit = input<string>();
  public decimalPlaces = input<number | null>(null);
  public acceptNegative = input(false);
  public link = input<LabelLink>();
  public maxLength = input<number>();
  public showErrors = input(true);
  public forceOptional = input<boolean>();
  public disabled = model(false);
  public showClear = input(true);
  public showPassword = model<boolean | null>(null);

  @Input() public set value(value: T | null) {
    if (value == null) {
      this.clearInput();
      return;
    }

    if (this.decimalPlaces() != null) {
      if (!this.pattern().test(value.toString())) return;
    }

    this.value_.set(value);
    setTimeout(
      () => (this.inputElementRef().nativeElement.value = value.toString()),
    );
  }
  public get value(): T | null {
    return this.value_() ?? null;
  }

  public valueChange = output<T | null>();

  protected onChange?: (value: T | null) => void;
  protected onTouched?: () => void;
  protected value_ = signal<T | undefined>(undefined);
  protected computedType = computed(() => {
    if (this.showPassword() == null) return this.type();
    return this.showPassword() ? 'text' : 'password';
  });
  protected maxLengthHint = computed(() => {
    if (this.maxLength() == null) return undefined;
    return `${this.value_()?.toString()?.length ?? 0}/${this.maxLength()}`;
  });
  protected isInteger = computed<boolean | null>(() => {
    if (this.decimalPlaces() == null) return null;
    return this.decimalPlaces() === 0;
  });
  protected pattern = computed(() => {
    if (this.isInteger()) return INTEGER_REGEXP;

    const places = this.decimalPlaces() ?? 2;
    const pattern = `^-?\\d*(?:[,.]\\d{0,${places}})?$`;
    return new RegExp(pattern);
  });

  private inputElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('inputElement');

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef,
  ) {
    super(injector, destroyRef);
    effect(() => (this.elementRef.nativeElement.style.width = this.width()));
  }

  public registerOnChange(fn: (value: T | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: T | null): void {
    if (this.decimalPlaces() != null && !this.isInteger()) {
      this.value = this.convertNumber(value as number, 'float');
      return;
    }

    this.value = value;
  }

  public focus(): void {
    if (this.disabled()) return;
    this.inputElementRef().nativeElement.focus();
  }

  protected validateInput(event: KeyboardEvent): boolean {
    if (this.decimalPlaces() == null) return true;

    const target = event.target as HTMLInputElement;
    const { value, selectionStart, selectionEnd } = target;

    if (this.acceptNegative() && event.key === '-') {
      const newValue = this.toggleNegativeSign(value);
      const numberValue = Number(newValue) as T;

      if (!isNaN(numberValue)) {
        const value = this.isInteger()
          ? numberValue
          : this.convertNumber(numberValue as number, 'integer');

        this.value_.set(numberValue);
        this.onChange?.(value);
        this.valueChange.emit(value);
      }

      this.inputElementRef().nativeElement.value = newValue;
      event.preventDefault();
      return false;
    }

    const newValue =
      value.slice(0, selectionStart!) + event.key + value.slice(selectionEnd!);
    if (!this.pattern().test(newValue)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  protected validatePaste(event: ClipboardEvent): boolean {
    if (this.decimalPlaces() == null) return true;

    const target = event.target as HTMLInputElement;
    const { value, selectionStart, selectionEnd } = target;
    const pasteData = event.clipboardData?.getData('text') ?? '';

    const newValue =
      value.slice(0, selectionStart!) + pasteData + value.slice(selectionEnd!);

    if (this.pattern().test(newValue)) {
      const numberValue = Number(newValue) as T;
      const value = this.isInteger()
        ? numberValue
        : this.convertNumber(numberValue as number, 'integer');

      if (value != null && (value as number) < 0 && !this.acceptNegative()) {
        event.preventDefault();
        return false;
      }

      this.onChange?.(value);
      this.valueChange.emit(value);
      this.inputElementRef().nativeElement.value = newValue;
    }

    event.preventDefault();
    return false;
  }

  protected clearInput(): void {
    this.value_.set(undefined);
    this.onChange?.(null);
    this.valueChange.emit(null);
    setTimeout(() => (this.inputElementRef().nativeElement.value = ''));
  }

  protected toggleShowPassword(): void {
    this.showPassword.update((showPassword) => !showPassword);
  }

  protected handleValueChange(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    if (this.maxLength() != null) {
      value = value.slice(0, this.maxLength());
    }
    this.value_.set(value as T);

    if (this.decimalPlaces() != null) {
      value = value.replace(',', '.').replace(/^0(?=[0-9])/, '');

      if (!value) {
        this.onChange?.(null);
        this.valueChange.emit(null);
        return;
      } else if (this.pattern().test(value)) {
        const numberValue = Number(value) as T;
        const convertedValue = this.isInteger()
          ? numberValue
          : this.convertNumber(numberValue as number, 'integer');

        this.onChange?.(convertedValue);
        this.valueChange.emit(convertedValue);
      }
    } else {
      this.onChange?.((value || null) as T | null);
      this.valueChange.emit((value || null) as T | null);
    }

    this.inputElementRef().nativeElement.value = value;
  }

  private toggleNegativeSign(value: string): string {
    if (!this.acceptNegative()) return value;

    return value.startsWith('-') ? value.slice(1) : `-${value}`;
  }

  private convertNumber(
    value: number | null,
    format: 'integer' | 'float',
  ): T | null {
    if (value == null) return null;

    const decimalPlaces = this.decimalPlaces()!;
    if (format === 'integer') {
      return Math.round(value * Math.pow(10, decimalPlaces)) as T;
    }
    return round(value / Math.pow(10, decimalPlaces), decimalPlaces) as T;
  }
}
