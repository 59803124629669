import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxModalService, OnyxToastService } from '@onyx/angular';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';

import { FleetModalComponent } from '../../fleet-modal/fleet-modal.component';
import { FleetRoute } from '../../fleet.routes';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';
import { Fleet } from '../interfaces/fleet';
import { FleetService } from '../services/fleet.service';

export const fleetCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const fleetService = inject(FleetService);
  const modalService = inject(OnyxModalService);
  const router = inject(Router);
  const toastService = inject(OnyxToastService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const uuid = route.paramMap.get('uuid')!;
  const category = state?.[FleetRouterStateKey.CATEGORY];

  fleetService.getFleet(category, uuid).subscribe({
    next: (fleet) => modalService.open<Fleet>(FleetModalComponent, fleet),
    error: (error) =>
      ValidationHelper.handleUnexpectedError(error, toastService),
  });

  const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};
