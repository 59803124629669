import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxAvatarComponent,
  OnyxBadgeComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalService,
} from '@onyx/angular';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { Driver } from '../../../../drivers/common/interfaces/driver';
import { DriverModalComponent } from '../../../../drivers/driver-modal/driver-modal.component';
import { Employee } from '../../../../management-panel/employees/common/interfaces/employee';
import { EmployeeModalComponent } from '../../../../management-panel/employees/employees-list/employee-modal/employee-modal.component';

@Component({
  selector: 'app-fleet-modal-crew-item',
  imports: [
    OnyxIconComponent,
    OnyxAvatarComponent,
    OnyxBadgeComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
  ],
  templateUrl: './fleet-modal-crew-item.component.html',
  styleUrl: './fleet-modal-crew-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalCrewItemComponent {
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly ActionHelper = ActionHelper;

  public driver = input<Driver | null>();
  public employee = input<Employee | null>();

  constructor(private modalService: OnyxModalService) {}

  protected openModal(type: 'driver' | 'employee') {
    const modalComponent =
      type === 'driver' ? DriverModalComponent : EmployeeModalComponent;
    const data = type === 'driver' ? this.driver() : this.employee();
    if (!data) return;

    this.modalService.open(modalComponent, data);
  }
}
