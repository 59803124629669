import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  Injector,
  input,
  model,
  OnInit,
  output,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { cloneDeep, get, isArray, isEqual, PropertyPath } from 'lodash';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Observable,
  Subject,
  switchMap,
  throttleTime,
} from 'rxjs';
import {
  OnyxDropdownDirective,
  OnyxTextOverflowDirective,
} from '../../../directives';
import {
  OnyxDropdownOption,
  OnyxDropdownOptionsGroup,
  OnyxOption,
} from '../../../directives/interfaces';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxFilterPipe } from '../../../pipes';
import { OnyxClearButtonComponent } from '../../buttons';
import { OnyxIconComponent } from '../../icons';
import { LabelLink, OnyxInputLabelComponent } from '../../labels';
import { OnyxPaths } from '../../table';
import { OnyxTooltipContext } from '../../tooltip';

@Component({
  selector: 'onyx-dropdown',
  imports: [
    OnyxInputLabelComponent,
    OnyxIconComponent,
    OnyxDropdownDirective,
    OnyxClearButtonComponent,
    OnyxFormControlErrorComponent,
    OnyxTextOverflowDirective,
    NgClass,
    TranslatePipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxDropdownComponent),
      multi: true,
    },
    {
      provide: OnyxFilterPipe,
      useClass: OnyxFilterPipe,
    },
  ],
  templateUrl: './onyx-dropdown.component.html',
  styleUrl: './onyx-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDropdownComponent<T>
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor, OnInit
{
  protected readonly I18N = `${I18N_NAMESPACE}.dropdown`;

  public source =
    input<
      (
        query: string,
      ) => Observable<OnyxDropdownOptionsGroup<T>[] | OnyxOption<T>[] | null>
    >();
  public options = input(null, {
    transform: (
      options:
        | OnyxDropdownOptionsGroup<T>[]
        | OnyxOption<T>[]
        | null
        | undefined,
    ): OnyxDropdownOptionsGroup<T>[] | null =>
      cloneDeep(this.mapToGroups(options)),
  });
  public compareKey = input<OnyxPaths<T> | PropertyPath>();
  public searchKeys = input<(OnyxPaths<T> | PropertyPath)[]>();
  public value = model<T | T[] | null>();
  public label = input<string>();
  public placeholder = input(`${this.I18N}.choose`);
  public hint = input<OnyxTooltipContext>();
  public link = input<LabelLink>();
  public multiple = input(false);
  public showCheckbox = input(true);
  public search = input(false);
  public searchPlaceholder = input<string>();
  public size = input<'s' | 'm'>('m');
  public optionSize = input(32);
  public optional = input(false);
  public showAddOption = input<boolean | 'value'>(false);
  public addOptionMessage = input<string>();
  public notFoundMessage = input('');
  public ribbon = input(false);
  public transparentRibbon = input(false);
  public headerTemplateRef = input<TemplateRef<any>>();
  public header = input<string>();
  public optionTemplateRef = input<TemplateRef<any>>();
  public footerTemplateRef = input<TemplateRef<any>>();
  public showOptional = input(true);
  public showErrors = input(true);
  public width = input('100%');

  public addOption = output<string | undefined>();
  public selectedOptionsChange = output<OnyxDropdownOption<T>[]>();

  protected onChange?: (value: T | T[] | null) => void;
  protected onTouched?: () => void;
  protected disabled = signal(false);
  protected expanded = signal(false);
  protected query = signal('');
  protected selectedOptions = signal<OnyxOption<T>[]>([]);
  protected selectedNames = computed(() =>
    this.selectedOptions().map((option) =>
      this.translateService.instant(option.name),
    ),
  );

  private _options$ = new BehaviorSubject<OnyxDropdownOptionsGroup<T>[] | null>(
    null,
  );
  protected get options$() {
    return this._options$.asObservable();
  }

  private _options = toSignal(this._options$);
  private request$ = new Subject<{
    source: (
      query: string,
    ) => Observable<OnyxDropdownOptionsGroup<T>[] | OnyxOption<T>[] | null>;
    query: string;
  }>();
  private selectedValues = computed(() =>
    this.selectedOptions().map((option) => option.value),
  );
  private loadingValues = signal<T[]>([]);

  private dropdownElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('dropdownElement');

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef,
    private filterPipe: OnyxFilterPipe,
    private translateService: TranslateService,
  ) {
    super(injector, destroyRef);

    effect(() => (this.elementRef.nativeElement.style.width = this.width()));
    effect(() => this.selectedOptionsChange.emit(this.selectedOptions()));
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.request$
      .pipe(
        distinctUntilChanged((a, b) => a.query === b.query),
        throttleTime(300, undefined, { trailing: true }),
        switchMap(({ source, query }) => source(query)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((options) => this._options$.next(this.mapToGroups(options)));

    toObservable(this.value, { injector: this.injector })
      .pipe(
        filter((value) => value !== undefined),
        distinctUntilChanged(isEqual),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.writeValue(value));

    effect(
      () => {
        const options = this.options();
        const source = this.source();
        const query = this.query();

        if (options) {
          this._options$.next(this.filterOptions(options, query));
        } else if (source) {
          this.request$.next({ source, query });
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const options = this._options();
        const loadingValues = this.loadingValues();
        if (!options?.length || !loadingValues.length) return;

        this.writeValue(loadingValues);
      },
      { injector: this.injector },
    );
  }

  public writeValue(value: T | T[] | null): void {
    if (value == null) {
      this.selectedOptions.set([]);
      this.loadingValues.set([]);
      return;
    }

    const options = this._options()?.flatMap((group) => group.options);
    if (!options?.length) {
      this.selectedOptions.set([]);
      this.loadingValues.set(isArray(value) ? value : [value]);
      return;
    }

    const values = isArray(value) ? value : [value];
    const selectedOptions = values
      .map((v) =>
        options.find((option) =>
          isEqual(this.getCompareValue(option.value), this.getCompareValue(v)),
        ),
      )
      .filter((option) => option != null)
      .map((option) => ({
        ...option,
        selected: true,
      }));

    this.changeSelectedOptions(selectedOptions);
  }

  public registerOnChange(fn: (value: T | T[] | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  public focus(): void {
    if (this.disabled()) return;
    this.dropdownElementRef().nativeElement.focus();
    this.expanded.set(true);
  }

  protected changeSelectedOptions(options: OnyxDropdownOption<T>[]): void {
    this.selectedOptions.set(options);
    this.loadingValues.set([]);

    const value = this.multiple()
      ? this.selectedValues()
      : (this.selectedValues()[0] ?? null);

    this.value.set(value);
    this.onChange?.(value);
    this.onTouched?.();
  }

  protected clearValue(): void {
    this.query.set('');
    this.expanded.set(false);
    this.changeSelectedOptions([]);
  }

  private getCompareValue(value: T): T | unknown {
    const compareKey = this.compareKey();
    return compareKey ? get(value, compareKey) : value;
  }

  private filterOptions(
    groups: OnyxDropdownOptionsGroup<T>[],
    query: string,
  ): OnyxDropdownOptionsGroup<T>[] {
    return groups.map((group) => ({
      ...group,
      options: this.filterPipe.transform(group.options, query, [
        'name',
        ...(this.searchKeys() ?? []).map(
          (key) =>
            `value.${key.toString()}` as OnyxPaths<OnyxDropdownOption<T>>,
        ),
      ]),
    }));
  }

  private mapToGroups(
    options:
      | OnyxDropdownOptionsGroup<T>[]
      | OnyxDropdownOption<T>[]
      | null
      | undefined,
  ): OnyxDropdownOptionsGroup<T>[] | null {
    if (options == null) return null;
    if (options.length === 0) return [];
    if ('options' in options[0]) {
      return options as OnyxDropdownOptionsGroup<T>[];
    }
    return [{ options: options as OnyxDropdownOption<T>[] }];
  }
}
