import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { OnyxMessageActionButtonComponent } from '../onyx-message-action-button/onyx-message-action-button.component';
import { OnyxMessageCloseButtonComponent } from './onyx-message-close-button/onyx-message-close-button.component';

@Component({
  selector: 'onyx-message',
  imports: [
    OnyxMessageActionButtonComponent,
    OnyxMessageCloseButtonComponent,
    NgClass,
  ],
  templateUrl: './onyx-message.component.html',
  styleUrl: './onyx-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxMessageComponent {
  public type = input.required<'warning' | 'error' | 'information'>();
  public size = input<'s' | 'm'>('m');
  public borderRadius = input(false);
  public showClose = input(false);
  public action1Text = input<string>();
  public action2Text = input<string>();

  public closeMessage = output<void>();
  public action1Click = output<void>();
  public action2Click = output<void>();
}
