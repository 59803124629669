import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { DriverForm } from '../../driver-form/driver-form.component';
import { DriversRoute } from '../../drivers.routes';
import { DriverHelper } from '../helpers/driver.helper';
import { DriversService } from '../services/drivers.service';

export const editDriverResolver: ResolveFn<DriverForm> = (
  route: ActivatedRouteSnapshot,
) => {
  const driverService = inject(DriversService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return driverService.getDriver(uuid).pipe(
    map((dto) => DriverHelper.fromDto(dto)),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('drivers.driverForm.driverNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(error, toastService, {
          keepOnNavigation: true,
        });
      }

      const urlTree = router.parseUrl(DriversRoute.DRIVERS_LIST);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};
