import {
  CompanyDictionariesStatistics,
  CompanyDictionariesStatisticsData,
} from '../interfaces/statistics/company-dictionaries-statistics';
import {
  ContractorStatistics,
  ContractorStatisticsData,
} from '../interfaces/statistics/contractor-statistics';
import {
  DelegationsStatistics,
  DelegationsStatisticsData,
} from '../interfaces/statistics/delegations-statistics';
import {
  DriverStatistics,
  DriverStatisticsData,
} from '../interfaces/statistics/drivers-statistics';
import {
  EmployeeStatistics,
  EmployeeStatisticsData,
} from '../interfaces/statistics/employee-statistics';
import {
  EmployeesStatistics,
  EmployeesStatisticsData,
} from '../interfaces/statistics/employees-statistics';
import {
  FleetStatistics,
  FleetStatisticsData,
} from '../interfaces/statistics/fleet-statistics';
import {
  OrdersStatistics,
  OrdersStatisticsData,
} from '../interfaces/statistics/orders-statistics';
import {
  PointsOfInterestStatistics,
  PointsOfInterestStatisticsData,
} from '../interfaces/statistics/points-of-interest-statistics';

export enum StatisticsType {
  COMPANY_DICTIONARIES = 'company-dictionaries',
  CONTRACTORS = 'contractors',
  DELEGATIONS = 'delegations',
  DRIVERS = 'drivers',
  EMPLOYEE = 'employee',
  EMPLOYEES = 'employees',
  FLEET = 'fleet',
  ORDERS = 'orders',
  POINTS_OF_INTEREST = 'points-of-interest',
}

export interface StatisticsByType {
  [StatisticsType.COMPANY_DICTIONARIES]: CompanyDictionariesStatistics;
  [StatisticsType.CONTRACTORS]: ContractorStatistics;
  [StatisticsType.DELEGATIONS]: DelegationsStatistics;
  [StatisticsType.DRIVERS]: DriverStatistics;
  [StatisticsType.EMPLOYEE]: EmployeeStatistics;
  [StatisticsType.EMPLOYEES]: EmployeesStatistics;
  [StatisticsType.FLEET]: FleetStatistics;
  [StatisticsType.ORDERS]: OrdersStatistics;
  [StatisticsType.POINTS_OF_INTEREST]: PointsOfInterestStatistics;
}

export interface StatisticsDataByType {
  [StatisticsType.COMPANY_DICTIONARIES]: CompanyDictionariesStatisticsData;
  [StatisticsType.CONTRACTORS]: ContractorStatisticsData;
  [StatisticsType.DELEGATIONS]: DelegationsStatisticsData;
  [StatisticsType.DRIVERS]: DriverStatisticsData;
  [StatisticsType.EMPLOYEE]: EmployeeStatisticsData;
  [StatisticsType.EMPLOYEES]: EmployeesStatisticsData;
  [StatisticsType.FLEET]: FleetStatisticsData;
  [StatisticsType.ORDERS]: OrdersStatisticsData;
  [StatisticsType.POINTS_OF_INTEREST]: PointsOfInterestStatisticsData;
}
