import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { UpperCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  Injector,
  signal,
  viewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxChip,
  OnyxChipsComponent,
  OnyxFormMode,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxMapComponent,
  OnyxMapEvent,
  OnyxMapMarkerType,
  OnyxModalComponent,
  OnyxModalService,
  OnyxOverlayPosition,
  OnyxPhonePipe,
  OnyxPluralTranslatePipe,
  OnyxTableComponent,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { AddressComponent } from '../../../../common/components/address/address.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { BlockContractorModalComponent } from '../common/components/block-contractor-modal/block-contractor-modal.component';
import { contractorsFormDocumentsColumns } from '../common/constants/contractors-form-documents-columns';
import { ContractorStatus } from '../common/enums/contractor-status';
import { Contractor } from '../common/interfaces/contractor';
import { ContractorsService } from '../common/services/contractors.service';
import { ContractorsRoute } from '../contractors.routes';

enum ContractorModalOption {
  ORDERS = 'orders',
  ADDRESSES = 'addresses',
  DOCUMENTS = 'documents',
  COMPANY = 'company',
}

@Component({
  selector: 'app-contractor-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    OnyxPhonePipe,
    OnyxChipsComponent,
    OnyxTableComponent,
    OnyxTooltipDirective,
    OnyxPluralTranslatePipe,
    OnyxMapComponent,
    AddressComponent,
    UpperCasePipe,
  ],
  templateUrl: './contractor-modal.component.html',
  styleUrl: './contractor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorModalComponent implements AfterViewInit {
  protected readonly I18N = 'contractors';
  protected readonly COLUMNS = contractorsFormDocumentsColumns(
    OnyxFormMode.ADD,
  );
  protected readonly GROUP_CHIPS: OnyxChip<ContractorModalOption>[] = [
    { name: 'labels.orders', value: ContractorModalOption.ORDERS },
    {
      name: `labels.addresses`,
      value: ContractorModalOption.ADDRESSES,
    },
    { name: `labels.documents`, value: ContractorModalOption.DOCUMENTS },
    {
      name: `${this.I18N}.aboutCompany`,
      value: ContractorModalOption.COMPANY,
    },
  ];

  protected readonly ActionHelper = ActionHelper;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly ContractorModalOption = ContractorModalOption;
  protected readonly ContractorStatus = ContractorStatus;
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  private readonly map = viewChild(OnyxMapComponent);

  protected chipValue = signal([this.GROUP_CHIPS[0].value]);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected contractor: Contractor,
    protected dialogRef: DialogRef<void>,
    private modalService: OnyxModalService,
    private contractorsService: ContractorsService,
    private router: Router,
    private toastService: OnyxToastService,
    private injector: Injector,
  ) {}

  public ngAfterViewInit(): void {
    effect(
      () => {
        const map = this.map();
        if (!map) return;

        map.dispatch(
          new OnyxMapEvent.AddUpdateMarker({
            id: OnyxMapMarkerType.BASE,
            type: OnyxMapMarkerType.BASE,
            coordinates: this.contractor.addresses.mainAddress.coordinates,
          }),
        );

        const correspondenceAddress =
          this.contractor.addresses.correspondenceAddress;
        if (correspondenceAddress) {
          map.dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: OnyxMapMarkerType.CORRESPONDENCE_ADDRESS,
              type: OnyxMapMarkerType.CORRESPONDENCE_ADDRESS,
              coordinates: correspondenceAddress.coordinates,
            }),
          );
        }

        const branches = this.contractor.branches;
        if (branches) {
          const points = branches.map((branch, index) => ({
            id: `branch-${index}`,
            coordinates: branch.correspondenceAddress?.coordinates,
            type: OnyxMapMarkerType.POINT,
          }));

          for (const { id, coordinates, type } of points) {
            if (coordinates) {
              map.dispatch(
                new OnyxMapEvent.AddUpdateMarker({
                  id,
                  type,
                  coordinates,
                }),
              );
            }
          }
        }

        map.dispatch(new OnyxMapEvent.FitContent());
      },
      { injector: this.injector },
    );
  }

  protected blockContractor(): void {
    this.modalService
      .open<Contractor, boolean>(BlockContractorModalComponent, this.contractor)
      .subscribe((result) => {
        if (result) this.close$.next();
      });
  }

  protected unblockContractor(): void {
    this.contractorsService.unblockContractor(this.contractor.uuid).subscribe({
      next: () => {
        this.toastService.showSuccess(
          `${this.I18N}.toasts.contractorUnblocked`,
        );
        this.close$.next();
      },
      error: (error) =>
        ValidationHelper.handleUnexpectedError(error, this.toastService),
    });
  }

  protected editContractor(uuid: string): void {
    this.close$.next();
    this.router.navigateByUrl(
      `${ContractorsRoute.EDIT_CONTRACTOR.replace(':uuid', uuid)}`,
    );
  }
}
