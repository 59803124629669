import { isNumber } from 'lodash';
import { OnyxCoordinates } from '../components';

export const COORDINATES_PATTERN =
  /^\s*(?<latitude>-?(?:[0-8]?\d(?:\s*\d{1,2})?(?:[.,]\d+)?|90(?:[.,]0+)?))\s*[;, ]\s*(?<longitude>-?(?:1?[0-7]?\d{1,2}(?:\s*\d{1,2})?(?:[.,]\d+)?|180(?:[.,]0+)?))\s*$/;

export class CoordinatesHelper {
  public static fromString(value: string): OnyxCoordinates | null {
    const match = value?.match(COORDINATES_PATTERN);
    if (!match) return null;

    const coordinates = {
      latitude: parseFloat(match.groups!['latitude'].replace(',', '.')),
      longitude: parseFloat(match.groups!['longitude'].replace(',', '.')),
    };

    return CoordinatesHelper.areValid(coordinates) ? coordinates : null;
  }

  public static toString(value: OnyxCoordinates | null | undefined): string {
    if (!isNumber(value?.latitude) || !isNumber(value?.longitude)) return '';
    return `${+value.latitude.toFixed(5)}, ${+value.longitude.toFixed(5)}`;
  }

  private static areValid(coordinates: OnyxCoordinates): boolean {
    return (
      coordinates.latitude >= -90 &&
      coordinates.latitude <= 90 &&
      coordinates.longitude >= -180 &&
      coordinates.longitude <= 180
    );
  }
}
