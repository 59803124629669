import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { MakeAndModelCellComponent } from '../../../../../common/components/cells/make-and-model-cell/make-and-model-cell.component';
import { StringCellComponent } from '../../../../../common/components/cells/string-cell/string-cell.component';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { BaseVehicleCellComponent } from '../../../bases/common/cells/base-vehicle-cell/base-vehicle-cell.component';

export const EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS: OnyxTableColumn<SimplifiedFleet>[] =
  [
    {
      id: 'vehicle',
      name: 'labels.vehicle',
      width: 'fill',
      component: {
        ref: BaseVehicleCellComponent,
        inputs: (data): OnyxComponentInputs<BaseVehicleCellComponent> => ({
          generalInformation: data.item.generalInformation,
        }),
      },
    },
    {
      id: 'vehicleType',
      name: 'labels.vehicleType',
      width: '150px',
      component: {
        ref: StringCellComponent,
        inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
          value: data.item.generalInformation.category,
          dictionaryCode: DictionaryCode.VEHICLE_CATEGORY,
          color: 'gray',
        }),
      },
    },
    {
      id: 'makeAndModel',
      name: 'labels.makeAndModel',
      width: '200px',
      component: {
        ref: MakeAndModelCellComponent,
        inputs: (data): OnyxComponentInputs<MakeAndModelCellComponent> => ({
          make: data.item.generalInformation.make,
          model: data.item.generalInformation.model,
        }),
      },
    },
    {
      id: 'fuel',
      name: 'labels.fuel',
      width: '60px',
      component: {
        ref: StringCellComponent,
        inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
          value: data.item.fuelTankCapacity?.fuelType,
          dictionaryCode: DictionaryCode.FUEL_TYPE,
          color: 'gray',
        }),
      },
    },
  ];
