import { OnyxToastType } from '../components/toast';
import { OnyxPhone } from '../interfaces';
import { OnyxToastService } from '../services';

export class ActionHelper {
  public static copy(text: string, toastService: OnyxToastService): void {
    navigator.clipboard.writeText(text);
    toastService.showCustom(OnyxToastType.COPIED_TO_CLIPBOARD);
  }

  public static makeCall(phone: OnyxPhone): void {
    window.open(`tel:${phone.areaCode}${phone.number}`, '_self');
  }

  public static openLink(link: string): void {
    window.open(link, '_blank');
  }

  public static sendEmail(email: string): void {
    window.open(`mailto:${email}`, '_self');
  }

  public static downloadFile(file: File): void {
    const url = URL.createObjectURL(file);

    const linkElement = document.createElement('a');
    linkElement.href = url;
    linkElement.download = file.name;

    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);

    URL.revokeObjectURL(url);
  }
}
