import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxOption } from '@onyx/angular';
import { chain } from 'lodash';
import { forkJoin, map, Observable, of, shareReplay } from 'rxjs';
import { AuthService } from '../../auth/common/services/auth.service';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { EmployeeRole } from '../../dashboard/management-panel/employees/common/enums/employee-role';
import { OrderPointCategory } from '../../dashboard/orders/common/enums/order-point-category';
import {
  DictionaryCode,
  DictionaryValueByCode,
} from '../enums/dictionary-code';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {
    super(http);
  }

  public getDictionary<T extends DictionaryCode>(
    code: T,
  ): Observable<DictionaryValueByCode[T][]> {
    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;

    // TEMP: Wait for BE implementation
    if (code === DictionaryCode.ORDER_POINT_TYPE) {
      return of([
        {
          value: 'loading',
          name: 'Załadunek',
          category: OrderPointCategory.LOADING,
          icon: 'loading',
          color: 'green',
          includeInOrder: true,
          isAlwaysOpen: false,
        },
        {
          value: 'unloading',
          name: 'Rozładunek',
          category: OrderPointCategory.UNLOADING,
          icon: 'unloading',
          color: 'red',
          includeInOrder: true,
          isAlwaysOpen: false,
        },
        {
          value: 'customs',
          name: 'Odprawa celna',
          category: OrderPointCategory.CHECKPOINT,
          icon: 'customs',
          color: 'violet',
          includeInOrder: true,
          isAlwaysOpen: false,
        },
        {
          value: 'car-wash',
          name: 'Myjnia',
          category: OrderPointCategory.CHECKPOINT,
          icon: 'car-wash',
          color: 'blue',
          includeInOrder: false,
          isAlwaysOpen: false,
        },
        {
          value: 'gas-station',
          name: 'Stacja paliw',
          category: OrderPointCategory.CHECKPOINT,
          icon: 'gas-station',
          color: 'orange',
          includeInOrder: false,
          isAlwaysOpen: true,
        },
        {
          value: 'parking',
          name: 'Parking',
          category: OrderPointCategory.CHECKPOINT,
          icon: 'parking',
          color: 'blue',
          includeInOrder: false,
          isAlwaysOpen: true,
        },
        {
          value: 'ferry',
          name: 'Prom',
          category: OrderPointCategory.TRANSIT,
          icon: 'ferry',
          color: 'sea-green',
          includeInOrder: true,
          isAlwaysOpen: false,
        },
        {
          value: 'tunnel',
          name: 'Tunel',
          category: OrderPointCategory.TRANSIT,
          icon: 'tunnel',
          color: 'gray',
          includeInOrder: true,
          isAlwaysOpen: true,
        },
        {
          value: 'train',
          name: 'Pociąg',
          category: OrderPointCategory.TRANSIT,
          icon: 'train',
          color: 'gold',
          includeInOrder: true,
          isAlwaysOpen: false,
        },
        {
          value: 'other',
          name: 'Inny punkt',
          category: OrderPointCategory.CHECKPOINT,
          icon: 'place',
          color: 'gray',
          includeInOrder: false,
          isAlwaysOpen: false,
        },
      ] satisfies DictionaryValueByCode[DictionaryCode.ORDER_POINT_TYPE][]) as any;
    } else if (code === DictionaryCode.ORDER_SEMI_TRAILER_SIZE) {
      return of([
        {
          value: 'mega',
          name: 'Mega',
        },
        {
          value: 'half-mega',
          name: 'Pół-mega',
        },
        {
          value: 'standard',
          name: 'Standard',
        },
        {
          value: 'auto',
          name: 'Dowolny',
        },
      ] as DictionaryValueByCode[DictionaryCode.ORDER_SEMI_TRAILER_SIZE][]) as any;
    } else if (code === DictionaryCode.VAT_RATE) {
      return of([
        {
          value: '23',
          name: '23%',
        },
        {
          value: '8',
          name: '8%',
        },
        {
          value: '0',
          name: '0%',
        },
        {
          value: 'np',
          name: 'NP',
        },
        {
          value: 'zw',
          name: 'ZW',
        },
      ] as DictionaryValueByCode[DictionaryCode.VAT_RATE][]) as any;
    }
    // END TEMP

    return this.get<DictionaryValueByCode[T][]>(
      `/dictionaries/${code}/${language}`,
    ).pipe(shareReplay(1));
  }

  public getFleetCategories(): Observable<OnyxOption<FleetCategory>[]> {
    return forkJoin([
      this.getDictionary(DictionaryCode.VEHICLE_CATEGORY),
      this.getDictionary(DictionaryCode.TRAILER_CATEGORY),
    ]).pipe(
      map((categories) => categories.flat()),
      shareReplay(1),
    );
  }

  public getVatIdCountries(): Observable<OnyxOption<string>[]> {
    return this.getDictionary(DictionaryCode.COUNTRY).pipe(
      map((countries) =>
        chain(countries)
          .map((country) => ({
            ...country,
            name: country.value.toUpperCase(),
          }))
          .orderBy('name')
          .value(),
      ),
      shareReplay(1),
    );
  }

  public getAvailableEmployeeRoles(): Observable<
    DictionaryValueByCode[DictionaryCode.EMPLOYEE_ROLE][]
  > {
    return this.getDictionary(DictionaryCode.EMPLOYEE_ROLE).pipe(
      map((roles) => {
        const userRoles = this.authService.user()?.roles;
        const isAdmin = userRoles?.includes(EmployeeRole.BUSINESS_ADMIN);
        const isManager = isAdmin || userRoles?.includes(EmployeeRole.MANAGER);

        return roles.filter((role) => {
          if (role.value === EmployeeRole.BUSINESS_ADMIN) return isAdmin;
          if (role.value === EmployeeRole.MANAGER) return isAdmin;
          return isManager;
        });
      }),
      shareReplay(1),
    );
  }
}
