import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  HostListener,
  Injector,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { debounceTime, fromEventPattern, skip, Subject, takeUntil } from 'rxjs';
import { ONYX_TOOLTIP_DELAY } from '../../../constants';
import {
  OnyxDropdownDirective,
  OnyxTooltipDirective,
} from '../../../directives';
import { OnyxOption } from '../../../directives/interfaces';
import { OnyxOverlayPosition } from '../../../enums';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxHereMapsService } from '../../../internal/services/onyx-here-maps.service';
import { OnyxMapEventQueue } from '../../../internal/utilities/onyx-map-event-queue';
import { OnyxMapService } from '../../../services';
import { OnyxCoordinates } from '../../address-input';
import { OnyxIconButtonComponent } from '../../buttons/onyx-icon-button/onyx-icon-button.component';
import { OnyxIconComponent } from '../../icons';
import {
  OnyxMapLayerType,
  OnyxMapMarkerType,
  OnyxMapMode,
  OnyxMapVehicleStatus,
} from '../enums';
import { OnyxMapEventType } from '../enums/onyx-map-event-type';
import { OnyxMapLayerIndex } from '../enums/onyx-map-layer-index';
import { OnyxMapEvent } from '../interfaces';

@Component({
  selector: 'onyx-map',
  imports: [
    TranslatePipe,
    OnyxIconComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
    OnyxTooltipDirective,
    NgTemplateOutlet,
    NgClass,
  ],
  templateUrl: './onyx-map.component.html',
  styleUrl: './onyx-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxMapComponent implements AfterViewInit {
  protected readonly I18N = `${I18N_NAMESPACE}.map`;
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly OnyxMapMode = OnyxMapMode;
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  protected readonly LAYER_TYPE_OPTIONS: OnyxOption<OnyxMapLayerType>[] =
    Object.values(OnyxMapLayerType).map((value) => ({
      name: `${this.I18N}.layerTypes.${value}`,
      value,
    }));

  private readonly controlsElementRef =
    viewChild<ElementRef<HTMLElement>>('controlsElement');

  public border = input(true);
  public preview = input(true);

  public mapClick = output<void>();
  public selectedVehicleIdChange = output<string | null>();

  public mode = signal(OnyxMapMode.MINIMIZED);

  protected layerButtonActive = signal(false);
  protected layerType = signal(OnyxMapLayerType.DEFAULT);

  protected active = computed(() => {
    if (!this.mapService.standaloneMap()) return true;
    return this.mode() === OnyxMapMode.STANDALONE;
  });
  protected showPreview = computed(
    () => this.mode() === OnyxMapMode.MINIMIZED && this.preview(),
  );

  private map = signal<H.Map | null>(null);
  private behaviors = signal<H.mapevents.Behavior | null>(null);
  private ui = signal<H.ui.UI | null>(null);

  private queue = new OnyxMapEventQueue();
  private destroy$ = new Subject<void>();

  private markers = signal(new Map<string, H.map.Marker>());
  private vehicles = signal(new Map<string, H.map.DomMarker>());
  private selectedVehicleId = signal<string | null>(null);

  constructor(
    private injector: Injector,
    private elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
    private mapService: OnyxMapService,
    private hereMapsService: OnyxHereMapsService,
  ) {
    effect(() => {
      const classList = this.elementRef.nativeElement.classList;
      classList.toggle('border', this.border());
      classList.toggle('minimized', this.mode() === OnyxMapMode.MINIMIZED);
      classList.toggle('maximized', this.mode() === OnyxMapMode.MAXIMIZED);
      classList.toggle('standalone', this.mode() === OnyxMapMode.STANDALONE);
      classList.toggle('active', this.active());
    });

    effect(() => {
      if (this.mode() === OnyxMapMode.STANDALONE) return;

      if (this.showPreview()) this.dispatch(new OnyxMapEvent.FitContent());
      setTimeout(() => this.resizeMap());
    });

    effect(() => {
      const map = this.map();
      if (!map) return;

      const defaultLayers = this.hereMapsService.getDefaultLayers();
      const defaultLayer = defaultLayers.vector.normal.map;
      const satelliteLayer = defaultLayers.raster.satellite.map;

      const baseLayer = {
        [OnyxMapLayerType.DEFAULT]: defaultLayer,
        [OnyxMapLayerType.SATELLITE]: satelliteLayer,
        [OnyxMapLayerType.TRAFFIC]: defaultLayer,
        [OnyxMapLayerType.TRAFFIC_INCIDENTS]: defaultLayer,
      }[this.layerType()];
      baseLayer.setMin(this.mapService.MIN_ZOOM);

      const informationLayer =
        {
          [OnyxMapLayerType.TRAFFIC]: defaultLayers.vector.normal.traffic,
          [OnyxMapLayerType.TRAFFIC_INCIDENTS]:
            defaultLayers.vector.normal.trafficincidents,
        }[this.layerType() as string] ?? null;

      // TODO: Add filter for custom layers in the future
      map.getLayers().flush();
      map.setBaseLayer(baseLayer);

      if (informationLayer) {
        informationLayer.setMin(this.mapService.MIN_ZOOM);
        map.addLayer(informationLayer, OnyxMapLayerIndex.INFORMATION_LAYER);
      }
    });

    effect(() => {
      if (!this.behaviors()) return;

      this.toggleBehaviors(this.active() && !this.showPreview());
    });
  }

  public ngAfterViewInit(): void {
    this.updateControlsDirection();

    effect(() => (this.active() ? this.showMap() : this.hideMap()), {
      injector: this.injector,
    });
  }

  public dispatch(event: OnyxMapEvent): void {
    this.queue.add(event);
  }

  protected openStandaloneMap(): void {
    this.mapService.openStandaloneMap();
  }

  protected zoomIn(): void {
    if (!this.map()) return;
    this.map()!.setZoom(this.map()!.getZoom() + this.mapService.ZOOM_STEP);
  }

  protected zoomOut(): void {
    if (!this.map()) return;
    this.map()!.setZoom(this.map()!.getZoom() - this.mapService.ZOOM_STEP);
  }

  @HostListener('document:keydown.escape')
  protected minimizeMap(): void {
    if (this.mode() !== OnyxMapMode.MAXIMIZED) return;
    this.mode.set(OnyxMapMode.MINIMIZED);
  }

  private toggleBehaviors(enable = true): void {
    for (const behavior of this.mapService.BEHAVIORS) {
      enable
        ? this.behaviors()?.enable(behavior)
        : this.behaviors()?.disable(behavior);
    }

    const element = this.map()?.getViewPort().element as HTMLElement;
    element.style.pointerEvents = enable ? 'all' : 'none';
  }

  private showMap(): void {
    const defaultLayers = this.hereMapsService.getDefaultLayers();
    const map = new H.Map(
      this.elementRef.nativeElement,
      defaultLayers.vector.normal.map,
      {
        pixelRatio: window.devicePixelRatio || 1,
        center: this.mapService.CENTER_POINT,
        zoom: this.mapService.INITIAL_ZOOM,
      },
    );
    this.map.set(map);

    this.behaviors.set(
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map)),
    );

    const ui = H.ui.UI.createDefault(map, defaultLayers);
    ui.removeControl('mapsettings');
    ui.removeControl('zoom');
    ui.removeControl('scalebar');
    this.ui.set(ui);

    map.addEventListener('pointermove', (event) => {
      let isActive = false;
      if (event.target instanceof H.map.AbstractMarker) {
        isActive = event.target.getData()?.callback != null;
      }

      this.elementRef.nativeElement.style.cursor = isActive
        ? 'pointer'
        : 'auto';
    });

    map.addEventListener('tap', (event) => {
      const isMarker = event.target instanceof H.map.AbstractMarker;
      if (isMarker) return;

      this.dispatch(new OnyxMapEvent.SelectVehicle({ id: null }));
      this.mapClick.emit();
    });

    fromEventPattern<void>(
      (handler) => {
        const resizeObserver = new ResizeObserver(() => handler());
        resizeObserver.observe(this.elementRef.nativeElement);

        map.addOnDisposeCallback(() => resizeObserver?.disconnect());
        return resizeObserver;
      },
      (_, resizeObserver?: ResizeObserver) => resizeObserver?.disconnect(),
    )
      .pipe(skip(1), debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.resizeMap());

    setTimeout(() =>
      this.queue.events$
        .pipe(takeUntil(this.destroy$), takeUntilDestroyed(this.destroyRef))
        .subscribe((event) => this.processEvent(event)),
    );
  }

  private hideMap(): void {
    this.map()?.dispose();
    this.map.set(null);
    this.behaviors()?.dispose();
    this.behaviors.set(null);
    this.ui()?.dispose();
    this.ui.set(null);

    this.destroy$.next();
    this.markers().clear();
  }

  private processEvent(event: OnyxMapEvent): void {
    switch (event.TYPE) {
      case OnyxMapEventType.ADD_UPDATE_MARKER:
        return this.addUpdateMarker(
          event.id,
          event.type,
          event.coordinates,
          event.callback,
        );
      case OnyxMapEventType.REMOVE_MARKER:
        return this.removeMarker(event.id);
      case OnyxMapEventType.ADD_UPDATE_VEHICLE:
        return this.addUpdateVehicle(
          event.id,
          event.status,
          event.coordinates,
          event.azimuth,
          event.visible,
        );
      case OnyxMapEventType.REMOVE_VEHICLE:
        return this.removeVehicle(event.id);
      case OnyxMapEventType.SELECT_VEHICLE:
        return this.selectVehicle(event.id);
      case OnyxMapEventType.FIT_CONTENT:
        return this.fitContent();
    }
  }

  private addUpdateMarker(
    id: string,
    type: OnyxMapMarkerType,
    coordinates: OnyxCoordinates,
    callback: (() => void) | undefined,
  ): void {
    const createIcon = () => new H.map.Icon(`map/markers/${type}.svg`);

    const geometry: H.geo.IPoint = {
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    };
    const data = { id, type, callback };

    let marker = this.markers().get(id);
    if (marker) {
      marker.setGeometry(geometry);

      const previousData = marker.getData();
      if (!isEqual(previousData, data)) {
        marker.setIcon(createIcon());
        marker.setData(data);
        if (previousData.callback) {
          marker.removeEventListener('tap', previousData.callback);
        }
        if (callback) {
          marker.addEventListener('tap', callback);
        }
      }
      return;
    }

    marker = new H.map.Marker(geometry, {
      icon: createIcon(),
      data,
    });
    if (callback) marker.addEventListener('tap', callback);

    this.map()!.addObject(marker);
    this.markers.update((markers) => new Map([...markers, [id, marker]]));
  }

  private removeMarker(id: string): void {
    const marker = this.markers().get(id);
    if (!marker) return;

    this.map()!.removeObject(marker);
    this.markers.update((markers) => {
      markers.delete(id);
      return new Map(markers);
    });
  }

  private addUpdateVehicle(
    id: string,
    status: OnyxMapVehicleStatus,
    coordinates: OnyxCoordinates,
    azimuth: number,
    visible: boolean,
  ): void {
    azimuth = status === OnyxMapVehicleStatus.TRAILER ? 0 : azimuth;
    const selected = this.selectedVehicleId() === id;

    const createIcon = () => {
      const containerElement = document.createElement('div');
      containerElement.className = `vehicle ${status}`;
      containerElement.setAttribute('vehicle-id', id);

      const markerElement = document.createElement('div');
      markerElement.className = 'marker';
      containerElement.appendChild(markerElement);

      const iconElement = document.createElement('div');
      iconElement.className = 'icon';
      iconElement.style.transform = `translate(-50%, -50%) rotate(${azimuth}deg)`;
      markerElement.appendChild(iconElement);

      return new H.map.DomIcon(containerElement);
    };

    const geometry: H.geo.IPoint = {
      lat: coordinates.latitude,
      lng: coordinates.longitude,
    };
    const data = { id, status, azimuth };

    let marker = this.vehicles().get(id);
    if (marker) {
      marker.setGeometry(geometry);

      if (!isEqual(marker.getData(), data)) {
        marker.setData(data);
        this.updateVehicleIcon(marker, selected);
      }

      if (marker.getVisibility() !== visible) marker.setVisibility(visible);
      return;
    }

    marker = new H.map.DomMarker(geometry, {
      icon: createIcon(),
      data,
      visibility: visible,
    });

    marker.addEventListener('tap', () =>
      this.dispatch(new OnyxMapEvent.SelectVehicle({ id })),
    );

    this.map()!.addObject(marker);
    this.vehicles.update((vehicles) => new Map([...vehicles, [id, marker]]));
  }

  private removeVehicle(id: string): void {
    const marker = this.vehicles().get(id);
    if (!marker) return;

    this.map()!.removeObject(marker);
    this.vehicles.update((vehicles) => {
      vehicles.delete(id);
      return new Map(vehicles);
    });
  }

  private selectVehicle(id: string | null): void {
    const selectedVehicleId = this.selectedVehicleId();
    if (id === selectedVehicleId) return;

    if (selectedVehicleId) {
      const marker = this.vehicles().get(selectedVehicleId);
      if (marker) this.updateVehicleIcon(marker, false);
    }

    if (id != null) {
      const marker = this.vehicles().get(id);
      if (!marker) return;

      this.updateVehicleIcon(marker, true);
    }

    this.selectedVehicleId.set(id);
    this.selectedVehicleIdChange.emit(id);
  }

  private fitContent(): void {
    const map = this.map()!;
    const objects = map.getObjects() as H.map.Marker[];

    if (objects.length === 0) {
      map.setCenter(this.mapService.CENTER_POINT);
      map.setZoom(this.mapService.INITIAL_ZOOM);
      return;
    }

    const content = objects.reduce(
      (rect, object) => {
        const point = object.getGeometry() as H.geo.Point;
        rect.top = Math.max(rect.top, point.lat);
        rect.right = Math.max(rect.right, point.lng);
        rect.bottom = Math.min(rect.bottom, point.lat);
        rect.left = Math.min(rect.left, point.lng);
        return rect;
      },
      { top: -Infinity, right: -Infinity, bottom: +Infinity, left: +Infinity },
    );

    const width = content.right - content.left;
    const height = content.top - content.bottom;
    const horizontalPadding = Math.max(0.3 * width, 0.3);
    const verticalPadding = Math.max(0.15 * height, 0.15);

    const bounds = new H.geo.Rect(
      content.top + verticalPadding,
      content.left - horizontalPadding,
      content.bottom - verticalPadding,
      content.right + horizontalPadding,
    );
    map.getViewModel().setLookAtData({ bounds });
  }

  private updateVehicleIcon(marker: H.map.DomMarker, selected: boolean): void {
    const { id, azimuth, status } = marker.getData();

    const containerElement = this.elementRef.nativeElement.querySelector(
      `.vehicle[vehicle-id="${id}"]`,
    );
    if (!containerElement) return;

    containerElement.className = `vehicle ${status}`;
    containerElement.classList.toggle('selected', selected);

    const iconElement = containerElement.querySelector('.icon')! as HTMLElement;
    iconElement.style.transform = `translate(-50%, -50%) rotate(${azimuth}deg)`;
  }

  private resizeMap(): void {
    this.map()?.getViewPort().resize();
    this.updateControlsDirection();
  }

  private updateControlsDirection(): void {
    const CONTROLS_PADDING = 8;

    const controlsElement = this.controlsElementRef()?.nativeElement;
    if (!controlsElement) return;

    const mapHeight = this.elementRef.nativeElement.offsetHeight;
    const controlsSize =
      Math.max(controlsElement.offsetHeight, controlsElement.offsetWidth) +
      2 * CONTROLS_PADDING;

    this.controlsElementRef()!.nativeElement.classList.toggle(
      'column',
      controlsSize < mapHeight,
    );
  }
}
