<div class="delegation-modal-note">
  <h6>{{ 'labels.note' | translate }}</h6>

  @if (note().content; as content) {
    <div class="note">
      <div class="details f-medium-1">
        <span>{{ 'labels.lastUpdatedAt' | translate }}</span>

        <span>·</span>

        <span>{{ note().updatedAt | onyxDate: 'date-time' }}</span>
      </div>

      <p class="content f-paragraph-regular-3">{{ content }}</p>
    </div>
  } @else {
    <div class="empty">
      <p>{{ 'labels.missingNote' | translate }}</p>

      <onyx-button type="outlined" color="black" (click)="edit()">
        <onyx-icon
          leftIcon
          class="add-icon"
          name="zoom-in"
          [size]="16"
        ></onyx-icon>

        {{ 'buttons.add' | translate }}
      </onyx-button>
    </div>
  }
</div>
