<onyx-modal
  type="right"
  heading="{{ employee.firstName }} {{ employee.lastName }}"
  [avatarUser]="employee"
  [dictionaryCode]="DictionaryCode.EMPLOYEE_ROLE"
  [roles]="employee.roles"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    <onyx-icon-button
      color="blue"
      type="primary"
      size="m"
      [circle]="true"
      (click)="CallHelper.makeCall(this.employee.phone)"
    >
      <onyx-icon name="telephone" [size]="16"></onyx-icon>
    </onyx-icon-button>

    <onyx-icon-button
      color="black"
      type="outlined"
      size="m"
      [circle]="true"
      (click)="editEmployee()"
    >
      <onyx-icon name="edit" [size]="16"></onyx-icon>
    </onyx-icon-button>
  </ng-container>

  <div class="container">
    <div>
      <onyx-information-row [label]="'labels.status' | translate">
        <app-employee-status
          [employee]="employee"
          [displayStatus]="true"
        ></app-employee-status>
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.contactData' | translate }}
      </onyx-information-heading>

      <div class="group">
        <onyx-information-row [label]="'labels.phone' | translate">
          {{ employee.phone | onyxPhone }}
        </onyx-information-row>

        <onyx-information-row [label]="'labels.email' | translate">
          {{ employee.email }}
        </onyx-information-row>
      </div>

      <onyx-information-heading>
        {{ 'labels.activity' | translate }}
      </onyx-information-heading>

      <div class="group">
        <onyx-information-row [label]="'labels.joinDate' | translate">
          @if (employee.joinedAt) {
            {{ employee.joinedAt | onyxDate: 'time-date' }}
          } @else {
            -
          }
        </onyx-information-row>

        <onyx-information-row [label]="'labels.lastActivity' | translate">
          @if (employee.lastActivityAt) {
            {{ employee.lastActivityAt | onyxDate: 'time-date' }}
          } @else {
            -
          }
        </onyx-information-row>
      </div>
    </div>

    <div class="wrapper">
      <onyx-chips
        [chips]="chips()"
        [single]="true"
        [(activeValues)]="group"
        left
      ></onyx-chips>

      @switch (group()[0]) {
        @case ('assignedVehicles') {
          <div class="vehicles">
            @if (employee.status.value !== EmployeeStatus.ARCHIVED) {
              <onyx-button
                class="edit-button"
                color="black"
                type="outlined"
                size="s"
                (click)="assignVehicles()"
              >
                <onyx-icon
                  left
                  class="edit-icon"
                  name="edit"
                  [size]="16"
                ></onyx-icon>

                {{ 'buttons.edit' | translate }}
              </onyx-button>
            }

            <onyx-table
              [data]="assignedVehicles()"
              [columns]="EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS"
              [loading]="loading()"
              tableSize="s"
              [rowSize]="48"
              [notFound]="ASSIGNED_VEHICLES_NOT_FOUND"
              [(pagination)]="pagination"
            ></onyx-table>
          </div>
        }
        @case ('plannedUnavailabilities') {
          <app-unavailabilities [employee]="employee"></app-unavailabilities>
        }
      }
    </div>
  </div>
</onyx-modal>
