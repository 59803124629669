import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FLAGS } from '../../icons/constants';
import { OnyxCustomIconComponent } from '../../icons/onyx-custom-icon/onyx-custom-icon.component';
@Component({
  selector: 'onyx-flag',
  imports: [OnyxCustomIconComponent],
  templateUrl: './onyx-flag.component.html',
  styleUrl: './onyx-flag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxFlagComponent {
  public countryCode = input.required<(typeof FLAGS)[number]>();
  public size = input<'m' | 's'>('s');
}
