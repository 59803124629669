@if (groups()) {
  @if (config.showSearch) {
    <onyx-search
      class="search"
      [(query)]="config.query"
      [placeholder]="config.searchPlaceholder"
      color="white"
      [showFocus]="false"
      size="s"
      (keydown.arrowup)="focusElement(); $event.preventDefault()"
      (keydown.arrowdown)="focusFirstOption(); $event.preventDefault()"
    ></onyx-search>
  }

  @if (config.headerTemplateRef) {
    <ng-container *ngTemplateOutlet="config.headerTemplateRef"></ng-container>
  } @else if (config.header) {
    <p class="header f-regular-3">{{ config.header | translate }}</p>
  }

  <ul
    class="container"
    [ngClass]="{
      multiple: config.multiple,
      checkbox: config.showCheckbox,
      optional: config.optional || config.multiple,
    }"
    cdkListbox
    [cdkListboxMultiple]="config.multiple"
    [cdkListboxNavigationWrapDisabled]="true"
  >
    @if (!isEmpty()) {
      @if (config.multiple && config.showCheckbox) {
        <button
          class="option"
          [ngStyle]="{ 'minHeight.px': config.optionSize }"
          [cdkOption]="null"
          (click)="toggleAll()"
          (keydown.space)="toggleAll()"
          (keydown.enter)="toggleAll()"
          (keydown.arrowup)="focusSearch(); $event.preventDefault()"
        >
          <onyx-checkbox
            [value]="selectedAll() || selectedAny()"
            [indeterminate]="selectedAny() && !selectedAll()"
            [tabindex]="-1"
          ></onyx-checkbox>

          <span>{{ I18N + '.selectAll' | translate }}</span>
        </button>

        <div class="separator"></div>
      }

      @for (
        group of groups();
        track group.options;
        let firstGroup = $first;
        let lastGroup = $last
      ) {
        @if (group.headerTemplateRef) {
          <ng-container
            *ngTemplateOutlet="group.headerTemplateRef"
          ></ng-container>
        } @else if (group.header) {
          <p class="header f-regular-3">{{ group.header | translate }}</p>
        } @else if (!firstGroup) {
          <div class="separator"></div>
        }

        @for (option of group.options; track option.value) {
          <button
            class="option"
            [ngClass]="{
              selected: option.selected,
              disabled: option.disabled,
            }"
            [ngStyle]="{ 'minHeight.px': config.optionSize }"
            [cdkOption]="option.value"
            [cdkOptionDisabled]="option.disabled"
            [onyxTooltip]="option.tooltip"
            [onyxTooltipPositions]="
              option.tooltipPosition ?? [OnyxOverlayPosition.RIGHT]
            "
            [onyxTooltipArrowOffsets]="{ y: -4 }"
            (click)="toggleOption(option.value)"
            (keydown.space)="toggleOption(option.value)"
            (keydown.enter)="toggleOption(option.value)"
            (keydown.arrowup)="
              firstGroup && $first && !config.multiple ? focusSearch() : null
            "
          >
            @if (config.multiple && config.showCheckbox) {
              <onyx-checkbox
                [value]="!!option.selected"
                [disabled]="!!option.disabled"
              ></onyx-checkbox>
            }

            <ng-container
              *ngTemplateOutlet="
                config.optionTemplateRef ?? optionTemplate;
                context: {
                  option,
                  query: config.query(),
                  selected: option.selected,
                  disabled: option.disabled,
                }
              "
            ></ng-container>

            @if (!config.multiple || !config.showCheckbox) {
              <onyx-icon
                class="check"
                [ngClass]="{ hidden: !option.selected }"
                name="check"
                [size]="12"
              ></onyx-icon>
            }
          </button>
        }

        @if (group.footerTemplateRef) {
          <ng-container
            *ngTemplateOutlet="group.footerTemplateRef"
          ></ng-container>
        }
      }
    } @else {
      <div class="empty f-italic">
        {{ config.emptyMessage ?? I18N + '.empty' | translate }}
      </div>
    }

    @if (groups()?.length && config.multiple && !config.showCheckbox) {
      <div class="separator"></div>

      @let disabled = !selectedAny();
      <button
        class="option clear"
        [ngClass]="{ disabled }"
        [ngStyle]="{ 'minHeight.px': config.optionSize }"
        [cdkOption]="null"
        (click)="disabled ? null : clear()"
        (keydown.space)="disabled ? null : clear()"
        (keydown.enter)="disabled ? null : clear()"
      >
        <onyx-icon name="close" [size]="16"></onyx-icon>

        <span>{{ I18N + '.clear' | translate }}</span>
      </button>
    }

    @if (config.showAddOption) {
      @let query = config.query() ?? '';
      @if (query || config.showAddOption !== 'value') {
        <button
          class="add-button"
          [cdkOption]="null"
          (click)="config.addOption?.(query); config.close()"
          (keydown.space)="config.addOption?.(query); config.close()"
          (keydown.enter)="config.addOption?.(query); config.close()"
        >
          {{ config.addOptionMessage ?? I18N + '.add' | translate }}
          @if (query) {
            "{{ query }}"
          }
        </button>
      }
    }
  </ul>

  @if (config.footerTemplateRef) {
    <ng-container *ngTemplateOutlet="config.footerTemplateRef"></ng-container>
  }
} @else {
  <onyx-spinner></onyx-spinner>
}

<ng-template let-option="option" let-query="query" #optionTemplate>
  <span [innerHTML]="option.name | translate | onyxHighlight: query"></span>
</ng-template>
