import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxDatePipe } from '@onyx/angular';

export interface CellRange {
  from: string;
  to: string;
}

@Component({
  selector: 'app-date-range-cell',
  imports: [OnyxDatePipe],
  templateUrl: './date-range-cell.component.html',
  styleUrl: './date-range-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeCellComponent {
  public range = input.required<CellRange | null>();
}
