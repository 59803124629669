import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  OnInit,
  output,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import {
  CellColor,
  ColorHelper,
} from '../../../../common/helpers/color.helper';
import { Fleet } from '../../common/interfaces/fleet';
import { FleetModalWarningComponent } from './fleet-modal-warning/fleet-modal-warning.component';

@Component({
  selector: 'app-fleet-modal-warnings',
  imports: [TranslatePipe, FleetModalWarningComponent],
  templateUrl: './fleet-modal-warnings.component.html',
  styleUrl: './fleet-modal-warnings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalWarningsComponent implements OnInit {
  protected readonly I18N = 'fleet.fleetModal';

  public vehicle = input.required<Fleet>();

  public redirect = output<void>();
  public warnings = output<boolean>();

  protected vehicleExpiryStatuses = computed(() => {
    const vehicle = this.vehicle();

    const expiryStatuses = [
      {
        key: 'technicalInspection',
        expiryDate:
          vehicle.registrationCertificate.technicalInspectionExpiryDate,
      },
      {
        key: 'ac',
        expiryDate: vehicle.comprehensiveInsurance?.expiryDate,
      },
      {
        key: 'oc',
        expiryDate: vehicle.thirdPartyLiabilityInsurance?.expiryDate,
      },
      {
        key: 'tachoLegalize',
        expiryDate: vehicle.tachograph?.nextLegalizationDate,
      },
    ] as const;

    const statuses = sortBy(expiryStatuses, ['expiryDate'])
      .map((status) => {
        const expiryStatus = this.getExpiryStatus(status.expiryDate);
        return expiryStatus ? { ...status, ...expiryStatus } : null;
      })
      .filter((status) => status != null);

    return {
      error: statuses.filter((status) => status.color === 'red'),
      warning: statuses.filter((status) => status.color === 'yellow'),
    };
  });

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    effect(
      () => {
        const { error, warning } = this.vehicleExpiryStatuses();
        this.warnings.emit(error.length > 0 || warning.length > 0);
      },
      { injector: this.injector },
    );
  }

  private getExpiryStatus(expiryDate: string | null | undefined): {
    color: CellColor;
  } | null {
    if (!expiryDate) return null;

    return {
      color: ColorHelper.getCellColor(expiryDate),
    };
  }
}
