import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ONYX_PAGINATION,
  OnyxBadgeComponent,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxOption,
  OnyxToastService,
} from '@onyx/angular';
import { intersectionBy, union } from 'lodash';
import { map, Subject, take } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { EmployeeCategory } from '../../../../management-panel/employees/common/enums/employee-category';
import { EmployeeRole } from '../../../../management-panel/employees/common/enums/employee-role';
import { EmployeeStatus } from '../../../../management-panel/employees/common/enums/employee-status';
import { Employee } from '../../../../management-panel/employees/common/interfaces/employee';
import { EmployeesService } from '../../../../management-panel/employees/common/services/employees.service';
import { Fleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

@Component({
  selector: 'app-fleet-assign-employees-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxBadgeComponent,
    OnyxIconComponent,
    FleetIdentifierPipe,
  ],
  templateUrl: './fleet-assign-employees-modal.component.html',
  styleUrl: './fleet-assign-employees-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetAssignEmployeesModalComponent implements OnInit {
  protected readonly I18N = 'fleet.assignEmployeesModal';

  protected form = this.buildForm();
  protected loading = signal(false);
  protected employeesOptions = signal<OnyxOption<string>[] | null>(null);
  protected close$ = new Subject<void>();

  protected isSingle = computed(() => this.vehicles.length === 1);

  constructor(
    @Inject(DIALOG_DATA) protected vehicles: Fleet[],
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private employeesService: EmployeesService,
    private toastService: OnyxToastService,
    private fleetService: FleetService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.employeesService
      .listEmployees({
        category: EmployeeCategory.EMPLOYED,
        statuses: [EmployeeStatus.ACTIVE],
        roles: Object.values(EmployeeRole),
        ...ONYX_PAGINATION,
      })
      .pipe(
        take(1),
        map((employees) =>
          employees.items.map((employee: Employee) => ({
            name: `${employee.firstName} ${employee.lastName}`,
            value: employee.uuid,
          })),
        ),
      )
      .subscribe((options) => this.employeesOptions.set(options));

    if (this.vehicles.length > 1) {
      const allEmployees = this.vehicles
        .map((vehicle) => vehicle.assignedEmployees)
        .flat();

      const commonEmployees = intersectionBy(allEmployees, 'uuid');
      const selectedEmployees =
        commonEmployees.length > 0 ? commonEmployees : union(allEmployees);

      this.form.controls.employees.setValue(
        selectedEmployees.map((employee) => employee!.uuid),
      );

      this.toastService.showInformation(
        commonEmployees.length > 0
          ? `${this.I18N}.commonEmployess`
          : `${this.I18N}.allEmployees`,
      );
    } else if (this.vehicles[0]?.assignedEmployees) {
      const uuid = this.vehicles[0]?.assignedEmployees[0]?.uuid;
      this.form.controls.employees.setValue(uuid ? [uuid] : []);
    }
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const action$ = this.isSingle()
      ? this.fleetService.assignEmployees(form, this.vehicles[0].uuid)
      : this.fleetService.batchFleet(
          this.vehicles.map((vehicle) => vehicle.uuid),
          { assignedEmployees: form.employees },
        );

    this.loading.set(true);
    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            this.translateService.instant(`${this.I18N}.assignedEmployees`, {
              value: form.employees.length,
            }),
          );
          this.close$.next();
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      employees: this.fb.control<string[]>([]),
    });
  }
}
