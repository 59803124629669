<section>
  <div class="heading-container" [ngClass]="{ center: !vehicle() }">
    <div class="heading">
      @if (vehicle(); as vehicle) {
        <app-fleet-modal-vehicle-heading
          class="details"
          [vehicle]="vehicle"
          [isVehicle]="isVehicle()"
        ></app-fleet-modal-vehicle-heading>

        @let generalInformation = vehicle.generalInformation;
        <h3>{{ generalInformation | fleetIdentifier }}</h3>
      } @else {
        <h4>
          {{ I18N + (!isVehicle() ? '.noTrailer' : '.noVehicle') | translate }}
        </h4>
      }
    </div>

    <onyx-button
      type="outlined"
      color="black"
      size="s"
      (click)="assignVehicle()"
    >
      {{ I18N + (vehicle() ? '.switchVehicle' : '.assignVehicle') | translate }}
    </onyx-button>
  </div>

  @if (vehicle(); as assignedVehicle) {
    <div class="parameters">
      @let generalInformation = assignedVehicle.generalInformation;
      @let isAutoPlanning = generalInformation.planningMode === 'auto';
      <onyx-icon-box
        [color]="
          isAutoPlanning ? OnyxIconBoxColor.BLUE : OnyxIconBoxColor.VIOLET
        "
        [label]="'labels.planning' | translate"
        [hasColoredBackground]="true"
      >
        <onyx-icon
          [name]="isAutoPlanning ? 'refresh' : 'manual'"
          [size]="16"
        ></onyx-icon>

        {{
          DictionaryCode.VEHICLE_PLANNING_MODE +
            '.' +
            generalInformation.planningMode | translate
        }}
      </onyx-icon-box>

      <onyx-icon-box
        [color]="OnyxIconBoxColor.GREEN"
        [label]="'labels.status' | translate"
        [hasColoredBackground]="true"
      >
        <onyx-icon name="check-fill" [size]="16"></onyx-icon>

        {{
          DictionaryCode.VEHICLE_STATE + '.' + generalInformation.state
            | translate
        }}
      </onyx-icon-box>

      <onyx-icon-box
        [color]="OnyxIconBoxColor.VIOLET"
        [label]="'labels.mileage' | translate"
      >
        <onyx-icon name="road" [size]="16"></onyx-icon>

        N/A
      </onyx-icon-box>

      <onyx-icon-box
        [color]="OnyxIconBoxColor.BLACK"
        [label]="'labels.telematics' | translate"
      >
        <onyx-icon name="menu-integrations" [size]="16"></onyx-icon>

        N/A
      </onyx-icon-box>

      <onyx-icon-box
        [color]="OnyxIconBoxColor.GRAY"
        [label]="'labels.manufactureYear' | translate"
      >
        <onyx-icon name="calendar" [size]="16"></onyx-icon>

        {{ generalInformation.manufactureYear }}
      </onyx-icon-box>

      @if (isVehicle()) {
        @let driver = assignedVehicle.drivers!.primaryDriver?.driverData;
        @if (driver) {
          <onyx-icon-box
            [color]="OnyxIconBoxColor.BLACK"
            [label]="'labels.driver' | translate"
          >
            <onyx-icon name="user" [size]="16"></onyx-icon>

            <span>{{ driver?.firstName }} {{ driver?.lastName }}</span>
          </onyx-icon-box>
        }

        <!-- TEMP: Replace when orders ready -->
        <onyx-icon-box [color]="OnyxIconBoxColor.GREEN" label="N/A">
          <onyx-icon name="vehicle-ride" [size]="16"></onyx-icon>
        </onyx-icon-box>

        <onyx-icon-box
          [color]="OnyxIconBoxColor.ORANGE"
          [label]="'labels.fuel' | translate"
        >
          <onyx-icon name="car-wash" [size]="16"></onyx-icon>

          {{
            DictionaryCode.FUEL_TYPE +
              '.' +
              assignedVehicle.fuelTankCapacity?.fuelType | translate
          }}
        </onyx-icon-box>
      }

      <app-fleet-modal-parameters
        [vehicle]="assignedVehicle"
      ></app-fleet-modal-parameters>
    </div>
  }
</section>
