<div class="delegation-modal-vehicles">
  <h6>{{ 'labels.vehicles' | translate }}</h6>

  @if (vehicle() || trailer()) {
    <div class="vehicles" [ngClass]="{ set: !!trailer() }">
      <app-delegation-modal-vehicle-item
        [vehicle]="vehicle()!"
      ></app-delegation-modal-vehicle-item>

      @if (trailer(); as trailer) {
        <app-delegation-modal-vehicle-item
          [vehicle]="trailer"
        ></app-delegation-modal-vehicle-item>
      }
    </div>
  } @else {
    <div class="empty">
      <div class="icon">
        <onyx-icon name="vehicle-disconnected-empty" [size]="16"></onyx-icon>
      </div>

      <div class="content f-medium-1">
        <p>{{ I18N + '.noVehicle' | translate }}</p>

        <onyx-button size="s">{{ 'buttons.assign' | translate }}</onyx-button>
      </div>
    </div>
  }
</div>
