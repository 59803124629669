import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxModalService, OnyxToastService } from '@onyx/angular';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { DriverModalComponent } from '../../driver-modal/driver-modal.component';
import { DriversRoute } from '../../drivers.routes';
import { Driver } from '../interfaces/driver';
import { DriversService } from '../services/drivers.service';

export const driverCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const driversService = inject(DriversService);
  const modalService = inject(OnyxModalService);
  const router = inject(Router);
  const toastService = inject(OnyxToastService);

  const uuid = route.paramMap.get('uuid')!;

  driversService.getDriver(uuid).subscribe({
    next: (driver) => modalService.open<Driver>(DriverModalComponent, driver),
    error: (error) =>
      ValidationHelper.handleUnexpectedError(error, toastService),
  });

  const urlTree = router.parseUrl(DriversRoute.DRIVERS_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};
