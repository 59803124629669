import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated } from '@onyx/angular';
import { map, Observable, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../../common/services/api.service';
import { BaseFormDto } from '../../base-form/base-form.component';
import { Base } from '../interfaces/base';

@Injectable({
  providedIn: 'root',
})
export class BasesService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listBases(): Observable<Base[]> {
    return this.get<OnyxPaginated<Base>>('/bases', {
      params: {
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      },
    }).pipe(map((paginated) => paginated.items));
  }

  public getBase(uuid: string): Observable<Base> {
    return this.get(`/bases/${uuid}`);
  }

  public addBase(dto: BaseFormDto): Observable<void> {
    return this.post<void>('/bases', dto).pipe(tap(() => this._reload$.next()));
  }

  public editBase(uuid: string, dto: BaseFormDto): Observable<void> {
    return this.put<void>(`/bases/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deleteBase(uuid: string): Observable<void> {
    return this.delete<void>(`/bases/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }
}
