import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DictionaryCode } from '../../../enums/dictionary-code';

@Component({
  selector: 'app-tachograph-cell',
  imports: [TranslatePipe],
  templateUrl: './make-and-model-cell.component.html',
  styleUrl: './make-and-model-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeAndModelCellComponent {
  public make = input.required<string | null>();
  public model = input.required<string | null>();
  public makeDictionaryCode = input<DictionaryCode>();
  public modelDictionaryCode = input<DictionaryCode>();
}
