import { TranslateService } from '@ngx-translate/core';
import { OnyxDatePipe } from '@onyx/angular';
import { DateTime } from 'luxon';

export const getDateSuggestions = (
  translateService: TranslateService,
  datePipe: OnyxDatePipe,
  options?: { days?: number; future?: boolean },
) => {
  const { days = 2, future = true } = options ?? {};

  const today = DateTime.now();
  const tomorrow = today.plus({ days: 1 }).toISODate();
  const yesterday = today.minus({ days: 1 }).toISODate();

  return [
    {
      name: `${datePipe.transform(today.toISODate(), 'short-date-dot')} (${translateService.instant('labels.today')})`,
      value: today.toISODate(),
    },
    ...(future
      ? [
          {
            name: `${datePipe.transform(tomorrow, 'short-date-dot')} (${translateService.instant('labels.tommorow')})`,
            value: tomorrow,
          },
        ]
      : [
          {
            name: `${datePipe.transform(yesterday, 'short-date-dot')} (${translateService.instant('labels.yesterday')})`,
            value: yesterday,
          },
        ]),
    ...Array.from({ length: days - 2 }, (_, i) => {
      const date = future
        ? today.plus({ days: i + 2 })
        : today.minus({ days: i + 2 });
      return {
        name: `${datePipe.transform(date.toISODate(), 'short-date-dot')}`,
        value: date.toISODate(),
      };
    }),
  ].slice(0, days);
};
