import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxFilterPipe,
  OnyxOption,
  OnyxPaginated,
  OnyxPagination,
} from '@onyx/angular';
import { isString } from 'lodash';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { PickDeep } from 'type-fest';
import { FleetIdentifierPipe } from '../../../../common/components/pipes/fleet-identifier.pipe';
import { BatchFileUpload } from '../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../common/services/api.service';
import { StorageService } from '../../../../common/services/storage.service';
import { FleetFormDto } from '../../fleet-form/fleet-form.component';
import { FleetAssignDriverModalForm } from '../components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import { FleetCategory } from '../enums/fleet-category';
import { FleetState } from '../enums/fleet-state';
import { Fleet } from '../interfaces/fleet';

@Injectable({
  providedIn: 'root',
})
export class FleetService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private filterPipe: OnyxFilterPipe,
  ) {
    super(http);
  }

  public listFleet(
    params: {
      state?: FleetState;
      category?: FleetCategory[];
      showSetsOnly?: boolean;
      showAssignedOnly?: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Fleet>> {
    return this.get('/fleet', {
      params: {
        ...(params.state && { state: params.state }),
        ...(params.category &&
          params.category.length > 0 && { 'category[]': params.category }),
        ...(params.showSetsOnly != null && {
          showSetsOnly: params.showSetsOnly,
        }),
        ...(params.showAssignedOnly != null && {
          showAssignedOnly: params.showAssignedOnly,
        }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchFleet(
    query: string,
    params?: Omit<
      Parameters<FleetService['listFleet']>[0],
      keyof OnyxPagination
    >,
    limit = 10,
  ): Observable<OnyxOption<string>[]> {
    return this.listFleet({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) =>
        response.items.map((fleet) => ({
          name: this.fleetIdentifierPipe.transform(fleet),
          value: fleet.uuid,
        })),
      ),
      map((options) =>
        this.filterPipe.transform(options, query, ['name']).slice(0, limit),
      ),
    );
  }

  public getFleet(category: FleetCategory, uuid: string): Observable<Fleet> {
    const path = this.getPath(category);
    return this.get(`/${path}/${uuid}`);
  }

  public addFleet(dto: FleetFormDto): Observable<Fleet> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<Fleet>(`/${this.getPath(dto)}`, dto)),
      tap(() => this._reload$.next()),
    );
  }

  public editFleet(uuid: string, dto: FleetFormDto): Observable<Fleet> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<Fleet>(`/${this.getPath(dto)}/${uuid}`, dto)),
      tap(() => this._reload$.next()),
    );
  }

  public deleteFleet(dto: Fleet): Observable<void> {
    return this.delete<void>(`/${this.getPath(dto)}/${dto.uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public assignDrivers(
    form: FleetAssignDriverModalForm,
    uuid: string,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${uuid}/assign-drivers`, form).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unassignDrivers(
    driverUuid: string,
    uuid: string,
    reload = true,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${uuid}/unassign-driver`, {
      driverUuid,
    }).pipe(
      tap(() => {
        if (reload) this._reload$.next();
      }),
    );
  }

  public assignEmployees(
    employees: { employees: string[] },
    uuid: string,
  ): Observable<void> {
    return this.put<void>(
      `/vehicles/${uuid}/assigned-employees`,
      employees,
    ).pipe(tap(() => this._reload$.next()));
  }

  public batchFleet(
    vehiclesUuid: string[],
    setFields?: {
      isForSale?: boolean;
      state?: FleetState;
      assignedEmployees?: string[];
    },
    actions: string[] = [],
  ): Observable<void> {
    return this.put<void>('/fleet/batch', {
      vehiclesUuid,
      setFields,
      actions,
    }).pipe(tap(() => this._reload$.next()));
  }

  public assignTrailer(
    vehicleUuid: string,
    trailerUuid: string | null,
    reload = true,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${vehicleUuid}/trailer`, {
      trailerUuid,
    }).pipe(
      tap(() => {
        if (reload) this._reload$.next();
      }),
    );
  }

  private getPath(
    dto: FleetCategory | PickDeep<Fleet, 'generalInformation.category'>,
  ): string {
    const category = isString(dto) ? dto : dto.generalInformation.category;
    return category.includes('trailer') ? 'trailers' : 'vehicles';
  }

  private uploadFiles(dto: FleetFormDto): Observable<FleetFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        {
          path: 'registrationCertificate.scan',
          files: dto.registrationCertificate.scan,
        },
        ...(dto.co2Emissions
          ? [
              {
                path: 'co2Emissions.emissionCertificate',
                files: dto.co2Emissions.emissionCertificate,
              },
            ]
          : []),
        {
          path: 'thirdPartyLiabilityInsurance.contractScan',
          files: dto.thirdPartyLiabilityInsurance.contractScan,
        },
        ...(dto.comprehensiveInsurance
          ? [
              {
                path: 'comprehensiveInsurance.contractScan',
                files: dto.comprehensiveInsurance.contractScan,
              },
            ]
          : []),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }
}
