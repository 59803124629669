import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { countBy, differenceBy, orderBy, uniqBy } from 'lodash';
import { Subject } from 'rxjs';
import { OnyxFormMode } from '../../../../enums';
import { FormHelper } from '../../../../helpers';
import { I18N_NAMESPACE } from '../../../../internal/constants';
import { OnyxFilterPipe, OnyxHighlightPipe } from '../../../../pipes';
import { OnyxToastService } from '../../../../services';
import { OnyxUserSettingDto } from '../../../../services/interfaces';
import { OnyxUserSettingsService } from '../../../../services/onyx-user-settings.service';
import { OnyxButtonComponent, OnyxIconButtonComponent } from '../../../buttons';
import { OnyxIconComponent } from '../../../icons';
import { OnyxModalComponent } from '../../../modal';
import { OnyxSearchComponent } from '../../../search';
import { OnyxTextFieldComponent } from '../../../text-field';
import { OnyxToastType } from '../../../toast';
import { OnyxTableColumn, OnyxTableView } from '../../interfaces';

export type OnyxTableViewModalData = {
  scope: string;
  columns: OnyxTableColumn<any>[];
  defaultView: OnyxTableView;
} & (
  | {
      mode: OnyxFormMode.ADD;
      value: string[];
    }
  | ({
      mode: OnyxFormMode.EDIT;
    } & OnyxTableView)
);

@Component({
  selector: 'onyx-table-view-modal',
  imports: [
    OnyxModalComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxTextFieldComponent,
    OnyxSearchComponent,
    OnyxButtonComponent,
    OnyxFilterPipe,
    OnyxHighlightPipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    DragDropModule,
    NgClass,
    NgTemplateOutlet,
  ],
  templateUrl: './onyx-table-view-modal.component.html',
  styleUrl: './onyx-table-view-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTableViewModalComponent {
  protected readonly I18N = `${I18N_NAMESPACE}.table.viewModal`;

  protected readonly OnyxFormMode = OnyxFormMode;

  protected query = signal('');
  protected visibleColumns = signal<OnyxTableColumn<any>[]>([]);
  protected hiddenColumns = computed(() =>
    orderBy(
      differenceBy(this.data.columns, this.visibleColumns(), 'id'),
      'name',
    ),
  );

  protected form = this.buildForm();
  protected loading = signal<'delete' | 'submit' | null>(null);
  protected close$ = new Subject<OnyxTableView | null>();

  private requiredColumnsCount = computed(
    () => countBy(this.visibleColumns(), 'required')['true'],
  );

  constructor(
    @Inject(DIALOG_DATA) protected data: OnyxTableViewModalData,
    protected dialogRef: DialogRef<OnyxTableView>,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private userSettingsService: OnyxUserSettingsService,
  ) {
    const viewColumns = data.columns.filter((column) =>
      data.value.includes(column.id),
    );
    this.visibleColumns.set(
      orderBy(viewColumns, (column) => data.value.indexOf(column.id)),
    );

    effect(() => {
      this.form.controls.value.setValue(
        this.visibleColumns().map((column) => column.id),
      );
    });

    if (data.mode === OnyxFormMode.EDIT) {
      this.form.controls.name.setValue(data.name);
    }
  }

  protected dropPredicate(index: number): boolean {
    return index > this.requiredColumnsCount() - 1;
  }

  protected drop(event: CdkDragDrop<unknown>): void {
    this.visibleColumns.update((visibleColumns) => {
      const columns = [...visibleColumns];
      moveItemInArray(columns, event.previousIndex, event.currentIndex);
      return columns;
    });
  }

  protected hideColumn(id: string): void {
    this.visibleColumns.update((columns) =>
      columns.filter((column) => column.id !== id),
    );
    this.query.set('');
  }

  protected showColumn(column: OnyxTableColumn<any>): void {
    this.visibleColumns.update((columns) => uniqBy([...columns, column], 'id'));
    this.query.set('');
  }

  protected delete(): void {
    if (this.data.mode !== OnyxFormMode.EDIT) return;

    this.loading.set('delete');
    this.userSettingsService
      .deleteSetting(this.data.scope, this.data.uuid)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.deleteSuccess`);
          this.close$.next(null);
        },
        error: (error: HttpErrorResponse) => {
          this.toastService.showCustom(OnyxToastType.SERVER_ERROR);
          if (error.status !== HttpStatusCode.InternalServerError) {
            throw error;
          }
        },
      })
      .add(() => this.loading.set(null));
  }

  protected restoreDefaults(): void {
    this.visibleColumns.set(
      this.data.defaultView.value.map(
        (id) => this.data.columns.find((c) => c.id === id)!,
      ),
    );
    this.query.set('');
  }

  protected cancel(): void {
    this.close$.next(null);
  }

  protected submit(): void {
    if (this.form.invalid) {
      FormHelper.submit(this.form);
      this.toastService.showCustom(OnyxToastType.INVALID_DATA);
      return;
    }

    const form = this.form.getRawValue();
    const dto: OnyxUserSettingDto = {
      name: form.name,
      value: JSON.stringify(form.value),
    };

    const { scope } = this.data;
    const action$ =
      this.data.mode === OnyxFormMode.ADD
        ? this.userSettingsService.addSetting(scope, dto)
        : this.userSettingsService.editSetting(scope, this.data.uuid, dto);

    this.loading.set('submit');
    action$
      .subscribe({
        next: (setting) => {
          this.toastService.showSuccess(
            `${this.I18N}.${this.data.mode}Success`,
          );
          this.close$.next({
            uuid: setting.uuid,
            name: setting.name,
            value: JSON.parse(setting.value),
          });
        },
        error: (error: HttpErrorResponse) => {
          this.toastService.showCustom(OnyxToastType.SERVER_ERROR);
          if (error.status !== HttpStatusCode.InternalServerError) {
            throw error;
          }
        },
      })
      .add(() => this.loading.set(null));
  }

  private buildForm() {
    return this.fb.group({
      name: this.fb.control('', [Validators.required]),
      value: this.fb.control<string[]>([], [Validators.required]),
    });
  }
}
