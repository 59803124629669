import { BaseForm } from '../../base-form/base-form.component';
import { Base } from '../interfaces/base';

export class BaseHelper {
  public static fromDto(dto: Base): BaseForm {
    return {
      ...dto,
      assignedDrivers: {
        selectedDrivers: dto.assignedDrivers,
        drivers: dto.assignedDrivers.map((driver) => ({
          uuid: driver.uuid,
          driver: driver,
        })),
      },
      assignedVehicles: {
        selectedVehicles: dto.assignedVehicles,
        vehicles: dto.assignedVehicles.map((vehicle) => ({
          uuid: vehicle.uuid,
          vehicle: vehicle,
        })),
      },
      baseInformation: {
        address: dto.address,
        name: dto.name,
      },
      contact: {
        phone: dto.phone,
      },
    };
  }
}
