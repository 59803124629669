<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="address" (click)="focus()">
  @if (label(); as label) {
    <onyx-input-label
      [label]="label"
      [hint]="hint()"
      [disabled]="disabled()"
      [optional]="isOptional()"
    ></onyx-input-label>
  }

  <div
    class="input"
    [ngClass]="{
      active: value(),
      disabled: disabled(),
      invalid: isInvalid(),
    }"
    [onyxDropdownOptions]="addresses$"
    [onyxDropdownValues]="[value()]"
    [(onyxDropdownQuery)]="query"
    [onyxDropdownInheritWidth]="true"
    [onyxDropdownDisabled]="disabled()"
    [onyxDropdownEmptyMessage]="notFoundMessage() ?? I18N + '.noResults'"
    [onyxDropdownHeaderTemplateRef]="dropdownHeaderTemplate"
    [onyxDropdownOptionTemplateRef]="optionTemplate"
    [(onyxDropdownAttached)]="expanded"
    (onyxDropdownAttachedChange)="$event ? null : restoreOnFocusout()"
    (onyxDropdownValueChange)="changeValue($event)"
    (onyxDropdownFocus)="focus()"
  >
    <input
      [value]="value()?.label"
      [placeholder]="placeholder() | translate"
      [disabled]="disabled()"
      (input)="changeQuery($event)"
      #inputElement
    />

    <div class="actions">
      <div class="hidden">
        @if (value() && !disabled()) {
          <onyx-clear-button
            (click)="clearValue(); $event.stopPropagation()"
          ></onyx-clear-button>
        }
      </div>

      @if (showPicker() && types().includes(OnyxAddressType.CUSTOM)) {
        <onyx-icon-button
          type="transparent"
          color="black"
          size="s"
          [disabled]="disabled()"
          [tabindex]="-1"
          (click)="openPicker(); $event.stopPropagation()"
        >
          <onyx-icon name="location" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
    </div>
  </div>

  @if (formControl()) {
    <onyx-form-control-error
      [formControls]="[formControl()!]"
    ></onyx-form-control-error>
  }
</div>

@if (showApartment()) {
  <onyx-text-field
    [label]="I18N + '.apartment' | translate"
    size="m"
    [width]="apartmentWidth()"
    [(value)]="apartmentNumber"
    [forceOptional]="true"
    [disabled]="disabled()"
    [showErrors]="false"
  ></onyx-text-field>
}

<ng-template let-option="option" let-query="query" #optionTemplate>
  <div class="option">
    @switch (option.value.type) {
      @case (OnyxAddressType.POINT_OF_INTEREST) {
        <onyx-icon
          class="point-of-interest"
          name="menu-poi"
          [size]="16"
        ></onyx-icon>
      }
      @default {
        <onyx-icon name="location" [size]="16"></onyx-icon>
      }
    }

    @let poiName = option.value.poiName;
    @let label = (poiName ? poiName + ' ' : '') + option.name;
    <p
      [onyxTooltip]="label"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
      [onyxTooltipEnabled]="
        OverflowHelper.enableOverflowTooltip.bind(this, labelElement)
      "
      #labelElement
    >
      @if (poiName) {
        <span
          class="f-medium-2"
          [innerHTML]="poiName | onyxHighlight: query"
        ></span>
        {{ ' ' }}
      }
      <span [innerHTML]="option.name | onyxHighlight: query"></span>
    </p>
  </div>
</ng-template>

<ng-template #dropdownHeaderTemplate>
  <p class="header f-label-1">
    {{ I18N + (query() ? '.suggestions' : '.lastSearches') | translate }}
  </p>
</ng-template>
