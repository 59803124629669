import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TABLE_TOOLTIP_DELAY,
  OnyxFlagComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { DictionaryCode } from '../../../enums/dictionary-code';

@Component({
  selector: 'app-country-cell',
  imports: [
    OnyxFlagComponent,
    TranslatePipe,
    UpperCasePipe,
    OnyxTooltipDirective,
  ],
  templateUrl: './country-cell.component.html',
  styleUrl: './country-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryCellComponent {
  protected readonly TOOLTIP_DELAY = ONYX_TABLE_TOOLTIP_DELAY;

  protected readonly DictionaryCode = DictionaryCode;

  public countryCode = input.required<string | null>();
  public short = input(false);
  public onlyFlag = input(false);
}
