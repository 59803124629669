import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { map } from 'rxjs';
import { OnyxStorageService } from '../../../services';
import { OnyxIconComponent } from '../../icons';
import { OnyxAvatarCompany, OnyxAvatarUser } from '../interfaces';

@Component({
  selector: 'onyx-avatar',
  imports: [NgStyle, OnyxIconComponent, NgClass, AsyncPipe],
  templateUrl: './onyx-avatar.component.html',
  styleUrl: './onyx-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxAvatarComponent {
  private readonly COLORS = [
    'green',
    'yellow',
    'red',
    'violet',
    'orange',
    'pink',
  ] as const;

  public type = input.required<'user' | 'company'>();
  public size = input.required<'s' | 'm' | 'l'>();
  public user = input<OnyxAvatarUser | null>();
  public company = input<OnyxAvatarCompany | null>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  public click = output<never>();

  protected color = computed(() => {
    if (this.company()) return;

    const user = this.user();
    if (!user?.firstName || !user?.lastName) return 'disabled';

    const fullName = `${user.firstName}${user.lastName}`;
    const hashedName = this.computeHash(fullName.toLowerCase());
    return this.COLORS[(hashedName % this.COLORS.length) as number];
  });

  protected background$ = computed(() => {
    const backgroundUrl =
      this.type() === 'user' ? this.user()?.avatar : this.company()?.logo;
    if (!backgroundUrl) return;

    return this.storageService.getFile(backgroundUrl).pipe(
      map((file) => URL.createObjectURL(file)),
      map((url) => `center / cover url(${url}), #fff`),
    );
  });

  protected initials = computed(() => {
    const user = this.user();
    if (!user?.firstName || !user?.lastName) return;

    const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
    return initials.toUpperCase();
  });

  protected showHover = computed(() => this.click['listeners'] != null);

  constructor(private storageService: OnyxStorageService) {}

  private computeHash(string: string): number {
    let hash = 0;
    if (string.length === 0) return hash;

    for (let i = 0; i < string.length; ++i) {
      const char = string.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return hash >>> 0;
  }
}
