@if (loading()) {
  <onyx-spinner color="blue" [size]="24"></onyx-spinner>
} @else {
  <section>
    <div class="heading">
      <h4>{{ 'labels.drivers' | translate }}</h4>

      <onyx-button
        type="outlined"
        color="black"
        size="s"
        (click)="assignDrivers()"
      >
        {{ 'buttons.assign' | translate }}
      </onyx-button>
    </div>

    <div class="crew-items">
      @for (driver of drivers(); track driver.uuid) {
        <app-fleet-modal-crew-item
          [driver]="driver"
        ></app-fleet-modal-crew-item>

        @if (!$last) {
          <div class="separator"></div>
        }
      }
    </div>

    <div class="heading">
      <h4>{{ 'labels.employees' | translate }}</h4>

      <onyx-button
        type="outlined"
        color="black"
        size="s"
        (click)="assignEmployees()"
      >
        {{ 'buttons.assign' | translate }}
      </onyx-button>
    </div>

    <div class="crew-items">
      @for (employee of employees(); track employee.uuid) {
        <app-fleet-modal-crew-item
          [employee]="employee"
        ></app-fleet-modal-crew-item>

        @if (!$last) {
          <div class="separator"></div>
        }
      }
    </div>
  </section>
}
