import { DecimalPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { round } from 'lodash';

@Component({
  selector: 'app-unit-cell',
  imports: [TranslatePipe, DecimalPipe, NgClass],
  templateUrl: './unit-cell.component.html',
  styleUrl: './unit-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitCellComponent {
  public value = input.required<number | null>();
  public unit = input.required<string | null>();
  public decimalPlaces = input(0);

  protected decimalValue = computed(() =>
    this.value()
      ? round(
          this.value()! / Math.pow(10, this.decimalPlaces()),
          this.decimalPlaces(),
        )
      : null,
  );
  protected pipeFormat = computed(() => `1.0-${this.decimalPlaces()}`);
}
