import { OnyxChip } from '@onyx/angular';
import { EmployeeStatistics } from '../../../../../common/interfaces/statistics/employee-statistics';

export const EMPLOYEE_MODAL_CHIPS: OnyxChip<keyof EmployeeStatistics>[] = [
  { name: 'labels.assignedVehicles', value: 'assignedVehicles' },
  {
    name: 'unavailabilities.unavailabilities',
    value: 'plannedUnavailabilities',
  },
];
