import { OnyxAddressType, OnyxPaginated } from '@onyx/angular';
import { uniqueId } from 'lodash';
import { GoodsSizeType } from '../../../../common/enums/goods-size-type';
import { FleetCategory } from '../../../fleet/common/enums/fleet-category';
import { FleetState } from '../../../fleet/common/enums/fleet-state';
import { VehiclePlanningMode } from '../../../fleet/common/enums/vehicle-planning-mode';
import { SimplifiedFleet } from '../../../fleet/common/interfaces/fleet';
import { EmployeeRole } from '../../../management-panel/employees/common/enums/employee-role';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderRateType } from '../enums/order-rate-type';
import { OrderSemiTrailerSize } from '../enums/order-semi-trailer-size';
import { OrderStatus } from '../enums/order-status';
import { OrderTimeWindowType } from '../enums/order-time-window-type';
import { Order } from '../interfaces/order';

export const ORDER_MOCKS: OnyxPaginated<Order> = {
  items: [
    {
      uuid: uniqueId('order'),
      id: 'OR-0001-11-24-SAC',
      status: {
        value: OrderStatus.VEHICLE_SEARCH,
        color: 'violet',
      },
      distance: 4000,
      distanceTime: 1000000,
      engineProposalData: null,
      outsourcingData: null,
      realizationData: null,

      price: {
        value: 6124054,
        currency: 'pln',
        date: null,
      },
      author: {
        uuid: '111',
        firstName: 'Donald',
        lastName: 'Trump',
        avatar: undefined,
      },
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: undefined,
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: undefined,
        },
      ],
      routeCountries: ['pl', 'fi'],
      manualRoute: {
        author: {
          uuid: '124',
          firstName: 'Andrzej',
          lastName: 'Kowalski',
          avatar: undefined,
        },
        profitabilityPercentage: 105,
        isOnTime: true,
        createdAt: '2024-05-05T18:06:00',
      },
      assignedData: {
        emptyDistance: 50,
        emptyDistanceTime: 5000,
        vehicle: {
          uuid: '71c98e0e-18aa-4a9b-8507-297c5195782e',
          generalInformation: {
            category: FleetCategory.SEMI_TRUCK,
            type: null,
            registrationNumber: 'GSZ 3AU7',
            vinNumber: '2LMDU68C27BJ83210',
            manufactureYear: 2015,
            firstRegistrationDate: '2024-11-12',
            make: 'Hyundai',
            model: 'Forentern',
            sideNumber: '099',
            maxSpeed: 15,
            initialMileage: null,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
            assignedBase: null,
          },
          fuelTankCapacity: {
            fuelType: 'diesel',
            mainTank: 100000,
            adBlueTank: null,
            additionalTank: 5000,
            generatorFuelTank: null,
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: true,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        trailer: {
          uuid: '813bdd69-43e2-4f53-aa3c-c390fbb46203',
          generalInformation: {
            assignedBase: null,
            category: FleetCategory.SEMI_TRAILER,
            type: 'tautliner',
            registrationNumber: 'K1 WIZZY',
            vinNumber: 'WAUAF48H99K021450',
            manufactureYear: 1999,
            firstRegistrationDate: '2024-11-01',
            make: 'Gniotpol',
            model: 'Jool',
            sideNumber: '015',
            maxSpeed: null,
            initialMileage: 15555,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: null,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        drivers: {
          primaryDriver: {
            uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
            driverData: {
              assignedBase: null,
              firstName: 'Adrian',
              lastName: 'Nowak ',
              birthDate: '1985-12-04',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '559099422',
              },
              workPhone: null,
              privateEmail: 'a.nowak@onyxtms.com',
              homeAddress: {
                label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
                street: 'Jeziorna',
                houseNumber: '11B',
                apartmentNumber: null,
                zipCode: '82-520',
                city: 'Krzykosy',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.68513,
                  longitude: 19.01345,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '85120441461',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
            },
          },
          secondaryDriver: {
            uuid: '9c91c434-7b18-4919-93ad-21b6272b82',
            driverData: {
              assignedBase: null,
              firstName: 'Edward',
              lastName: 'Grodzki',
              birthDate: '1953-01-30',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '123123123',
              },
              workPhone: {
                areaCode: '+48',
                number: '556556556',
              },
              privateEmail: 'edek.g@wp.pl',
              homeAddress: {
                label: 'Bolesława Prusa 3/12, 82-550 Prabuty, PL',
                street: 'Bolesława Prusa',
                houseNumber: '3',
                apartmentNumber: '12',
                zipCode: '82-550',
                city: 'Prabuty',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.75863,
                  longitude: 19.198,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '53013097732',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['f6c7f668-d6f2-419a-84e9-4456dac2111f'],
            },
          },
        },
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      basicInformation: {
        sender: {
          note: null,
          uuid: '000',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud ',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: 'N/a',
        senderBranch: 'N/a',
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null },
        rateType: OrderRateType.FREIGHT,
        officeNote: 'Notatka dla biura',
        vehicleTypes: ['curtainside'],
        loadedSemiTrailer: {
          uuid: '123',
          generalInformation: {
            registrationNumber: 'GKW 666',
          },
        } as SimplifiedFleet,
        loadedSemiTrailerRegistrationNumber: null,
        minSemiTrailerSize: OrderSemiTrailerSize.MEGA,
        vatRate: '23',
      },

      parameters: {
        adrClasses: null,
        beltsNumber: 14,
        coilWellLength: 14,
        driverLanguages: ['pl', 'en'],
        hasCustomsSecuringRope: true,
        hasDoubleCrew: true,
        hasDumpContainer: true,
        hasEcmr: true,
        hasForklift: true,
        hasHaccp: true,
        hasHdsCrane: true,
        hasLoadingsRamps: true,
        hasPalletExchange: true,
        hasSanitaryInspection: true,
        hasTailLift: true,
        hooksNumber: 14,
        isDedicated: true,
        isExclusive: true,
        matsNumber: 14,
        hasDoubleDeck: true,
        hasSafeParking: true,
        sentNotificationNumber: '04624',
        temperatureRange: { from: 14, to: 15 },
        other: [],
      },

      documents: [],

      points: [
        {
          uuid: '123',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '01:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-11-27', time: '08:06:00' }],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 15500,
              type: 'Nasiona',
              unit: 'euro-pallet',
              unitedNationsNumber: '125',
            },
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 13500,
              type: 'Zboże',
              unit: 'euro-pallet',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: '456',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-13', time: '15:00:00' }],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 13500,
              type: 'Nasiona',
              unit: 'euro-pallet',
              unitedNationsNumber: '125',
            },
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 1200,
              type: 'Amunicja',
              unit: 'euro-pallet',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: '123124214',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: { from: '15:00:00', to: '16:00:00' },
            weekday: { from: '15:00:00', to: '16:00:00' },
            sunday: { from: '15:00:00', to: '16:00:00' },
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          uuid: '1245156666',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-05', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
    {
      uuid: uniqueId('order'),
      id: 'OR-0001-15-25-ROG',

      distance: 4000,
      distanceTime: 100000,
      assignedData: null,
      outsourcingData: null,
      realizationData: {
        distance: 4000,
        distanceTime: 100000,
        emptyDistance: 50,
        emptyDistanceTime: 1000,
        remainingDistance: 900,
        remainingDistanceTime: 10000,
      },
      price: {
        value: 1244234,
        currency: 'pln',
        date: null,
      },
      author: {
        uuid: '111',
        firstName: 'Donald',
        lastName: 'Trump',
        avatar: undefined,
      },
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: undefined,
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: undefined,
        },
      ],
      status: {
        value: OrderStatus.IN_PROGRESS,
        color: 'violet',
      },
      routeCountries: ['pl', 'de', 'fr'],
      manualRoute: {
        author: {
          uuid: '124',
          firstName: 'Andrzej',
          lastName: 'Kowalski',
          avatar: undefined,
        },
        profitabilityPercentage: 98,
        isOnTime: false,
        createdAt: '2024-05-05T18:06:00',
      },
      engineProposalData: {
        emptyDistance: 50,
        emptyDistanceTime: 1000,
        vehicle: {
          uuid: '71c98e0e-18aa-4a9b-8507-297c5195782e',
          generalInformation: {
            category: FleetCategory.SEMI_TRUCK,
            type: null,
            registrationNumber: 'GSZ 3AU7',
            vinNumber: '2LMDU68C27BJ83210',
            manufactureYear: 2015,
            firstRegistrationDate: '2024-11-12',
            make: 'Hyundai',
            model: 'Forentern',
            sideNumber: '099',
            maxSpeed: 15,
            initialMileage: null,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
            assignedBase: null,
          },
          fuelTankCapacity: {
            fuelType: 'diesel',
            mainTank: 100000,
            adBlueTank: null,
            additionalTank: 5000,
            generatorFuelTank: null,
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: true,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        trailer: {
          uuid: '813bdd69-43e2-4f53-aa3c-c390fbb46203',
          generalInformation: {
            assignedBase: null,
            category: FleetCategory.SEMI_TRAILER,
            type: 'tautliner',
            registrationNumber: 'K1 WIZZY',
            vinNumber: 'WAUAF48H99K021450',
            manufactureYear: 1999,
            firstRegistrationDate: '2024-11-01',
            make: 'Gniotpol',
            model: 'Jool',
            sideNumber: '015',
            maxSpeed: null,
            initialMileage: 15555,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: null,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        drivers: {
          primaryDriver: {
            uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
            driverData: {
              assignedBase: null,
              firstName: 'Adrian',
              lastName: 'Nowak ',
              birthDate: '1985-12-04',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '559099422',
              },
              workPhone: null,
              privateEmail: 'a.nowak@onyxtms.com',
              homeAddress: {
                label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
                street: 'Jeziorna',
                houseNumber: '11B',
                apartmentNumber: null,
                zipCode: '82-520',
                city: 'Krzykosy',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.68513,
                  longitude: 19.01345,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '85120441461',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
            },
          },
          secondaryDriver: {
            uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
            driverData: {
              assignedBase: null,
              firstName: 'Adrian',
              lastName: 'Nowak II',
              birthDate: '1985-12-04',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '559099422',
              },
              workPhone: null,
              privateEmail: 'a.nowak@onyxtms.com',
              homeAddress: {
                label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
                street: 'Jeziorna',
                houseNumber: '11B',
                apartmentNumber: null,
                zipCode: '82-520',
                city: 'Krzykosy',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.68513,
                  longitude: 19.01345,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '85120441461',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
            },
          },
        },
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      basicInformation: {
        sender: {
          note: null,
          uuid: '000',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud ',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: 'N/a',
        senderBranch: 'N/a',
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null },
        rateType: OrderRateType.FREIGHT,
        officeNote: 'Notatka dla biura',
        vehicleTypes: ['curtainside'],
        loadedSemiTrailer: null,
        loadedSemiTrailerRegistrationNumber: null,
        minSemiTrailerSize: OrderSemiTrailerSize.HALF_MEGA,
        vatRate: '23',
      },

      parameters: {
        adrClasses: null,
        beltsNumber: 14,
        coilWellLength: 14,
        driverLanguages: ['pl', 'en'],
        hasCustomsSecuringRope: true,
        hasDoubleCrew: true,
        hasDumpContainer: true,
        hasEcmr: true,
        hasForklift: true,
        hasHaccp: true,
        hasHdsCrane: true,
        hasLoadingsRamps: true,
        hasPalletExchange: true,
        hasSanitaryInspection: true,
        hasTailLift: true,
        hooksNumber: 14,
        isDedicated: false,
        isExclusive: true,
        matsNumber: 14,
        hasDoubleDeck: true,
        hasSafeParking: true,
        other: [],
        sentNotificationNumber: '04624',
        temperatureRange: { from: 14, to: 30 },
      },

      documents: [],

      points: [
        {
          uuid: '215611251562',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '05:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.INTERVAL,
            windows: [
              {
                date: '2024-11-15',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-11-15',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-11-15',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: 'asgJQ33G3',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: { from: '15:00:00', to: '16:00:00' },
            weekday: { from: '15:00:00', to: '16:00:00' },
            sunday: { from: '15:00:00', to: '16:00:00' },
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          uuid: 'GY2JG3923GQGQ',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-05', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
    {
      uuid: uniqueId('order'),
      id: 'OR-0241-09-24-POV',
      distance: 4000,
      distanceTime: 100000,
      assignedData: {
        emptyDistance: 150,
        emptyDistanceTime: 5000,
        vehicle: {
          uuid: '71c98e0e-18aa-4a9b-8507-297c5195782e',
          generalInformation: {
            category: FleetCategory.SEMI_TRUCK,
            type: null,
            registrationNumber: 'GSZ 3AU7',
            vinNumber: '2LMDU68C27BJ83210',
            manufactureYear: 2015,
            firstRegistrationDate: '2024-11-12',
            make: 'Hyundai',
            model: 'Forentern',
            sideNumber: '099',
            maxSpeed: 15,
            initialMileage: null,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
            assignedBase: null,
          },
          fuelTankCapacity: {
            fuelType: 'diesel',
            mainTank: 100000,
            adBlueTank: null,
            additionalTank: 5000,
            generatorFuelTank: null,
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: true,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        trailer: {
          uuid: '813bdd69-43e2-4f53-aa3c-c390fbb46203',
          generalInformation: {
            assignedBase: null,
            category: FleetCategory.SEMI_TRAILER,
            type: 'tautliner',
            registrationNumber: 'K1 WIZZY',
            vinNumber: 'WAUAF48H99K021450',
            manufactureYear: 1999,
            firstRegistrationDate: '2024-11-01',
            make: 'Gniotpol',
            model: 'Jool',
            sideNumber: '015',
            maxSpeed: null,
            initialMileage: 15555,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: null,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        drivers: {
          primaryDriver: {
            uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
            driverData: {
              assignedBase: null,
              firstName: 'Adrian',
              lastName: 'Nowak ',
              birthDate: '1985-12-04',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '559099422',
              },
              workPhone: null,
              privateEmail: 'a.nowak@onyxtms.com',
              homeAddress: {
                label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
                street: 'Jeziorna',
                houseNumber: '11B',
                apartmentNumber: null,
                zipCode: '82-520',
                city: 'Krzykosy',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.68513,
                  longitude: 19.01345,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '85120441461',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
            },
          },
          secondaryDriver: {
            uuid: '9c91c434-7b18-4919-93ad-21b6272b84ac',
            driverData: {
              assignedBase: null,
              firstName: 'Edward',
              lastName: 'Grodzki',
              birthDate: '1953-01-30',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '123123123',
              },
              workPhone: {
                areaCode: '+48',
                number: '556556556',
              },
              privateEmail: 'edek.g@wp.pl',
              homeAddress: {
                label: 'Bolesława Prusa 3/12, 82-550 Prabuty, PL',
                street: 'Bolesława Prusa',
                houseNumber: '3',
                apartmentNumber: '12',
                zipCode: '82-550',
                city: 'Prabuty',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.75863,
                  longitude: 19.198,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '53013097732',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['f6c7f668-d6f2-419a-84e9-4456dac2111f'],
            },
          },
        },
      },
      engineProposalData: null,
      manualRoute: null,
      realizationData: {
        distance: 4100,
        distanceTime: 100000,
        emptyDistance: 50,
        emptyDistanceTime: 1000,
        remainingDistance: 900,
        remainingDistanceTime: 1000,
      },

      price: {
        value: 1244234,
        currency: 'pln',
        date: null,
      },
      author: {
        uuid: '111',
        firstName: 'Donald',
        lastName: 'Trump',
        avatar: undefined,
      },
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: undefined,
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: undefined,
        },
      ],
      status: {
        value: OrderStatus.COMPLETING_DOCUMENTS,
        color: 'violet',
      },
      routeCountries: ['pl', 'de', 'fr', 'es', 'fi'],

      basicInformation: {
        sender: {
          note: null,
          uuid: '000',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: 'N/a',
        senderBranch: 'N/a',
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null },
        rateType: OrderRateType.FREIGHT,
        officeNote: 'Notatka dla biura',
        vehicleTypes: ['curtainside'],
        loadedSemiTrailer: null,
        loadedSemiTrailerRegistrationNumber: 'DSDDS',
        minSemiTrailerSize: OrderSemiTrailerSize.HALF_MEGA,
        vatRate: '23',
      },
      outsourcingData: {
        contactPerson: {
          timocomId: '123',
          transId: '123',
          email: '123',
          firstName: '123',
          lastName: '123',
          note: '123',
          phone: {
            areaCode: '123',
            number: '123',
          },
          roles: [EmployeeRole.BUSINESS_ADMIN],
        },
        contractor: {
          note: null,
          uuid: '123',
          companyProfile: {
            companyName: '123',
            country: 'pl',
            displayName: 'Omega',
            phone: {
              areaCode: '+48',
              number: '1234214124',
            },
            types: [],
            vatId: '123',
          },
        },
        daysPaymentTerms: '',
        primaryDriver: {
          name: 'Jan Paweł',
          phone: {
            areaCode: '+48',
            number: '1234214124',
          },
        },
        secondaryDriver: {
          name: 'Jan Paweł II',
          phone: {
            areaCode: '+48',
            number: '1234214124',
          },
        },

        executionTerms: '',
        margin: 2,
        marginRate: 20000,
        note: '',
        rate: {
          value: 100,
          currency: 'pln',
          date: null,
        },
        rateType: OrderRateType.FREIGHT,
        trailerRegistrationNumber: 'GKW 55P8',
        vehicleRegistrationNumber: 'GKW RS6GT',
        vatRate: '12',
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      parameters: {
        adrClasses: null,
        beltsNumber: 14,
        coilWellLength: 14,
        driverLanguages: ['pl', 'en'],
        hasCustomsSecuringRope: true,
        hasDoubleCrew: true,
        hasDumpContainer: true,
        hasEcmr: true,
        hasForklift: true,
        hasHaccp: true,
        hasHdsCrane: true,
        hasLoadingsRamps: true,
        hasPalletExchange: true,
        hasSanitaryInspection: true,
        hasTailLift: true,
        hooksNumber: 14,
        isDedicated: true,
        isExclusive: true,
        matsNumber: 14,
        hasDoubleDeck: true,
        hasSafeParking: true,
        other: [],
        sentNotificationNumber: '04624',
        temperatureRange: { from: 15, to: 22 },
      },

      documents: [],

      points: [
        {
          uuid: '12312512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.OPENING_HOURS,
            windows: [
              {
                date: '2024-05-13',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-05-14',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-05-15',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: '1231251251gwedg2',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: { from: '15:00:00', to: '16:00:00' },
            weekday: { from: '15:00:00', to: '16:00:00' },
            sunday: { from: '15:00:00', to: '16:00:00' },
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          uuid: '1231251251asdasdasffff2',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-05', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
    {
      uuid: uniqueId('order'),
      id: 'OR-1452-08-22-ONY',
      distance: 4000,
      distanceTime: 100000,
      realizationData: null,
      assignedData: null,
      engineProposalData: null,
      manualRoute: null,
      outsourcingData: null,

      price: {
        value: 1244234,
        currency: 'pln',
        date: null,
      },
      author: {
        uuid: '111',
        firstName: 'Donald',
        lastName: 'Trump',
        avatar: undefined,
      },
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: undefined,
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: undefined,
        },
      ],
      status: {
        value: OrderStatus.TO_ACCEPT,
        color: 'violet',
      },
      routeCountries: ['pl', 'de', 'fr', 'es', 'fi'],

      basicInformation: {
        sender: {
          note: null,
          uuid: '000',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: 'N/a',
        senderBranch: 'N/a',
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null },
        rateType: OrderRateType.FREIGHT,
        officeNote: 'Notatka dla biura',
        vehicleTypes: [
          'curtainside',
          'curtainside',
          'curtainside',
          'curtainside',
        ],
        loadedSemiTrailer: null,
        loadedSemiTrailerRegistrationNumber: null,
        minSemiTrailerSize: OrderSemiTrailerSize.MEGA,
        vatRate: '23',
      },

      parameters: {
        adrClasses: null,
        beltsNumber: 14,
        coilWellLength: 14,
        driverLanguages: ['pl', 'en'],
        hasCustomsSecuringRope: true,
        hasDoubleCrew: true,
        hasDumpContainer: true,
        hasEcmr: true,
        hasForklift: true,
        hasHaccp: true,
        hasHdsCrane: true,
        hasLoadingsRamps: true,
        hasPalletExchange: true,
        hasSanitaryInspection: true,
        hasTailLift: true,
        hooksNumber: 14,
        isDedicated: true,
        isExclusive: true,
        matsNumber: 14,
        hasDoubleDeck: true,
        hasSafeParking: true,
        other: [],
        sentNotificationNumber: '04624',
        temperatureRange: { from: 1, to: 15 },
      },

      documents: [],
      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      points: [
        {
          uuid: '1231212312512r512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '01:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.OPENING_HOURS,
            windows: [
              {
                date: '2024-05-13',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-05-14',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-11-26',
                timeRange: { from: '13:00:00', to: '14:00:00' },
              },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: '123121saSDasfddsgv512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: { from: '15:00:00', to: '16:00:00' },
            weekday: { from: '15:00:00', to: '16:00:00' },
            sunday: { from: '15:00:00', to: '16:00:00' },
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          uuid: '1231wgql2512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-05', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
    {
      uuid: uniqueId('order'),
      id: 'OR-1452-08-22-ONY',
      distance: 4000,
      distanceTime: 1000000,

      realizationData: {
        distance: 3000,
        distanceTime: 50000,
        remainingDistance: 3050,
        remainingDistanceTime: 51000,
        emptyDistance: 50,
        emptyDistanceTime: 1000,
      },
      assignedData: {
        emptyDistance: 30,
        emptyDistanceTime: 5000,
        vehicle: {
          uuid: '71c98e0e-18aa-4a9b-8507-297c5195782e',
          generalInformation: {
            category: FleetCategory.SEMI_TRUCK,
            type: null,
            registrationNumber: 'GSZ 3AU7',
            vinNumber: '2LMDU68C27BJ83210',
            manufactureYear: 2015,
            firstRegistrationDate: '2024-11-12',
            make: 'Hyundai',
            model: 'Forentern',
            sideNumber: '099',
            maxSpeed: 15,
            initialMileage: null,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
            assignedBase: null,
          },
          fuelTankCapacity: {
            fuelType: 'diesel',
            mainTank: 100000,
            adBlueTank: null,
            additionalTank: 5000,
            generatorFuelTank: null,
          },
          additionalParameters: {
            adrClasses: null,
            isLowDeck: true,
            beltsNumber: null,
            hooksNumber: null,
            hasSanitaryInspection: null,
            hasHaccp: null,
            hasHdsCrane: null,
            matsNumber: null,
            coilWellLength: null,
            trailerSize: null,
            semiTrailerSize: null,
            hasLoadingsRamps: null,
            temperatureRange: null,
            hasTailLift: null,
            hasDumpContainer: null,
            hasForklift: null,
            other: [],
            hasCustomsSecuringRope: null,
          },
        },
        trailer: {
          uuid: '813bdd69-43e2-4f53-aa3c-c390fbb46203',
          generalInformation: {
            assignedBase: null,
            category: FleetCategory.SEMI_TRAILER,
            type: 'tautliner',
            registrationNumber: 'K1 WIZZY',
            vinNumber: 'WAUAF48H99K021450',
            manufactureYear: 1999,
            firstRegistrationDate: '2024-11-01',
            make: 'Gniotpol',
            model: 'Jool',
            sideNumber: '015',
            maxSpeed: null,
            initialMileage: 15555,
            state: FleetState.ACTIVE,
            planningMode: VehiclePlanningMode.AUTO,
            registrationCountry: 'pl',
          },
        } as SimplifiedFleet,
        drivers: {
          primaryDriver: {
            uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
            driverData: {
              assignedBase: null,
              firstName: 'Adrian',
              lastName: 'Nowak ',
              birthDate: '1985-12-04',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '559099422',
              },
              workPhone: null,
              privateEmail: 'a.nowak@onyxtms.com',
              homeAddress: {
                label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
                street: 'Jeziorna',
                houseNumber: '11B',
                apartmentNumber: null,
                zipCode: '82-520',
                city: 'Krzykosy',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.68513,
                  longitude: 19.01345,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '85120441461',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
            },
          },
          secondaryDriver: {
            uuid: '9c91c434-7b18-4919-93ad-21b6272b84ac',
            driverData: {
              assignedBase: null,
              firstName: 'Edward',
              lastName: 'Grodzki',
              birthDate: '1953-01-30',
              nationality: 'pl',
              privatePhone: {
                areaCode: '+48',
                number: '123123123',
              },
              workPhone: {
                areaCode: '+48',
                number: '556556556',
              },
              privateEmail: 'edek.g@wp.pl',
              homeAddress: {
                label: 'Bolesława Prusa 3/12, 82-550 Prabuty, PL',
                street: 'Bolesława Prusa',
                houseNumber: '3',
                apartmentNumber: '12',
                zipCode: '82-550',
                city: 'Prabuty',
                countryCode: 'pl',
                coordinates: {
                  latitude: 53.75863,
                  longitude: 19.198,
                },
                type: OnyxAddressType.CUSTOM,
              },
              peselNumber: '53013097732',
            },
            driverCard: {
              number: '123123123123123',
              issuingCountry: 'pl',
              expirationDate: '2024-11-30',
              scans: ['f6c7f668-d6f2-419a-84e9-4456dac2111f'],
            },
          },
        },
      },
      engineProposalData: null,
      manualRoute: null,
      outsourcingData: null,
      price: {
        value: 1244234,
        currency: 'pln',
        date: null,
      },
      author: {
        uuid: '111',
        firstName: 'Donald',
        lastName: 'Trump',
        avatar: undefined,
      },
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: undefined,
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: undefined,
        },
      ],
      status: {
        value: OrderStatus.COMPLETING_DOCUMENTS,
        color: 'violet',
      },
      routeCountries: ['pl', 'de', 'fr', 'es', 'fi'],

      basicInformation: {
        sender: {
          note: null,
          uuid: '000',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: 'N/a',
        senderBranch: 'N/a',
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null },
        rateType: OrderRateType.FREIGHT,
        officeNote: 'Notatka dla biura',
        vehicleTypes: [
          'curtainside',
          'curtainside',
          'curtainside',
          'curtainside',
        ],
        loadedSemiTrailer: null,
        loadedSemiTrailerRegistrationNumber: 'GKW 666',
        minSemiTrailerSize: OrderSemiTrailerSize.MEGA,
        vatRate: '23',
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      parameters: {
        adrClasses: null,
        beltsNumber: 14,
        coilWellLength: 14,
        driverLanguages: ['pl', 'en'],
        hasCustomsSecuringRope: true,
        hasDoubleCrew: true,
        hasDumpContainer: true,
        hasEcmr: true,
        hasForklift: true,
        hasHaccp: true,
        hasHdsCrane: true,
        hasLoadingsRamps: true,
        hasPalletExchange: true,
        hasSanitaryInspection: true,
        hasTailLift: true,
        hooksNumber: 14,
        isDedicated: true,
        isExclusive: true,
        matsNumber: 14,
        hasDoubleDeck: true,
        hasSafeParking: true,
        other: [],
        sentNotificationNumber: '04624',
        temperatureRange: { from: 15, to: 100 },
      },

      documents: [],

      points: [
        {
          uuid: '1231hk230k23gk30hj2512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '01:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.OPENING_HOURS,
            windows: [
              {
                date: '2024-05-13',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-05-14',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-11-28',
                timeRange: { from: '13:00:00', to: '14:00:00' },
              },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: '12312h3kmhmo324hn5hn512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: { from: '15:00:00', to: '16:00:00' },
            weekday: { from: '15:00:00', to: '16:00:00' },
            sunday: { from: '15:00:00', to: '16:00:00' },
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          uuid: '12g32hg243hb2gv3312512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-05', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
    {
      uuid: uniqueId('order'),
      id: 'OR-1452-08-22-ONY',
      distance: 4000,
      distanceTime: 1000000,
      realizationData: null,
      assignedData: null,
      engineProposalData: null,
      manualRoute: null,
      outsourcingData: null,
      price: {
        value: 1244234,
        currency: 'pln',
        date: null,
      },
      author: {
        uuid: '111',
        firstName: 'Donald',
        lastName: 'Trump',
        avatar: undefined,
      },
      assignedEmployees: [
        {
          uuid: '15',
          firstName: 'Patryk',
          lastName: 'Kawiak',
          avatar: undefined,
        },
        {
          uuid: '001',
          firstName: 'Adam',
          lastName: 'Rogacz',
          avatar: undefined,
        },
      ],
      status: {
        value: OrderStatus.ARCHIVED,
        color: 'gray',
      },
      routeCountries: ['pl', 'de', 'fr', 'es', 'fi'],

      basicInformation: {
        sender: {
          note: null,
          uuid: '000',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: 'N/a',
        senderBranch: 'N/a',
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null },
        rateType: OrderRateType.FREIGHT,
        officeNote: 'Notatka dla biura',
        vehicleTypes: [
          'curtainside',
          'curtainside',
          'curtainside',
          'curtainside',
        ],
        loadedSemiTrailer: null,
        loadedSemiTrailerRegistrationNumber: null,
        minSemiTrailerSize: OrderSemiTrailerSize.MEGA,
        vatRate: '23',
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      parameters: {
        adrClasses: null,
        beltsNumber: 14,
        coilWellLength: 14,
        driverLanguages: ['pl', 'en'],
        hasCustomsSecuringRope: true,
        hasDoubleCrew: true,
        hasDumpContainer: true,
        hasEcmr: true,
        hasForklift: true,
        hasHaccp: true,
        hasHdsCrane: true,
        hasLoadingsRamps: true,
        hasPalletExchange: true,
        hasSanitaryInspection: true,
        hasTailLift: true,
        hooksNumber: 14,
        isDedicated: true,
        isExclusive: true,
        matsNumber: 14,
        hasDoubleDeck: true,
        hasSafeParking: true,
        other: [],
        sentNotificationNumber: '04624',
        temperatureRange: { from: 15, to: 22 },
      },

      documents: [],

      points: [
        {
          uuid: '123ascf eqg g eqw12512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '01:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.OPENING_HOURS,
            windows: [
              {
                date: '2024-05-13',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-05-14',
                timeRange: { from: '15:00:00', to: '16:00:00' },
              },
              {
                date: '2024-11-26',
                timeRange: { from: '13:00:00', to: '23:00:00' },
              },
            ],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          uuid: '1231asfgvggp2512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: { from: '15:00:00', to: '16:00:00' },
            weekday: { from: '15:00:00', to: '16:00:00' },
            sunday: { from: '15:00:00', to: '16:00:00' },
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          uuid: '12312g34g2lkjhg512512',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
              },
              statisticalNumber: '15',
              totalWeight: 155,
              type: 'N/A',
              unit: 'kg',
              unitedNationsNumber: '125',
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-05', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
  ],
  limit: 10,
  page: 1,
  totalItems: 1,
};
