import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxFilterPipe,
  OnyxOption,
  OnyxPaginated,
  OnyxPagination,
} from '@onyx/angular';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { BatchFileUpload } from '../../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../../common/services/api.service';
import { StorageService } from '../../../../../common/services/storage.service';
import { ContractorFormDto } from '../../contractor-form/contractor-form.component';
import { BlockContractorModalForm } from '../components/block-contractor-modal/block-contractor-modal.component';
import { ContractorCategory } from '../enums/contractor-category';
import { ContractorStatus } from '../enums/contractor-status';
import { ContractorType } from '../enums/contractor-type';
import { Contractor } from '../interfaces/contractor';

@Injectable({
  providedIn: 'root',
})
export class ContractorsService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private filterPipe: OnyxFilterPipe,
  ) {
    super(http);
  }

  public listContractors(
    params: {
      category?: ContractorCategory;
      status?: ContractorStatus[];
      types?: ContractorType[];
      country?: string;
      vatId?: string;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Contractor>> {
    return this.get('/contractor', {
      params: {
        ...(params.category && { category: params.category }),
        ...(params.types &&
          params.types.length > 0 && { 'types[]': params.types }),
        ...(params.status &&
          params.status.length > 0 && { 'status[]': params.status }),
        ...(params.country && { country: params.country }),
        ...(params.vatId && { vatId: params.vatId }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchContractors(
    query: string,
    params?: Omit<
      Parameters<ContractorsService['listContractors']>[0],
      keyof OnyxPagination
    >,
    limit = 10,
  ): Observable<OnyxOption<string>[]> {
    return this.listContractors({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) =>
        response.items.map((contractor) => ({
          name: contractor.companyProfile.displayName,
          value: contractor.uuid,
        })),
      ),
      map((options) =>
        this.filterPipe.transform(options, query, ['name']).slice(0, limit),
      ),
    );
  }

  public getContractor(uuid: string): Observable<Contractor> {
    return this.get(`/contractor/${uuid}`);
  }

  public addContractor(dto: ContractorFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<void>('/contractor', dto)),
      tap(() => this._reload$.next()),
    );
  }

  public editContractor(
    uuid: string,
    dto: ContractorFormDto,
  ): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<void>(`/contractor/${uuid}`, dto)),
      tap(() => this._reload$.next()),
    );
  }

  public blockContractor(
    uuid: string,
    form: BlockContractorModalForm,
  ): Observable<void> {
    return this.put<void>(`/contractor/${uuid}/status`, {
      status: ContractorStatus.BLOCKED,
      block: form,
    }).pipe(tap(() => this._reload$.next()));
  }

  public unblockContractor(uuid: string): Observable<void> {
    return this.put<void>(`/contractor/${uuid}/status`, {
      status: ContractorStatus.ACTIVE,
      block: null,
    }).pipe(tap(() => this._reload$.next()));
  }

  public archiveContractor(uuid: string): Observable<void> {
    return this.post<void>(`/contractor/${uuid}/archive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unarchiveContractor(uuid: string): Observable<void> {
    return this.post<void>(`/contractor/${uuid}/unarchive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  private uploadFiles(dto: ContractorFormDto): Observable<ContractorFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        ...dto.documents.map((document, index) => ({
          path: `documents[${index}].scan`,
          files: document.scan,
        })),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }
}
