import { Driver } from '../../../drivers/common/interfaces/driver';
import { Fleet } from '../../../fleet/common/interfaces/fleet';
import { DelegationForm } from '../../delegation-form/delegation-form.component';
import { Delegation } from '../interfaces/delegation';

export class DelegationHelper {
  public static fromDto(dto: Delegation): DelegationForm {
    return {
      driverAndVehicle: {
        // TEMP
        primaryDriverUuid: dto.primaryDriver as Driver,
        secondaryDriverUuid: dto.secondaryDriver as Driver,
        vehicleUuid: dto.vehicle as Fleet,
        trailerUuid: dto.trailer as Fleet | null,
        //
      },
      dailyReturns: {
        daysOfWeek: dto.daysOfWeek,
        hasDailyReturns: dto.hasDailyReturns,
        hasSelectedDays: dto.daysOfWeek != null,
      },
      start: {
        departurePlace: dto.start.address,
        departureDate: dto.start.date,
        departureTime: dto.start.time,
      },
      end: {
        arrivalDate: dto.end.date,
        arrivalPlace: dto.end.address,
        arrivalTime: dto.end.time,
      },
    };
  }
}
